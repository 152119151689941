import React from "react";
import { AdminCustomerMessage } from "../../../../components";
import { userData, dummy__messages } from "../../../../data/messageData";

const CustomerMessage = () => {
  return (
    <>
      <AdminCustomerMessage users={userData} messages={dummy__messages} />
    </>
  );
};

export default CustomerMessage;
