import { customerAuthConstant, vendorAuthConstant } from "./../constants";

const initialState = {
  errors: [],
  message: "",

  getLoggedInUserData: {
    loading: false,
    user: null,
  },
};

const vendorAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case vendorAuthConstant.VENDOR_REGISTER_REQUEST:
    case vendorAuthConstant.VENDOR_LOGIN_REQUEST:
    case vendorAuthConstant.FORGOT_PASSWORD_REQUEST:
    case vendorAuthConstant.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        message: "",
        errors: [],
      };
    case vendorAuthConstant.VENDOR_REGISTER_SUCCESS:
    case vendorAuthConstant.VENDOR_LOGIN_SUCCESS:
    case vendorAuthConstant.FORGOT_PASSWORD_SUCCESS:
    case vendorAuthConstant.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case vendorAuthConstant.VENDOR_REGISTER_FAILURE:
    case vendorAuthConstant.VENDOR_LOGIN_FAILURE:
    case vendorAuthConstant.FORGOT_PASSWORD_FAILURE:
    case vendorAuthConstant.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload.err,
        message: "",
      };
    case customerAuthConstant.CLEAR_MESSAGES:
      return {
        ...state,
        loading: false,
        message: "",
        errors: [],
      };
    case customerAuthConstant.CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        errors: [],
        message: "",
      };

    case vendorAuthConstant.GET_LOGGED_IN_USER_REQUEST:
      return {
        ...state,
        getLoggedInUserData: {
          loading: true,
          user: null,
        },
      };
    case vendorAuthConstant.GET_LOGGED_IN_USER_SUCCESS:
      return {
        ...state,
        getLoggedInUserData: {
          loading: false,
          user: action.payload,
        },
      };
    case vendorAuthConstant.GET_LOGGED_IN_USER_FAILURE:
      return {
        ...state,
        getLoggedInUserData: {
          loading: false,
          user: null,
        },
      };

    default:
      return state;
  }
};

export default vendorAuthReducer;
