import axios from "axios";
import { servicesTypesConstant } from "../constants";
import { BACKEND_URL } from "../../constants";


export const getAllServicesTypes=()=>async(dispatch)=>{
    dispatch({type:servicesTypesConstant.GET_ALL_SERVICES_TYPES_REQUEST})
    try {
        const {data}=await axios.get(`${BACKEND_URL}/v1/api/admin-services`)
        dispatch({type:servicesTypesConstant.GET_ALL_SERVICES_TYPES_SUCCESS,payload:data})
    } catch (error) {
        console.log(error)
        dispatch({type:servicesTypesConstant.GET_ALL_SERVICES_TYPES_FAILURE,payload:{err:error?.response?.data?.errors[0]?.message}})
    }
}   