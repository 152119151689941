// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.venderprofile-container-image > img {
  height: 143px;
  width: 143px;
  border-radius: 50%; }

.venderprofile-container-content {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .venderprofile-container-content > div {
    width: 40%; }
`, "",{"version":3,"sources":["webpack://./src/module/vender/dashboard/venderProfile/VenderProfile.scss"],"names":[],"mappings":"AAEK;EAEG,aAAa;EACb,YAAY;EACZ,kBAAkB,EAAA;;AAGtB;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B,EAAA;EAH/B;IAKG,UAAU,EAAA","sourcesContent":[".venderprofile {\n  &-container {\n    &-image {\n      & > img {\n        height: 143px;\n        width: 143px;\n        border-radius: 50%;\n      }\n    }\n    &-content {\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      & > div {\n        width: 40%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
