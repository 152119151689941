import { useEffect, useRef } from "react";

const useGoogleLoc = (onChange = (place) => {}) => {
  const ref = useRef(null);

  useEffect(() => {
    const setAutoCompletes = (ref) => {
      if (!ref.current) return;
      const autoComplete = new window.google.maps.places.Autocomplete(
        ref.current,
        {
          types: ["(cities)"],
          componentRestrictions: { country: "uk" },
        }
      );

      const onCh = async function () {
        const place = await autoComplete.getPlace();
        onChange(place);
      };

      autoComplete.addListener("place_changed", onCh);
    };

    setAutoCompletes(ref);
  }, [onChange]);

  return ref;
};

export default useGoogleLoc;
