import React from "react";
import { Link } from "react-router-dom";
import { Container, Text, Heading } from "../../../components";
const TcForCustomer = () => {
  return (
    <>
      <Container className="main">
        <Container className="md">
          <div className="list-view">
            <Heading className="primary center m-5">
              Terms and conditions for Customers
            </Heading>
            <Text>
              These terms and conditions outline the rules and regulations for
              the use of OccasionBase’s services, including our website and any
              products or services offered by OccasionBase to customers.
            </Text>
            <Text>
              By using our services, you agree to be bound by these terms and
              conditions. If you disagree with any part of these terms and
              conditions, you must not use our services.
            </Text>
            <div className="m-1" />
            <ol>
              <li>
                <Text>
                  Description of Services: OccasionBase is an online marketing
                  plaƞorm that allows customers to search for and connect with
                  vendors who provide products and services for events. We do
                  not provide any products or services ourselves.
                </Text>
              </li>
              <li>
                <Text>
                  Use of Services: You may use our services for the sole purpose
                  of searching for and connecting with vendors for events. You
                  may not use our services for any unlawful purpose or in any
                  way that violates these terms and conditions.
                </Text>
              </li>
              <li>
                <Text>
                  Vendors and Products/Services: OccasionBase does not endorse
                  or guarantee the quality of any vendor or product/service
                  listed on our website. It is your responsibility to evaluate
                  and verify the quality and suitability of any vendor or
                  product/service before entering into any agreement with them.
                </Text>
              </li>
              <li>
                <Text>
                  Payment: Payment for any products or services provided by
                  vendors is solely between you and the vendor. OccasionBase is
                  not responsible for any payment transactions or disputes
                  between customers and vendors.
                </Text>
              </li>
              <li>
                <Text>
                  User Content: You may submit user content to our website, such
                  as reviews of vendors and their products/services. By submiƫng
                  user content, you grant OccasionBase a worldwide, irrevocable,
                  non-exclusive, royalty-free license to use, reproduce, adapt,
                  publish, translate and distribute your user content in any
                  existing or future media.
                </Text>
              </li>
              <li>
                <Text>
                  Limitations of Liability: OccasionBase will not be liable for
                  any damages arising from the use of our services, including
                  but not limited to direct, indirect, incidental, punitive, and
                  consequential damages. We do not guarantee the accuracy or
                  completeness of any information on our website, including
                  vendor listings.
                </Text>
              </li>
              <li>
                <Text>
                  User Conduct: You agree not to use our services for any
                  unlawful purpose or in any way that violates these terms and
                  conditions. You agree to use our services only for lawful
                  purposes and in accordance with all applicable laws and
                  regulations.
                </Text>
              </li>
              <li>
                <Text>
                  Intellectual Property Rights: Unless otherwise stated,
                  OccasionBase and/or its licensors own the intellectual
                  property rights in our website and material on our website.
                  Subject to the license below, all these intellectual property
                  rights are reserved.
                </Text>
              </li>
              <li>
                <Text>
                  Termination: OccasionBase reserves the right to terminate your
                  access to our services at any time, without notice or
                  explanation, if you breach these terms and conditions.
                </Text>
              </li>
              <li>
                <Text>
                  Indemnification: You agree to indemnify and hold OccasionBase,
                  its affiliates, officers, agents, and employees, harmless from
                  any claim or demand, including reasonable attorneys’ fees,
                  made by any third-party due to or arising out of your use of
                  our services or violation of these terms and conditions.
                </Text>
              </li>
              <li>
                <Text>
                  Entire Agreement: These terms and conditions constitute the
                  entire agreement between you and OccasionBase and supersede
                  any prior agreements or understandings, whether written or
                  oral, relating to the use of our services.
                </Text>
              </li>
              <li>
                <Text>
                  Contact Us: If you have any questions about these terms and
                  conditions, please contact us at{" "}
                  <Link to="mailto:info@occasionbase.com">
                    info@occasionbase.com.
                  </Link>
                </Text>
              </li>
            </ol>
            <Text>
              Conclusion At OccasionBase, we are committed to protecting your
              privacy and providing you with a safe and secure online
              experience. We use cookies to enhance your user experience,
              understand your preferences, and improve the quality of our
              services. By using our website, you consent to our use of cookies
              in accordance with this Privacy/Cookies Policy.
            </Text>
          </div>

          <div className="m-5"></div>
          <div className="m-5"></div>
        </Container>
      </Container>
    </>
  );
};

export default TcForCustomer;
