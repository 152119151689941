import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./ManageMembership.scss";
import styled from "@emotion/styled";
import { IoMdCheckmark } from "react-icons/io";
import useUser from "../../../../hooks/useUser";
import toast from "react-hot-toast";
import { reSubscribeVendorMembership } from "../../../../store/actions";
import { reGetUserDetails2 } from "../../../../store/actions/user.action";

export const priorityTable = {
  Bronze: 1,
  Silver: 2,
  Gold: 3,
  Platinum: 4,
};

export const MemberShipCard = (props) => {
  const navigate = useNavigate();
  const { getUserDetails2Data } = useSelector((s) => s.userReducer);
  const { details } = useSelector((state) => state.vendorReducer);

  const user = useUser();
  const subscribedPlanName = useMemo(
    () => user?.subscription?.plan?.product?.name,
    [user?.subscription?.plan?.product?.name]
  );

  const subscripedPlanPriority = useMemo(
    () => (subscribedPlanName ? priorityTable[subscribedPlanName] : 0),
    [subscribedPlanName]
  );

  const isSubscribedPlanWillCancelling = useMemo(
    () => user?.subscription?.cancel_at_period_end,
    [user?.subscription?.cancel_at_period_end]
  );

  const thisPlanPriority = useMemo(
    () => priorityTable[props.name],
    [props.name]
  );

  const isSubscribed = useMemo(
    () => subscribedPlanName === props.name,
    [subscribedPlanName, props.name]
  );

  const isAnyPlanSubscribed = useMemo(
    () => Boolean(subscribedPlanName),
    [subscribedPlanName]
  );

  const isWantedToUpdateDowngradeToThisPlan = useMemo(
    () =>
      isSubscribedPlanWillCancelling &&
      user?.wantUpdateOrDowngradeToAfterCancel === props?.priceId,
    [
      isSubscribedPlanWillCancelling,
      props?.priceId,
      user?.wantUpdateOrDowngradeToAfterCancel,
    ]
  );

  return (
    <MemberShipCardStyled color={props.color}>
      <div className="my-background"></div>
      <div className="my-group">
        <div className="my-top">
          <h2>{props.name}</h2>
          <p>{props.desc}</p>
        </div>

        <div className="my-middle">
          <ul className="my-top">
            {props?.features?.map((text, i) => (
              <li key={i}>
                <span className="icon">
                  <IoMdCheckmark />
                </span>
                <span className="text">{text}</span>
              </li>
            ))}

            {props?.coupon && (
              <li>
                <span className="icon">
                  <IoMdCheckmark />
                </span>
                <span className="text">
                  Use code{" "}
                  <span className="underline bold">{props.coupon?.name}</span>{" "}
                  for{" "}
                  <span className="lowercase">
                    {props?.coupon?.description}
                  </span>
                </span>
              </li>
            )}

            {props?.price === 0 && (
              <li>
                <span className="icon">
                  <IoMdCheckmark />
                </span>
                <span className="text">
                  Vendors, seize this opportunity! Get your {props.name}{" "}
                  subscription absolutely{" "}
                  <span className="underline bold">FREE</span> and connect with
                  potential clients forever. Claim your membership today!
                </span>
              </li>
            )}
          </ul>

          <div className="my-bottom">
            <div className="price">
              <h6>Price</h6>
              <span>£ {props.price}</span>
            </div>
          </div>
        </div>

        <div className="my-bottom">
          {!props.noButton && (
            <button
              onClick={() => {
                if (isAnyPlanSubscribed) {
                  if (isSubscribed) {
                    if (isSubscribedPlanWillCancelling)
                      props.onReSubscribe && props.onReSubscribe();
                    else props.onCancel && props.onCancel();
                  } else {
                    props.onUpgradeDowngrade && props.onUpgradeDowngrade();
                  }
                } else navigate(`/checkout?name=${props.name}`);
              }}
              disabled={
                getUserDetails2Data?.loading ||
                isWantedToUpdateDowngradeToThisPlan
              }
            >
              {(() => {
                if (getUserDetails2Data?.loading) return "Loading...";

                if (isAnyPlanSubscribed) {
                  if (isSubscribed) {
                    if (isSubscribedPlanWillCancelling) return "Re-Subscribe";
                    else return "Unsubscribe";
                  } else {
                    if (thisPlanPriority < subscripedPlanPriority)
                      return "Downgrade";
                    else return "Upgrade";
                  }
                } else return "Subscribe";
              })()}
            </button>
          )}
        </div>
      </div>

      {props?.coupon && <p className="my-coupon">OFFER</p>}
    </MemberShipCardStyled>
  );
};

const MemberShipCardStyled = styled.div`
  &,
  &* {
    box-sizing: border-box;
  }
  position: relative;
  background-color: ${({ color }) =>
    color === "brown"
      ? "#603B30"
      : color === "silver"
      ? "#C5C5C5"
      : color === "gold"
      ? "#C57934"
      : "#2D2D2D"};
  overflow: hidden;
  padding: 2.4rem 1.6rem;
  border-radius: 0.8rem;
  display: grid;
  grid-template-rows: 1fr;
  min-height: 50rem;

  & > .my-background {
    clip-path: polygon(100% 0, 100% 33%, 0 100%, 0 66%);
    background-color: black;
    position: absolute;
    inset: 0;
  }

  & > .my-group {
    position: relative;
    z-index: 1;

    max-width: 30rem;
    margin: 0 auto;
    display: grid;
    gap: 2.4rem;
    grid-template-rows: auto 1fr auto;

    & > .my-top {
      display: grid;
      align-items: center;
      justify-items: center;

      & > h2 {
        font-size: 2.4rem;
        color: white;
        align-items: center;
      }

      & > p {
        font-size: 1.6rem;
        color: white;
        align-items: center;
      }
    }

    & > .my-middle {
      display: grid;
      gap: 1.6rem;
      grid-template-rows: 1fr auto;

      & > .my-top {
        display: grid;
        list-style: none;
        gap: 1.8rem;
        align-content: start;

        & > li {
          display: grid;
          gap: 0.8rem;
          grid-template-columns: auto 1fr;
          align-items: center;

          & > .icon {
            color: green;
            line-height: 0;
            font-size: 1.6rem;
          }

          & > .text {
            font-size: 1.6rem;
            color: white;
          }
        }
      }

      & > .my-bottom {
        display: grid;
        gap: 1.6rem;

        & > p {
          font-size: 1.4rem;
          color: white;
        }

        & > .price {
          justify-self: center;
          border: 0.1rem solid rgba(255, 255, 255, 1);
          padding: 1.6rem;
          border-radius: 1000rem;
          display: grid;
          grid-template-columns: auto auto;
          gap: 2.4rem;
          align-items: center;

          & > h6 {
            font-size: 1.8rem;
            color: white;
            font-weight: 700;
          }

          & > span {
            font-size: 1.8rem;
            color: white;
            font-weight: 500;
          }
        }
      }
    }

    & > .my-bottom {
      display: grid;
      justify-content: center;

      & > button {
        padding: 0.8rem 1.2rem;
        border: none;
        border-radius: 0.6rem;
        cursor: pointer;
        outline: none;
        transition: opacity 0.3s ease-in;
        will-change: opacity;

        &:disabled {
          cursor: not-allowed;
        }

        &:not(:disabled):hover {
          opacity: 0.7;
        }
      }
    }
  }

  & > .my-coupon {
    transform-origin: center center;
    transform: rotate(-45deg);
    position: absolute;
    z-index: 2;
    left: -4.2rem;
    top: 2rem;
    width: 15rem;

    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    text-align: center;
    background-color: #ffc841;
    color: black;
  }
`;
