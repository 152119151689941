import React from "react";
import { Messages } from "../../../../components";
import { userData, dummy__messages } from "../../../../data/messageData";

const CustomerMessage = () => {
  return (
    <>
      <Messages users={userData} messages={dummy__messages} />
    </>
  );
};

export default CustomerMessage;
