import React from "react";
import { GoCheck } from "react-icons/go";
import { IoMdCheckmark } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./ManageMembership.scss";
import useUser from "../../../../hooks/useUser";

export const MemberShipCard = (props) => {
  return (
    <>
      <div
        className={`membershipcardcheckout  ${props.className}`}
        onClick={props.onClick}
      >
        <div className="membershipcardcheckout-container-content">
          <h4>{props.name}</h4>
          <div>
            {props.features?.map((item) => (
              <Correct>{item}</Correct>
            ))}
          </div>
          <div style={{ marginTop: "5rem" }}></div>
        </div>
        <div className="membershipcardcheckout-container"></div>
      </div>
    </>
  );
};

const Correct = (props) => {
  return (
    <>
      <div className="correct align-item-center m-3">
        <div>
          {" "}
          <IoMdCheckmark style={{ fontSize: "2rem", fontWeight: "700" }} />
        </div>
        <p>{props.children}</p>
      </div>
    </>
  );
};
