import React, { useEffect } from "react";
import "./Faq.scss";

import { faq } from "../../../assests";

import { Container, Heading, Text } from "../../../components";
import { getAllFAQs } from "../../../store/actions";
import { useSelector } from "react-redux";
import { CenteredLoader } from "../../vender/dashboard/Chat/Chat";

const Faq = () => {
  const { getAllFAQsData } = useSelector((s) => s.faqReducer);
  useEffect(() => {
    getAllFAQs();
  }, []);
  return getAllFAQsData.loading ? (
    <div style={{ padding: "0 5rem" }}>
      <CenteredLoader />
    </div>
  ) : (
    <>
      <div className="faq">
        <div className="faq-hero">
          <img src={faq} alt="faq" />
        </div>

        <div className="faq-main m-6">
          <Container className="main">
            <Container className="md">
              {getAllFAQsData?.data?.results?.map((t, i) => (
                <div key={i}>
                  <Question>{t?.question}</Question>
                  <Text>{t?.answer} </Text>
                </div>
              ))}
            </Container>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Faq;

// Sub Compoents

const Question = (props) => {
  return (
    <>
      <div className="question m-3">
        <Heading>Q. </Heading>
        <Heading>{props.children}</Heading>
      </div>
    </>
  );
};
