import { combineReducers } from "redux";
import customerAuthReducer from "./customerAuth.reducer";
import vendorAuthReducer from "./vendorAuth.reducer";
import vendorReducer from "./vendor.reducer";
import customerReducer from "./customer.reducer";
import publicReducer from "./public.reducer";
import occasionReducer from "./occassion.reducer";
import servicesTypesReducer from "./serviceTypes.reducer";
import fileUploadReducer from "./fileUpload.reducer";
import vendorServices2Reducer from "./vendorServices2.reducer";
import occasion2Reducer from "./occasion2.reducer";
import chatReducer from "./chat.reducer";
import notificationReducer from "./notification.reducer";
import userReducer from "./user.reducer";
import faqReducer from "./faq.reducer";

const rootReducer = combineReducers({
  customerAuthReducer,
  vendorAuthReducer,
  vendorReducer,
  customerReducer,
  publicReducer,
  occasionReducer,
  fileUploadReducer,
  servicesTypesReducer,
  vendorServices2Reducer,
  occasion2Reducer,
  chatReducer,
  notificationReducer,
  userReducer,
  faqReducer,
});

export default rootReducer;
