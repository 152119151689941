import axios from "axios";
import { BACKEND_URL } from "../../constants";
import { asyncCatch } from "../../utils";
import { chatContants } from "../constants";

export const getAllChats = asyncCatch(async ({ payload, config }) => {
  const { data } = await axios.get(`${BACKEND_URL}/v1/api/chat`, config);
  return data;
}, chatContants.GET_ALL_CHATS);

export const createNewChat = asyncCatch(
  async ({ payload: { body }, config }) => {
    const { data } = await axios.post(
      `${BACKEND_URL}/v1/api/chat`,
      body,
      config
    );
    return data;
  },
  chatContants.POST_NEW_CHAT
);

export const createNewChatWithAdmins = asyncCatch(
  async ({ payload, config }) => {
    const { data } = await axios.post(
      `${BACKEND_URL}/v1/api/chat/create-with-admins`,
      {},
      config
    );
    return data;
  },
  chatContants.POST_CHATS_WITH_ADMINS
);

export const updateMessages = (payload) => ({
  type: chatContants.UPDATE_MESSAGE,
  payload,
});
