import React, { createContext, useEffect, useState } from 'react';

const ServicesContext = createContext();

const ServiceProvider = ({ children }) => {
  const [selectedService, setSelectedService] = useState("Choose Service");

  return (
    <ServicesContext.Provider value={{ selectedService, setSelectedService }}>
      {children}
    </ServicesContext.Provider>
  );
}

export { ServiceProvider, ServicesContext };
