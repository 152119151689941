import React from "react";
import "./Review.scss";
import Slider from "react-slick";
import Container from "../container/Container";
import { useSelector } from "react-redux";

const Reviews = () => {
  const { landingPageInfo } = useSelector((s) => s.publicReducer);
console.log(landingPageInfo);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1600,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="reviewsection">
        <Container className="sm">
          <Slider {...settings}>
            {landingPageInfo?.reviews.map((review) => (
              <ReviewCard
                review={review?.description ?? "--"}
                name={review?.name ?? "--"}
                month={review?.time ?? "--"}
                key={review._id}
              />
            ))}
          </Slider>
        </Container>
      </div>
    </>
  );
};

export default Reviews;

const ReviewCard = (props) => {
  return (
    <>
      <div className="reviewcard">
        <div className="reviewcard-container center">
          <p>{props.review}</p>
          <div className="reviewcard-container-name center m-2">
            <h5>{props.name}</h5>
            <p>{props.month}</p>
          </div>
        </div>
      </div>
    </>
  );
};
