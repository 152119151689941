import { useState, useEffect } from "react";
import "./MyServices.scss";
import { Container, Heading } from "../../../../components";
import { MdDelete, MdDeleteForever } from "react-icons/md";
import { HiEye } from "react-icons/hi";
import { FaRegEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Puff } from "react-loader-spinner";
import toast from "react-hot-toast";
import {
  GetMyService,
  DeleteMyService,
  clearErrors,
  clearMessages,
  patchVendorServiceDeleteRequest2,
  GetMyUnviewedStatusUpdateServices,
  ChangeUnviewdStatusToView,
  getTotalNumberOfUnviewedServicesUpdate,
  patchToggleArchivedStatusOfService,
} from "./../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@mui/styles";
import { fakeServiceDetailsData } from "./fakeServiceDetailsData";
import { useQueryParams } from "../../../../hooks";
import {
  NOTIFICATION_TYPES,
  updateNotificationSeen,
} from "../../../../firebase/notfication";
import useCallOnDependencyChange from "../../../../hooks/useCallOnDependencyChange";
import useUser from "../../../../hooks/useUser";
import { Modal } from "@mui/material";
import { BiSolidArchiveIn, BiSolidArchiveOut } from "react-icons/bi";
import { Tooltip } from "../../../../components/common";

const useStyles = makeStyles({
  root: {
    "& .MuiPaginationItem-root": {
      color: "black",
      backgroundColor: "#ffc841",
      "&:hover": {
        backgroundColor: "#ffc841",
        color: "black",
      },
      "& .Mui-selected": {
        backgroundColor: "#ffc841",
        color: "black",
      },
    },
  },
});

const MyServices = () => {
  const { getWillAllMyServiceDeleteData } = useSelector((s) => s.vendorReducer);
  const [isWarningModalShow, setIsWarningModalShow] = useState(
    Boolean(getWillAllMyServiceDeleteData?.data?.willDelete)
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, setQueryParam } = useQueryParams({
    page: "1",
  });
  const { details, errors, message, loading, totalPages } = useSelector(
    (state) => state.vendorReducer
  );
  const { patchToggleArchivedStatusOfServiceData } = useSelector(
    (s) => s.vendorServices2Reducer
  );
  const { allNotifications } = useSelector((s) => s.notificationReducer);
  const vendorUser = useUser();

  useEffect(() => {
    vendorUser?.id && dispatch(GetMyService(vendorUser?.id, page, true));
  }, [dispatch, page, vendorUser?.id]);

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
    }
  }, [errors, message]);

  useEffect(() => {
    updateNotificationSeen(NOTIFICATION_TYPES.SERVICE);
  }, [allNotifications]);

  useCallOnDependencyChange(() => {
    if (
      allNotifications?.filter(
        (n) => n.notficationType === NOTIFICATION_TYPES.SERVICE
      ).length &&
      vendorUser?.id
    )
      dispatch(GetMyService(vendorUser?.id, page, true));
  }, [allNotifications]);

  const handleDelete = (_id) => {
    patchVendorServiceDeleteRequest2({
      payload: { body: { deleteStatus: "PENDING" }, _id },
      onSuccess: () => {
        toast.success("Delete request has been sent!");
        dispatch(GetMyService(vendorUser?.id, page, true));
      },
    });
  };

  const cancelDeleteRequest = (_id) => {
    patchVendorServiceDeleteRequest2({
      payload: { body: { deleteStatus: "NULL" }, _id },
      onSuccess: () => {
        toast.success("Delete request has been cancelled!");
        dispatch(GetMyService(vendorUser?.id, page, true));
      },
    });
  };

  const handleToggleArchivedStatus = (_id) => {
    patchToggleArchivedStatusOfService({
      payload: { _id },
      onSuccess: (data) => {
        toast.success(data.isArchived ? "Archived!" : "Un-Archived!");
        dispatch(GetMyService(vendorUser?.id, page, true));
      },
    });
  };

  return (
    <>
      <Container className="md customer-container-2g2">
        <Heading className="center m-5">My Services</Heading>
        <div className="table-container custom-scrollbar">
          <div className="myservices-table m-2">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Service name</th>
                  <th>Status</th>
                  <th>Reason</th>
                  <th>Likes</th>
                  <th>Archived Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loading || patchToggleArchivedStatusOfServiceData.loading ? (
                  <tr>
                    <Puff
                      height="60"
                      width="60"
                      radius="6"
                      color="black"
                      ariaLabel="loading"
                      wrapperStyle
                      wrapperClass
                    />
                  </tr>
                ) : details.length > 0 ? (
                  <>
                    {details.length > 0 &&
                      details.map((data, ind) => {
                        return (
                          <tr key={ind}>
                            <td>{1 + (9 * (page - 1) + ind)}</td>
                            <td>
                              {data?.customServiceName ||
                                data.serviceName?.serviceName ||
                                "--"}
                            </td>
                            <td>
                              {data.status}
                              {data?.acceptedEdit
                                ? " (Edit Request Status: Pending)"
                                : ""}
                            </td>
                            <td>{data?.reason && data.reason}</td>
                            <td>{data?.like?.length || 0}</td>
                            <td>
                              {data?.isArchived ? "Archived" : "Un-Archived"}
                            </td>
                            <td>
                              <Link to={`/event-detail/${data.id}`}>
                                <HiEye />
                              </Link>

                              {data?.status === "Rejected" ||
                              data?.status === "Accepted" ? (
                                <Link to={`/edit-service/${data.id}`}>
                                  <FaRegEdit />
                                </Link>
                              ) : (
                                <FaRegEdit style={{ cursor: "not-allowed" }} />
                              )}

                              <Tooltip
                                title={
                                  !data?.isArchived ? "Archived" : "Un-Archived"
                                }
                              >
                                <div
                                  onClick={() =>
                                    handleToggleArchivedStatus(data.id)
                                  }
                                >
                                  {!data?.isArchived ? (
                                    <BiSolidArchiveIn />
                                  ) : (
                                    <BiSolidArchiveOut />
                                  )}
                                </div>
                              </Tooltip>

                              {data?.status === "Rejected" ? (
                                <MdDelete style={{ cursor: "not-allowed" }} />
                              ) : data?.deleteStatus !== "PENDING" ? (
                                <MdDelete
                                  onClick={() => {
                                    handleDelete(data.id);
                                  }}
                                />
                              ) : (
                                <MdDeleteForever
                                  onClick={() => {
                                    cancelDeleteRequest(data.id);
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </>
                ) : (
                  <tr>
                    <td colSpan={6}>No service found</td>
                  </tr>
                )}
              </tbody>
            </table>
            {totalPages > 1 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "4rem",
                }}
              >
                <Pagination
                  classes={{ root: classes.root }}
                  variant="outlined"
                  count={totalPages}
                  page={Number(page)}
                  size="large"
                  showFirstButton
                  showLastButton
                  onChange={(e, value) => setQueryParam({ page: value })}
                />
              </div>
            )}
          </div>
        </div>
      </Container>

      <WarningModal
        open={isWarningModalShow}
        onClose={() => setIsWarningModalShow(false)}
      />
    </>
  );
};

export default MyServices;

const WarningModal = ({ open, onClose, onConfirmation, disabled }) => {
  return (
    <Modal
      open={open}
      onClose={!disabled && onClose}
      onConfirmation={onConfirmation}
      className="confirmation-modal-lksadjf239jf2"
    >
      <div className="confirmation-modal-239jv2">
        <div className="top">
          <h6>Warning</h6>
          <p>
            Your service will be deleted soon. If you don't want this to happen,
            please buy a subscription.
          </p>
        </div>
        <div className="bottom">
          <button
            onClick={onClose}
            className="button-sdkjfkj2939j3f23"
            disabled={disabled}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};
