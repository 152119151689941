import { BACKEND_URL } from "../../constants";
import { customerConstant } from "./../constants";
import axios from "axios";

export const ChangePasswordByCustomer = (body) => {
  return async (dispatch) => {
    dispatch({ type: customerConstant.CHANGE_PASSWORD_REQUEST });
    try {
      const token = sessionStorage.getItem("userToken");
      await axios.post(`${BACKEND_URL}/v1/api/auth/change/password`, body, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      dispatch({
        type: customerConstant.CHANGE_PASSWORD_SUCCESS,
        payload: "Password has been changed",
      });
    } catch (error) {
      dispatch({
        type: customerConstant.CHANGE_PASSWORD_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const EditCustomerProfile = (body, onSuccess) => {
  return async (dispatch) => {
    dispatch({ type: customerConstant.EDIT_PROFILE_REQUEST });
    try {
      const token = sessionStorage.getItem("userToken");
      const result = await axios.patch(
        `${BACKEND_URL}/v1/api/auth/update/user`,
        body,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: customerConstant.EDIT_PROFILE_SUCCESS,
        payload: "Profile has been updated",
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch({
        type: customerConstant.EDIT_PROFILE_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const AddMessageRequest = (body, vendorServiceId, onSuccess) => {
  return async (dispatch) => {
    dispatch({ type: customerConstant.MESSAGE_TO_VENDOR_REQUEST });
    try {
      const token = sessionStorage.getItem("userToken");
      const { data } = await axios.post(
        `${BACKEND_URL}/v1/api/service/customer/message/request/for/vendor/${vendorServiceId}`,
        body,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({
        type: customerConstant.MESSAGE_TO_VENDOR_SUCCESS,
        payload: "Request has been sent to vendor",
      });

      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch({
        type: customerConstant.MESSAGE_TO_VENDOR_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const sendSupportForm = (body, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch({ type: customerConstant.POST_SUPPORT_REQUEST });
    try {
      const token = sessionStorage.getItem("userToken");
      await axios.post(`${BACKEND_URL}/v1/api/message/add/support`, body, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      dispatch({
        type: customerConstant.POST_SUPPORT_REQUEST,
        payload: "Form submitted!",
      });
      onSuccess();
    } catch (error) {
      dispatch({
        type: customerConstant.POST_SUPPORT_REQUEST,
        payload: { err: error.response.data.errors[0].message },
      });
      onError();
    }
  };
};

export const getCustomerInfo = (onSuccess, onError) => {
  return async (dispatch) => {
    dispatch({ type: customerConstant.GET_USER_INFO_REQUEST });
    try {
      const token = sessionStorage.getItem("userToken");
      const { data } = await axios.get(
        `${BACKEND_URL}/v1/api/service/get/customer/info`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({
        type: customerConstant.GET_USER_INFO_SUCCESS,
        payload: data,
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch({
        type: customerConstant.GET_USER_INFO_FAILURE,
        payload: { err: error?.response?.data?.errors[0].message },
      });
      onError && onError();
    }
  };
};

export const getCustomerOrders = (onSuccess, onError) => {
  return async (dispatch) => {
    dispatch({ type: customerConstant.GET_CUSTOMER_ORDERS_REQUEST });
    try {
      const token = sessionStorage.getItem("userToken");
      const { data } = await axios.get(
        `${BACKEND_URL}/v1/api/service/get/customer/orders`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({
        type: customerConstant.GET_CUSTOMER_ORDERS_SUCCESS,
        payload: data,
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch({
        type: customerConstant.GET_CUSTOMER_ORDERS_FAILURE,
        payload: { err: error?.response?.data?.errors[0].message },
      });
      onError && onError();
    }
  };
};

export const getMessageRequestForCustomer = ({ _id }, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch({
      type: customerConstant.GET_MESSAGE_REQUEST_FOR_CUSTOMER_REQUEST,
    });
    try {
      const token = sessionStorage.getItem("userToken");
      const { data } = await axios.get(
        `${BACKEND_URL}/v1/api/service/get/request/for/customer/${_id}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({
        type: customerConstant.GET_MESSAGE_REQUEST_FOR_CUSTOMER_SUCCESS,
        payload: data,
      });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch({
        type: customerConstant.GET_MESSAGE_REQUEST_FOR_CUSTOMER_FAILURE,
        payload: { err: error?.response?.data?.errors[0].message },
      });
      onError && onError();
    }
  };
};

export const updateMessageRequestForCustomer = (
  { _id, body },
  onSuccess,
  onError
) => {
  return async (dispatch) => {
    dispatch({
      type: customerConstant.UPDATE_MESSAGE_REQUEST_FOR_CUSTOMER_REQUEST,
    });
    try {
      const token = sessionStorage.getItem("userToken");
      const { data } = await axios.put(
        `${BACKEND_URL}/v1/api/service/update/request/for/customer/${_id}`,
        body,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({
        type: customerConstant.UPDATE_MESSAGE_REQUEST_FOR_CUSTOMER_SUCCESS,
        payload: data,
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch({
        type: customerConstant.UPDATE_MESSAGE_REQUEST_FOR_CUSTOMER_FAILURE,
        payload: { err: error?.response?.data?.errors[0].message },
      });
      onError && onError();
    }
  };
};

export const addCustomerToEmailList = (onSuccess, onError) => {
  return async (dispatch) => {
    try {
      const token = sessionStorage.getItem("userToken");
      dispatch({ type: customerConstant.ADD_CUSTOMER_TO_EMAIL_LIST_SUCCESS });
      await axios.patch(
        `${BACKEND_URL}/v1/api/email-list/toggle`,
        {},
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({ type: customerConstant.ADD_CUSTOMER_TO_EMAIL_LIST_SUCCESS });

      onSuccess && onSuccess();
    } catch (error) {
      onError && onError();
      dispatch({ type: customerConstant.ADD_CUSTOMER_TO_EMAIL_LIST_FAILURE });
    }
  };
};
