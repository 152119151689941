import { useEffect, useRef } from "react";

function useCallOnDependencyChange(callback, dependencies) {
  const firstRender = useRef(true);

  useEffect(() => {
    // Skip the callback on the first render
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    // Call the callback function when dependencies change
    callback();
  }, dependencies);
}

export default useCallOnDependencyChange;
