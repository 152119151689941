import React, { useRef, useState, useEffect, useMemo } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Container, Heading, Button, FormInput } from "../../../../components";
import { profile } from "../../../../assests";
import { MdModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  EditUserProfile,
  clearErrors,
  clearMessages,
} from "./../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import FormSelect from "../../../../components/FormInput/FormSelect";
import { BACKEND_STATIC_URL } from "../../../../constants";
import { reGetUserDetails2 } from "../../../../store/actions/user.action";
const VenderEditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.vendorReducer);
  const { getUserDetails2Data } = useSelector((s) => s.userReducer);

  const vendorUser = useMemo(
    () => getUserDetails2Data.data,
    [getUserDetails2Data.data]
  );
  const [image, setImage] = useState(null);
  const [userUpdatedImage, setUserUpdatedImage] = useState("");
  const imageRef = useRef();

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUserUpdatedImage(event.target.files[0]);
    } else {
      setUserUpdatedImage(null);
    }
  };

  useEffect(() => {
    if (userUpdatedImage) {
      setImage(URL.createObjectURL(userUpdatedImage));
    } else {
      setImage(null);
    }
  }, [userUpdatedImage]);

  const validation = Yup.object({
    // left side
    name: Yup.string().min(2, "Must be 2 character"),
    // email: Yup.string().email("Email is invalid"),
    company_name: Yup.string().min(2, "Must be 2 character"),
    instagram: Yup.string().min(2, "Must be 2 character"), // Right Side
    phone: Yup.string()
      .matches(/^0/, "Phone number must start with zero")
      .matches(/^\d*$/, "Phone number must contain only numeric characters")
      .required("Contact number is required"),
    address: Yup.string().min(2, "Must be 2 character"),
    business_type: Yup.string().min(2, "Must be 2 character"), //   middle
    website: Yup.string().min(2, "Must be 2 character"),
  });

  return (
    <>
      <Container className="half">
        <div className="edit-profile m-5">
          <Heading className="primary center m-5">Edit Profile</Heading>

          <div className="edit-profile-image center">
            <img
              src={
                image
                  ? image
                  : vendorUser?.photoPath
                  ? `${BACKEND_STATIC_URL}${vendorUser?.photoPath}`
                  : profile
              }
              alt="profile"
            />
            <MdModeEdit onClick={() => imageRef.current.click()} />
          </div>
          <div style={{ display: "none" }}>
            <input
              type="file"
              name="myImage"
              ref={imageRef}
              onChange={onImageChange}
              accept=".png,.jpg,.jpeg"
            />
          </div>

          <div className="m-4">
            <Formik
              initialValues={{
                fullName: vendorUser?.fullName ? vendorUser?.fullName : "",
                company_name: vendorUser?.companyName
                  ? vendorUser?.companyName
                  : "",
                instagram: vendorUser?.instagram ? vendorUser?.instagram : "",
                phone: vendorUser?.phone ? vendorUser?.phone : "",
                address: vendorUser?.address ? vendorUser?.address : "",
                business_type: vendorUser?.bussinessType
                  ? vendorUser?.bussinessType
                  : "",
                website: vendorUser?.website ? vendorUser?.website : "",
              }}
              validationSchema={validation}
              onSubmit={(values, { resetForm }) => {
                const {
                  fullName,
                  company_name,
                  instagram,
                  phone,
                  address,
                  business_type,
                  website,
                } = values;
                const result = {
                  fullName,
                  companyName: company_name,
                  instagram,
                  phone: phone,
                  address,
                  bussinessType: business_type,
                  website: website,
                };

                const finalResult = new FormData();
                finalResult.append("fullName", result.fullName);
                finalResult.append("companyName", result.companyName);
                finalResult.append("instagram", result.instagram);
                finalResult.append("phone", result.phone);
                finalResult.append("address", result.address);
                finalResult.append("bussinessType", result.bussinessType);
                finalResult.append("website", result.website);

                if (userUpdatedImage) {
                  finalResult.append("photoPath", userUpdatedImage);
                }

                dispatch(
                  EditUserProfile(finalResult, () => {
                    reGetUserDetails2({
                      onSuccess: () => {
                        navigate("/vender-settings");
                      },
                    });
                  })
                );
              }}
            >
              {(formik) => (
                <div className="m-5">
                  <Form>
                    <div className="vendersignup-form">
                      <div className="vendersignup-form-left">
                        <FormInput label="Name" name="fullName" type="text" />
                        <FormInput
                          label="Company name"
                          name="company_name"
                          type="text"
                        />
                        <FormInput
                          label="Instagram"
                          name="instagram"
                          type="text"
                        />
                      </div>
                      <div className="vendersignup-form-right">
                        <FormInput
                          label="Contact Number"
                          name="phone"
                          type="text"
                        />

                        <FormInput label="Address" name="address" type="text" />
                        <FormSelect
                          label="Type of business"
                          place="Type of business"
                          name="business_type"
                          type="string"
                        >
                          <option>Type of business</option>
                          <option>Sole Trader</option>
                          <option>Partnership</option>
                          <option>Private Limited Company</option>
                        </FormSelect>
                      </div>
                    </div>
                    <Container className="half">
                      <FormInput label="Website" name="website" type="text" />
                    </Container>
                    <div className="save-btn">
                      <Button className="btn-lighter rounded center m-2">
                        {loading || getUserDetails2Data.fetching
                          ? "Please Wait"
                          : "Update"}
                      </Button>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </>
  );
};

export default VenderEditProfile;
