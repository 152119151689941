import React from "react";
import { VendorMessage } from "../../../../components";
import { userData, dummy__messages } from "../../../../data/messageData";

const VendorMessageComponent = () => {
  return (
      <VendorMessage users={userData} messages={dummy__messages} />
  );
};

export default VendorMessageComponent;
