import React from "react";
import "./MyAccount.scss";
import { profile } from "../../../../assests";
import { Button, Container, LinkBtn, InfoItem } from "../../../../components";
import { FaEdit } from "react-icons/fa";
import { BACKEND_STATIC_URL } from "../../../../constants";
import useUser from "../../../../hooks/useUser";
const MyAccount = () => {
  const customerUser = useUser();
  return (
    <>
      <div className="myaccount">
        <Container className="half">
          <div className="myaccount-container m-5">
            <div className="myaccount-container-image center">
              <img
                src={
                  customerUser?.photoPath
                    ? `${BACKEND_STATIC_URL}${customerUser?.photoPath}`
                    : profile
                }
                alt="profile"
              />
            </div>
            <div className="m-3">
              {/* <Button className="rounded align-item-center align-item-right"></Button> */}

              <LinkBtn
                to="/customer-edit-profile"
                className="rounded align-item-center align-item-right"
              >
                <FaEdit style={{ marginRight: ".5rem" }} />
                Edit Profile
              </LinkBtn>
            </div>

            <div>
              <InfoItem
                title="Name"
                content={customerUser?.fullName ? customerUser?.fullName : ""}
              />
              <InfoItem
                title="Phone"
                content={customerUser?.phone ? customerUser?.phone : ""}
              />
              <InfoItem
                title="Email"
                content={customerUser?.email ? customerUser?.email : ""}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default MyAccount;
