export const vendorServices2Constants = {
  POST_VENDOR_SERVICE_2: {
    pending: "POST_VENDOR_SERVICE_2_REQUEST",
    success: "POST_VENDOR_SERVICE_2_SUCCESS",
    error: "POST_VENDOR_SERVICE_2_FAILURE",
  },
  GET_ALL_VENDOR_SERVICE_2: {
    pending: "GET_ALL_VENDOR_SERVICE_2_REQUEST",
    success: "GET_ALL_VENDOR_SERVICE_2_SUCCESS",
    error: "GET_ALL_VENDOR_SERVICE_2_FAILURE",
  },
  PATCH_VENDOR_SERVICE_DELETE_REQUEST_2: {
    pending: "PATCH_VENDOR_SERVICE_DELETE_REQUEST_2_REQUEST",
    success: "PATCH_VENDOR_SERVICE_DELETE_REQUEST_2_SUCCESS",
    error: "PATCH_VENDOR_SERVICE_DELETE_REQUEST_2_FAILURE",
  },
  PATCH_CANCEL_VENDOR_SERVICE_DELETE_REQUEST_2: {
    pending: "PATCH_CANCEL_VENDOR_SERVICE_DELETE_REQUEST_2_REQUEST",
    success: "PATCH_CANCEL_VENDOR_SERVICE_DELETE_REQUEST_2_SUCCESS",
    error: "PATCH_CANCEL_VENDOR_SERVICE_DELETE_REQUEST_2_FAILURE",
  },
  GET_VENDOR_DETAILS_2: {
    pending: "GET_VENDOR_DETAILS_2_REQUEST",
    success: "GET_VENDOR_DETAILS_2_SUCCESS",
    error: "GET_VENDOR_DETAILS_2_FAILURE",
  },

  PATCH_TOGGLE_ARCHIVED_STATUS_OF_SERVICE: {
    pending: "PATCH_TOGGLE_ARCHIVED_STATUS_OF_SERVICE_REQUEST",
    success: "PATCH_TOGGLE_ARCHIVED_STATUS_OF_SERVICE_SUCCESS",
    error: "PATCH_TOGGLE_ARCHIVED_STATUS_OF_SERVICE_FAILURE",
  },
};
