import { fileUploadConstants } from "./../constants";

const initialState = {
  postFileUploadData: {
    loading: false,
  },
};

const fileUploadReducer = (s = initialState, action) => {
  const state = { ...s };
  switch (action.type) {
    case fileUploadConstants.POST_FILE_UPLOAD.pending:
      state.postFileUploadData.loading = true;
      break;
    case fileUploadConstants.POST_FILE_UPLOAD.success:
    case fileUploadConstants.POST_FILE_UPLOAD.error:
      state.postFileUploadData.loading = false;
      break;

    default:
      break;
  }

  return state;
};

export default fileUploadReducer;
