// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text > p {
  color: black;
  font-size: 1.5rem;
  line-height: 2.5rem; }
`, "",{"version":3,"sources":["webpack://./src/components/text/Text.scss"],"names":[],"mappings":"AAAA;EAEI,YAAY;EACZ,iBAAiB;EACjB,mBAAkB,EAAA","sourcesContent":[".text {\n  & > p {\n    color: black;\n    font-size: 1.5rem;\n    line-height:2.5rem;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
