import axios from "axios";
import { asyncCatch } from "../../utils";
import { BACKEND_URL } from "../../constants";
import { userContants } from "../constants";

export const getUserDetails2 = asyncCatch(async ({ payload, config }) => {
  const token =
    sessionStorage.getItem("userToken") ||
    sessionStorage.getItem("vendorToken");

  if (!token) return null;
  const { data } = await axios.get(
    `${BACKEND_URL}/v1/api/auth/current/user`,
    config
  );
  return data;
}, userContants.GET_USER_DETAILS_2);

export const reGetUserDetails2 = asyncCatch(async ({ payload, config }) => {
  const token =
    sessionStorage.getItem("userToken") ||
    sessionStorage.getItem("vendorToken");

  if (!token) return null;
  const { data } = await axios.get(
    `${BACKEND_URL}/v1/api/auth/current/user`,
    config
  );
  return data;
}, userContants.RE_GET_USER_DETAILS_2);
