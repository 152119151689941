import globalHookData from "../../utils/globalHookData";
import { customerAuthConstant, vendorConstant } from "./../constants";

const initialState = {
  details: [],
  eventServices: [],
  productServices: [],
  customerRequest: [],
  chatUser: [],
  chatConnectionIds: [],
  chatVendor: [],
  admin: [],
  chatConnection: {},
  singleService: {},
  userInfo: {},
  totalViews: 0,
  totalFavourites: 0,
  totalLikes: 0,
  services: [],
  page: 1,
  totalPages: 1,
  errors: [],
  message: "",
  loading: false,
  loading2: false,
  subscriptionLoading: false,

  addNewViewLoading: false,

  getTotalNumberOfUnviewedServicesUpdateData: {
    loading: false,
    data: 0,
  },

  getUnviewedAllServiesData: {
    loading: false,
    data: [],
  },

  patchUnviewStatusToViewData: {
    loading: false,
  },

  reSubscribeVendorMembershipData: {
    loading: false,
  },

  toggleMailListData: {
    loading: false,
  },

  getCardsDetails: {
    loading: false,
    data: [],
  },

  getCustomerCompletedRequestData: {
    data: {
      results: [],
      page: 1,
      limit: 10,
      totalPages: 1,
      totalResults: 1,
    },
    loading: false,
    errors: [],
  },
  getCustomerCancelledRequestData: {
    data: {
      results: [],
      page: 1,
      limit: 10,
      totalPages: 1,
      totalResults: 1,
    },
    loading: false,
    errors: [],
  },
};

const vendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case vendorConstant.CHANGE_PASSWORD_REQUEST:
    case vendorConstant.GET_MY_SERVICE_REQUEST:
    case vendorConstant.ADD_CARD_REQUEST:
    case vendorConstant.DELETE_CARD_REQUEST:
    case vendorConstant.CREATE_VERIFICATION_REQUEST:
    case vendorConstant.DELETE_MY_SERVICE_REQUEST:
    case vendorConstant.EDIT_USER_PROFILE_REQUEST:
    case vendorConstant.CREATE_SERVICE_REQUEST:
    case vendorConstant.GET_SERVICE_BY_TYPE_REQUEST:
    case vendorConstant.GET_SINGLE_SERVICE_REQUEST:
    case vendorConstant.GET_FAVOURITE_SERVICES_REQUEST:
    case vendorConstant.GET_MEMBERSHIP_SUBSCRIPTION_REQUEST:
    case vendorConstant.GET_SERVICE_REQUEST:
    case vendorConstant.GET_ALL_CUSTOMER_REQUESTS_REQUEST:
    case vendorConstant.UPDATE_STATUS_CUSTOMER_REQUEST:
    case vendorConstant.GET_USER_FOR_CHAT_REQUEST:
    case vendorConstant.GET_VENDOR_FOR_CHAT_REQUEST:
    case vendorConstant.CREATE_CHAT_CONNECTION_REQUEST:
    case vendorConstant.GET_ALL_ADMIN_REQUEST:
    case vendorConstant.GET_ALL_CHATS_IDS_VENDOR_REQUEST:
    case vendorConstant.GET_ALL_SERVICES_OF_SINGLE_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
        message: "",
        errors: [],
      };

    case vendorConstant.GET_ALL_CUSTOMER_COMPLETED_REQUESTS_REQUEST:
      return {
        ...state,
        getCustomerCompletedRequestData: {
          ...state.getCustomerCompletedRequestData,
          loading: true,
          errors: [],
        },
      };
    case vendorConstant.GET_ALL_CUSTOMER_CANCELLED_REQUESTS_REQUEST:
      return {
        ...state,
        getCustomerCancelledRequestData: {
          ...state.getCustomerCancelledRequestData,
          loading: true,
          errors: [],
        },
      };
    case vendorConstant.CANCEL_MEMBERSHIP_VENDOR_REQUEST:
    case vendorConstant.GET_COUPON_DETAILS_REQUEST:
      return {
        ...state,
        loading2: true,
      };
    case vendorConstant.GET_CARD_REQUEST:
      return {
        ...state,
        getCardsDetails: {
          ...state.getCardsDetails,
          loading: true,
        },
      };
    case vendorConstant.GET_CARD_SUCCESS:
      return {
        ...state,
        getCardsDetails: {
          ...state.getCardsDetails,
          loading: false,
          data: action.payload,
        },
      };

    case vendorConstant.GET_CARD_FAILURE:
      return {
        ...state,
        getCardsDetails: {
          ...state.getCardsDetails,
          loading: false,
        },
      };

    case vendorConstant.CANCEL_MEMBERSHIP_VENDOR_SUCCESS:
    case vendorConstant.CANCEL_MEMBERSHIP_VENDOR_FAILURE:
    case vendorConstant.GET_ALL_SERVICES_OF_SINGLE_VENDOR_FAILURE:
    case vendorConstant.GET_COUPON_DETAILS_FAILURE:
    case vendorConstant.GET_COUPON_DETAILS_SUCCESS:
      return {
        ...state,
        loading2: false,
      };
    case vendorConstant.GET_ALL_SERVICES_OF_SINGLE_VENDOR_SUCCESS:
      return {
        ...state,
        loading2: false,
        ...action.payload,
      };

    case vendorConstant.ADD_CARD_BEFORE_SUBSCRIPTION_REQUEST:
    case vendorConstant.ADD_CARD_BEFORE_SUBSCRIPTION_SUCCESS:
    case vendorConstant.UPDATE_MEMBERSHIP_VENDOR_REQUEST:
      return {
        ...state,
        subscriptionLoading: true,
      };
    case vendorConstant.UPDATE_MEMBERSHIP_VENDOR_SUCCESS:
      return {
        ...state,
        subscriptionLoading: false,
        message: action.payload,
      };
    case vendorConstant.ADD_CARD_BEFORE_SUBSCRIPTION_FAILURE:
    case vendorConstant.UPDATE_MEMBERSHIP_VENDOR_FAILURE:
      return {
        ...state,
        subscriptionLoading: false,
        errors: action.payload.err,
      };
    case vendorConstant.ADD_MEMBERSHIP_SUBSCRIPTION_REQUEST:
    case vendorConstant.UPDATE_SINGLE_SERVICE_REQUEST:
    case vendorConstant.ADD_TO_FAVOURITE_REQUEST:
    case vendorConstant.ADD_TO_LIKE_REQUEST:
      return {
        ...state,
        subscriptionLoading: true,
        message: "",
        errors: [],
      };
    case vendorConstant.CHANGE_PASSWORD_SUCCESS:
    case vendorConstant.ADD_CARD_SUCCESS:
    case vendorConstant.CREATE_VERIFICATION_SUCCESS:
    case vendorConstant.DELETE_MY_SERVICE_SUCCESS:
    case vendorConstant.EDIT_USER_PROFILE_SUCCESS:
    case vendorConstant.CREATE_SERVICE_SUCCESS:
    case vendorConstant.UPDATE_STATUS_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case vendorConstant.GET_TOTAL_FAVOURITE_SUCCESS:
      return {
        ...state,
        loading: false,
        totalFavourites: action.payload,
      };
    case vendorConstant.GET_TOTAL_LIKE_SUCCESS:
      return {
        ...state,
        loading: false,
        totalLikes: action.payload,
      };
    case vendorConstant.GET_TOTAL_VIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        totalViews: action.payload,
      };
    case vendorConstant.CREATE_CHAT_CONNECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        chatConnection: action.payload.results,
      };
    case vendorConstant.CLEAR_CHAT_CONNECTION:
      return {
        ...state,
        loading: false,
        chatConnection: {},
      };
    case vendorConstant.GET_USER_FOR_CHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        chatUser: action.payload,
      };
    case vendorConstant.GET_ALL_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        admin: action.payload,
      };
    case vendorConstant.GET_VENDOR_FOR_CHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        chatVendor: action.payload,
      };
    case vendorConstant.ADD_TO_FAVOURITE_SUCCESS:
      return {
        ...state,
        subscriptionLoading: false,
        message: action.payload,
        singleService: {
          ...state.singleService,
          favourite: state.singleService?.favourite?.includes(
            globalHookData.useUser?.id
          )
            ? state.singleService?.favourite?.filter(
                (id) => globalHookData.useUser?.id !== id
              )
            : [...state.singleService?.favourite, globalHookData.useUser?.id],
        },
      };

    case vendorConstant.ADD_TO_LIKE_SUCCESS:
      return {
        ...state,
        subscriptionLoading: false,
        message: action.payload,
        singleService: {
          ...state.singleService,
          like: state.singleService?.like?.includes(globalHookData.useUser?.id)
            ? state.singleService?.like?.filter(
                (id) => globalHookData.useUser?.id !== id
              )
            : [...state.singleService?.like, globalHookData.useUser?.id],
        },
      };
    case vendorConstant.ADD_MEMBERSHIP_SUBSCRIPTION_SUCCESS:
    case vendorConstant.UPDATE_SINGLE_SERVICE_SUCCESS:
      return {
        ...state,
        subscriptionLoading: false,
        message: action.payload,
      };
    case vendorConstant.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
      };
    case vendorConstant.DELETE_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        details: action.payload.data,
      };
    case vendorConstant.GET_ALL_CUSTOMER_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        customerRequest: action.payload,
      };

    case vendorConstant.GET_ALL_CUSTOMER_COMPLETED_REQUESTS_SUCCESS:
      return {
        ...state,
        getCustomerCompletedRequestData: {
          ...state.getCustomerCompletedRequestData,
          data: action.payload,
          loading: false,
        },
      };

    case vendorConstant.GET_ALL_CUSTOMER_CANCELLED_REQUESTS_SUCCESS:
      return {
        ...state,

        getCustomerCancelledRequestData: {
          ...state.getCustomerCancelledRequestData,
          data: action.payload,
          loading: false,
        },
      };

    case vendorConstant.GET_ALL_CHATS_IDS_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        chatConnectionIds: action.payload,
      };
    case vendorConstant.GET_SINGLE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleService: action.payload,
      };
    case vendorConstant.GET_MY_SERVICE_SUCCESS:
    case vendorConstant.GET_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        details: action.payload.results,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
      };
    case vendorConstant.GET_SERVICE_BY_TYPE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        eventServices: action.payload.results,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
      };
    case vendorConstant.GET_SERVICE_BY_TYPE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        productServices: action.payload.results,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
      };
    case vendorConstant.GET_FAVOURITE_SERVICES_SUCCESS:
    case vendorConstant.GET_MEMBERSHIP_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        details: action.payload,
      };

    case vendorConstant.RESET_REDUCER:
      return {
        ...state,
        ...initialState,
      };

    case vendorConstant.MARK_CARD_AS_DEFAULT_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case vendorConstant.MARK_CARD_AS_DEFAULT_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case vendorConstant.MARK_CARD_AS_DEFAULT_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };

    case vendorConstant.ADD_NEW_VIEW_REQUEST:
      return {
        ...state,
        addNewViewLoading: true,
      };
    case vendorConstant.ADD_NEW_VIEW_SUCCESS:
      return {
        ...state,
        addNewViewLoading: false,
      };
    case vendorConstant.ADD_NEW_VIEW_FAILURE:
      return {
        ...state,
        addNewViewLoading: false,
      };

    case vendorConstant.GET_TOTAL_NUMBER_OF_UNVIEWED_SERVICE_UPDATE_REQUEST:
      return {
        ...state,
        getTotalNumberOfUnviewedServicesUpdateData: {
          ...state.getTotalNumberOfUnviewedServicesUpdateData,
          loading: true,
        },
      };
    case vendorConstant.GET_TOTAL_NUMBER_OF_UNVIEWED_SERVICE_UPDATE_SUCCESS:
      return {
        ...state,
        getTotalNumberOfUnviewedServicesUpdateData: {
          ...state.getTotalNumberOfUnviewedServicesUpdateData,
          loading: false,
          data: action.payload,
        },
      };
    case vendorConstant.GET_TOTAL_NUMBER_OF_UNVIEWED_SERVICE_UPDATE_FAILURE:
      return {
        ...state,
        getTotalNumberOfUnviewedServicesUpdateData: {
          ...state.getTotalNumberOfUnviewedServicesUpdateData,
          loading: false,
        },
      };

    case vendorConstant.GET_UNVIEWED_ALL_SERVICES_REQUEST:
      return {
        ...state,
        getUnviewedAllServiesData: {
          ...state.getUnviewedAllServiesData,
          loading: true,
        },
      };
    case vendorConstant.GET_UNVIEWED_ALL_SERVICES_SUCCESS:
      return {
        ...state,
        getUnviewedAllServiesData: {
          ...state.getUnviewedAllServiesData,
          loading: false,
          data: action.payload,
        },
      };
    case vendorConstant.GET_UNVIEWED_ALL_SERVICES_FAILURE:
      return {
        ...state,
        getUnviewedAllServiesData: {
          ...state.getUnviewedAllServiesData,
          loading: false,
        },
      };

    case vendorConstant.PATCH_UNVIEW_STATUS_TO_VIEW_REQUEST:
      return {
        ...state,
        patchUnviewStatusToViewData: {
          ...state.patchUnviewStatusToViewData,
          loading: true,
        },
      };
    case vendorConstant.PATCH_UNVIEW_STATUS_TO_VIEW_SUCCESS:
    case vendorConstant.PATCH_UNVIEW_STATUS_TO_VIEW_FAILURE:
      return {
        ...state,
        patchUnviewStatusToViewData: {
          ...state.patchUnviewStatusToViewData,
          loading: false,
        },
      };

    case vendorConstant.RESUBSCRIBE_MEMBERSHIP_VENDOR_REQUEST:
      return {
        ...state,
        reSubscribeVendorMembershipData: {
          ...state.reSubscribeVendorMembershipData,
          loading: true,
        },
      };
    case vendorConstant.RESUBSCRIBE_MEMBERSHIP_VENDOR_SUCCESS:
    case vendorConstant.RESUBSCRIBE_MEMBERSHIP_VENDOR_FAILURE:
      return {
        ...state,
        reSubscribeVendorMembershipData: {
          ...state.reSubscribeVendorMembershipData,
          loading: false,
        },
      };

    case vendorConstant.ADD_TO_EMAIL_LIST_REQUEST:
      return {
        ...state,
        toggleMailListData: {
          ...state.toggleMailListData,
          loading: true,
        },
      };
    case vendorConstant.ADD_TO_EMAIL_LIST_SUCCESS:
    case vendorConstant.ADD_TO_EMAIL_LIST_FAILURE:
      return {
        ...state,
        toggleMailListData: {
          ...state.toggleMailListData,
          loading: false,
        },
      };

    case vendorConstant.CHANGE_PASSWORD_FAILURE:
    case vendorConstant.GET_MY_SERVICE_FAILURE:
    case vendorConstant.ADD_CARD_FAILURE:
    case vendorConstant.DELETE_CARD_FAILURE:
    case vendorConstant.CREATE_VERIFICATION_FAILURE:
    case vendorConstant.DELETE_MY_SERVICE_FAILURE:
    case vendorConstant.EDIT_USER_PROFILE_FAILURE:
    case vendorConstant.CREATE_SERVICE_FAILURE:
    case vendorConstant.GET_SERVICE_BY_TYPE_FAILURE:
    case vendorConstant.GET_SINGLE_SERVICE_FAILURE:
    case vendorConstant.ADD_TO_FAVOURITE_FAILURE:
    case vendorConstant.ADD_TO_LIKE_FAILURE:
    case vendorConstant.GET_FAVOURITE_SERVICES_FAILURE:
    case vendorConstant.GET_MEMBERSHIP_SUBSCRIPTION_FAILURE:
    case vendorConstant.ADD_MEMBERSHIP_SUBSCRIPTION_FAILURE:
    case vendorConstant.UPDATE_SINGLE_SERVICE_FAILURE:
    case vendorConstant.GET_SERVICE_FAILURE:
    case vendorConstant.GET_ALL_CUSTOMER_REQUESTS_FAILURE:
    case vendorConstant.UPDATE_STATUS_CUSTOMER_FAILURE:
    case vendorConstant.GET_USER_FOR_CHAT_FAILURE:
    case vendorConstant.GET_VENDOR_FOR_CHAT_FAILURE:
    case vendorConstant.CREATE_CHAT_CONNECTION_FAILURE:
    case vendorConstant.ADD_MESSAGE_FAILURE:
    case vendorConstant.GET_USER_INFO_FAILURE:
    case vendorConstant.GET_TOTAL_FAVOURITE_FAILURE:
    case vendorConstant.GET_TOTAL_LIKE_FAILURE:
    case vendorConstant.GET_TOTAL_VIEWS_FAILURE:
    case vendorConstant.GET_ALL_ADMIN_FAILURE:
    case vendorConstant.GET_ALL_CHATS_IDS_VENDOR_FAILURE:
      return {
        ...state,
        message: "",
        subscriptionLoading: false,
        loading: false,
        errors: action.payload.err,
      };

    case vendorConstant.GET_ALL_CUSTOMER_COMPLETED_REQUESTS_FAILURE:
      return {
        ...state,
        getCustomerCompletedRequestData: {
          ...state.getCustomerCompletedRequestData,
          loading: false,
          errors: action.payload.err,
        },
      };

    case vendorConstant.GET_ALL_CUSTOMER_CANCELLED_REQUESTS_FAILURE:
      return {
        ...state,
        getCustomerCancelledRequestData: {
          ...state.getCustomerCancelledRequestData,
          loading: false,
          errors: action.payload.err,
        },
      };

    case customerAuthConstant.CLEAR_MESSAGES:
      return {
        ...state,
        message: "",
        loading: false,
        errors: [],
      };
    case customerAuthConstant.CLEAR_ERRORS:
      return {
        ...state,
        message: "",
        loading: false,
        errors: [],
      };
    case vendorConstant.ADD_TO_EMAIL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Added to email list",
      };
    case vendorConstant.ADD_TO_EMAIL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errors: "Failed to add to email list",
      };
    case vendorConstant.REMOVE_FROM_EMAIL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Removed from email list",
      };
    case vendorConstant.REMOVE_FROM_EMAIL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errors: "Failed to remove from email list",
      };
    default:
      return state;
  }
};

export default vendorReducer;
