import React from "react";
import "./AboutUsPage.scss";
import { aboutfirst, aboutsecond } from "../../../assests";
import {
  Container,
  Grid,
  Heading,
  HowWorks,
  Review,
  Text,
  GoBackBtn,
} from "../../../components";

const AboutUsPage = () => {
  return (
    <>
      <div className="aboutus">
        <Container className="main">
          <Container className="lg">
            <GoBackBtn className="m-4" />
          </Container>
        </Container>
        <Container className="main">
          <Container className="md">
            <Text>
              Welcome to OccasionBase, your ultimate online marketplace
              dedicated to transforming the way events are planned and
              experienced. At OccasionBase, we believe that finding the perfect
              vendors for any occasion should be simple, accessible, and
              enjoyable.
            </Text>
            <div className="m-3" />
            <Heading className="primary">Our Mission</Heading>
            <Text>
              Our mission is to connect event organizers with a diverse network
              of service providers, making it easy to plan events that are
              memorable and seamlessly organized. Whether you're planning a
              wedding, corporate event, birthday party, or any other special
              celebration, OccasionBase brings together all the resources you
              need in one user-friendly platform.
            </Text>
            <div className="m-3" />
            <Heading className="primary">Our Vision</Heading>
            <Text>
              We envision a world where anyone planning an event can find their
              ideal vendors without the hassle and stress that typically comes
              with event planning. Our goal is to empower both event organizers
              and vendors by providing a platform that promotes discoverability,
              efficiency, and excellence in service.
            </Text>
            <div className="m-3" />
            <Heading className="primary">What We Offer</Heading>
            <ul>
              <li>
                <Text>
                  <strong>A Comprehensive Directory:</strong> Browse through an
                  extensive list of verified vendors, from caterers and
                  photographers to florists and entertainers. Each listing
                  includes detailed information, reviews, and ratings to help
                  you make informed decisions.
                </Text>
              </li>
              <li>
                <Text>
                  <strong>Personalized Experience:</strong> Our platform offers
                  personalized recommendations based on your event type,
                  location, and preferences, ensuring you find services that
                  truly match your needs.
                </Text>
              </li>
              <li>
                <Text>
                  <strong>Direct Communication:</strong> Connect directly with
                  vendors through our platform, facilitating easier
                  communication and coordination.
                </Text>
              </li>
              <li>
                <Text>
                  <strong>Free Subscription:</strong> We offer a free lifetime
                  subscription to vendors, which includes free marketing tools
                  to help them reach a broader audience and grow their business.
                </Text>
              </li>
            </ul>
            <div className="m-3" />
            <Heading className="primary">Join Our Community </Heading>
            <Text>
              Whether you are an event organizer looking for the perfect setup
              or a service provider aiming to expand your reach, OccasionBase is
              here to support you every step of the way. Join our community
              today and start planning or promoting with ease.
            </Text>
            <div className="m-3" />
            <Heading className="primary">Our Commitment </Heading>
            <Text>
              At OccasionBase, we are committed to continuous improvement and
              innovation. We listen to our users and evolve our services to meet
              their changing needs, ensuring a top-notch experience for everyone
              involved.
            </Text>
            <div className="m-3" />
            <Text>
              Discover the ease and excitement of event planning with
              OccasionBase, the base of operations for all occasions.
            </Text>
            <div className="m-3" />
            <Heading className="primary">Contact Us </Heading>
            <Text>
              If you have any questions, suggestions, or feedback, please don't
              hesitate to reach out to us. We are here to help you make your
              events spectacular.
            </Text>
            <Text className="uppercase">
              <strong>Office:</strong> OCCASION BASE LIMITED, 124 City Road,
              London, EC1V2NX
            </Text>
            <Text className="uppercase">
              <strong>Email:</strong> info@occasionbase.com
            </Text>
            <div className="m-3" />
            <Text>
              Thank you for choosing OccasionBase – the base of operations for
              all occasions.
            </Text>
          </Container>
        </Container>
        <div className="m-3">
          <Review />
        </div>
      </div>
    </>
  );
};

export default AboutUsPage;

// Sub Components
const Stats = (props) => {
  return (
    <>
      <div className="stats center m-4">
        <h1>{props.number}</h1>
        <h4>{props.content}</h4>
      </div>
    </>
  );
};
