import React from "react";

import { Link } from "react-router-dom";
import { Container, Text, Heading } from "../../../components";
const PrivacyPolicy = () => {
  return (
    <>
      <Container className="main">
        <Container className="md">
          <div className="list-view">
            <Heading className="primary center m-5">Privacy policy</Heading>
            <Text>
              At OccasionBase, we value your privacy and are committed to
              protecting your personal information. This privacy policy outlines
              how we collect, use, and protect your personal data when you use
              our website, located at
              <Link to="/">https://www.occasionbase.com.</Link>
            </Text>
            <div className="m-1" />
            <ol>
              <li>
                <Text>
                  Personal Information We Collect We collect personal
                  information that you voluntarily provide to us, such as your
                  name, email address, phone number, and billing information. We
                  may also collect information about your use of our website,
                  such as your IP address, browser type, and operating system.
                </Text>
              </li>

              <li>
                <Text>
                  How We Use Your Information We use your personal information
                  to provide you with our services, process your payments,
                  communicate with you about your account, and improve our
                  website. We may also use your information to send you
                  promotional emails or newsletters about our services.
                </Text>
              </li>
              <li>
                <Text>
                  Sharing Your Information We may share your personal
                  information with third-party service providers who help us
                  operate our website or provide our services. We may also share
                  your information with government authorities or law
                  enforcement agencies when required by law or to protect our
                  legal rights.
                </Text>
              </li>
              <li>
                <Text>
                  Cookies and Tracking Technologies We use cookies and other
                  tracking technologies to collect information about your use of
                  our website. Cookies are small data files that are placed on
                  your device when you visit a website. We use cookies to
                  remember your preferences and improve your user experience. We
                  may also use cookies for advertising purposes.
                </Text>
              </li>
              <li>
                <Text>
                  Your Choices You have the right to opt-out of receiving
                  promotional emails from us by clicking the "unsubscribe" link
                  in the email. You can also disable cookies in your web browser
                  settings. However, please note that disabling cookies may
                  affect your user experience on our website.
                </Text>
              </li>
              <li>
                <Text>
                  Security We take reasonable measures to protect your personal
                  information from unauthorized access, use, or disclosure.
                  However, no method of transmission over the internet or
                  electronic storage is 100% secure, and we cannot guarantee
                  absolute security.
                </Text>
              </li>
              <li>
                <Text>
                  Children's Privacy Our website is not intended for children
                  under the age of 13, and we do not knowingly collect personal
                  information from children under 13. If we discover that we
                  have collected personal information from a child under 13, we
                  will promptly delete that information.
                </Text>
              </li>
              <li>
                <Text>
                  Changes to this Policy We may update this privacy policy from
                  time to time. If we make significant changes, we will notify
                  you by email or by posting a notice on our website.
                </Text>
              </li>
              <li>
                <Text>
                  Contact Us If you have any questions or concerns about this
                  privacy policy, please contact us at{" "}
                  <Link to="mailto:info@occasionbase.com">
                    info@occasionbase.com
                  </Link>
                </Text>
              </li>
            </ol>
          </div>

          <div className="m-5"></div>
          <div className="m-5"></div>
        </Container>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
