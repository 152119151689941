import { vendorAuthConstant } from "./../constants";
import { customerOrVendorRegister, customerOrVendorLogin } from "./../../http";
import axios from "axios";
import { BACKEND_URL } from "../../constants";
import { emitUserStateChange } from "../../utils";
import toast from "react-hot-toast";

export const VendorRegister = (user) => {
  return async (dispatch) => {
    dispatch({ type: vendorAuthConstant.VENDOR_REGISTER_REQUEST });
    try {
      await customerOrVendorRegister(user);
      dispatch({
        type: vendorAuthConstant.VENDOR_REGISTER_SUCCESS,
        payload: "Created",
      });
      emitUserStateChange();
    } catch (error) {
      dispatch({
        type: vendorAuthConstant.VENDOR_REGISTER_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const VendorLogins = (user, onSuccess) => {
  return async (dispatch) => {
    dispatch({ type: vendorAuthConstant.VENDOR_LOGIN_REQUEST });
    try {
      const result = await customerOrVendorLogin(user);
      const { data } = result;
      dispatch({
        type: vendorAuthConstant.VENDOR_LOGIN_SUCCESS,
        payload: "",
      });
      if (data) {
        sessionStorage.setItem("vendorToken", data.tokens.access.token);
        sessionStorage.setItem("vendorRefreshToken", data.tokens.refresh.token);
      }
      emitUserStateChange();
      onSuccess && onSuccess();

      toast.success("Login Successfully");
    } catch (error) {
      dispatch({
        type: vendorAuthConstant.VENDOR_LOGIN_FAILURE,
        payload: { err: "" },
      });

      error.response.data.errors[0].message &&
        toast.error(error.response.data.errors[0].message);
    }
  };
};

export const Forgotpassword = (body) => {
  return async (dispatch) => {
    dispatch({ type: vendorAuthConstant.FORGOT_PASSWORD_REQUEST });
    try {
      const token =
        sessionStorage.getItem("userToken") ??
        sessionStorage.getItem("vendorToken");
      await axios.post(`${BACKEND_URL}/v1/api/auth/forgot/password`, body, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });

      dispatch({
        type: vendorAuthConstant.FORGOT_PASSWORD_SUCCESS,
        payload: "Kindly check your mail for OTP",
      });
      emitUserStateChange();
    } catch (error) {
      dispatch({
        type: vendorAuthConstant.FORGOT_PASSWORD_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const Resetpassword = (body) => {
  return async (dispatch) => {
    dispatch({ type: vendorAuthConstant.RESET_PASSWORD_REQUEST });
    try {
      const token =
        sessionStorage.getItem("userToken") ??
        sessionStorage.getItem("vendorToken");
      await axios.post(`${BACKEND_URL}/v1/api/auth/reset/password`, body, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      dispatch({
        type: vendorAuthConstant.RESET_PASSWORD_SUCCESS,
        payload: "Password has been reset",
      });
      emitUserStateChange();
    } catch (error) {
      dispatch({
        type: vendorAuthConstant.RESET_PASSWORD_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const getLoggedInVendor = () => {
  return async (dispatch) => {
    dispatch({ type: vendorAuthConstant.GET_LOGGED_IN_USER_REQUEST });
    try {
      const token =
        sessionStorage.getItem("userToken") ??
        sessionStorage.getItem("vendorToken");
      const { data } = await axios.get(
        `${BACKEND_URL}/v1/api/auth/current/user`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({
        type: vendorAuthConstant.GET_LOGGED_IN_USER_SUCCESS,
        payload: data,
      });
      emitUserStateChange();
    } catch (error) {
      dispatch({
        type: vendorAuthConstant.GET_LOGGED_IN_USER_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};
