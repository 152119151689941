// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.linkbtn > * {
  background-color: #ffc841;
  color: black;
  padding: 1rem 3rem;
  cursor: pointer;
  border: none;
  transition: 0.3s ease-in-out;
  font-size: 1.5rem;
  font-weight: 700;
  border-radius: 1rem; }
  .linkbtn > *:hover {
    background-color: #cda814; }
`, "",{"version":3,"sources":["webpack://./src/components/linkBtn/LinkBtn.scss","webpack://./src/scss/_variable.scss"],"names":[],"mappings":"AAIA;EAEI,yBCNa;EDOb,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,4BAA4B;EAC5B,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB,EAAA;EAVvB;IAYM,yBCfiB,EAAA","sourcesContent":["@import \"../../scss/variable\";\n\n// New code\n\n.linkbtn {\n  & > * {\n    background-color: $primary;\n    color: black;\n    padding: 1rem 3rem;\n    cursor: pointer;\n    border: none;\n    transition: 0.3s ease-in-out;\n    font-size: 1.5rem;\n    font-weight: 700;\n    border-radius: 1rem;\n    &:hover {\n      background-color: $primary-hover;\n    }\n  }\n}\n","$primary: #ffc841;\n$primary-hover: #cda814;\n\n$light-gray: #f2f1f1;\n$gray: #838282;\n\n$white: #ffffff;\n$sidebar-background: #212121;\n$footer_background: black;\n\n// Typo Graphy\n$heading-primary: #4c4d4e;\n\n$black: black;\n\n$dorger: #e0fff9;\n\n$bronze: #603b30;\n$sliver: #c5c5c5;\n$gold: #c57934;\n$platinium: #2d2d2d;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
