import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Routes,
} from "react-router-dom";
import { CustomerPanelLayout, UserLayout, VenderLayout } from "./layout";
import {
  AboutUsPage,
  Faq,
  HomePage,
  SearchPage,
  SupportPage,
  ViewCategory,
  ViewServices,
  EventDetail,
  VenderShop,
  CustomerLogin,
  CustomerSignUp,
  ChangePassword,
  ForgotPassword,
  ResetPassword,
  CookiePolicy,
  PrivacyPolicy,
  TcForCustomer,
  TcForSupplier,
  SearchResult,
} from "./module/customer";
import { Toaster } from "react-hot-toast";
import {
  CustomerMessage,
  CustomerSettings,
  Favourite,
  MyAccount,
  EditProfile,
  AdminCustomerMessage,
  CustomerDashboard,
} from "./module/customer/dashboard";
import { VenderLogin, VenderSignUp } from "./module/vender";
import {
  CreateService,
  DashBoard,
  MyServices,
  VenderEditProfile,
  VenderProfile,
  VendorSettings,
  ServiceDetail,
  EditService,
  CustomerRequest,
  ManageMembership,
  Payments,
  AddCard,
  FindOutMembership,
  VerificationForm,
  UpgradePlain,
  VendorMessage,
  AdminMessage,
} from "./module/vender/dashboard";
import {
  AccessDenied,
  ProTectedRouteForVendor,
  ProtectLoginRouteForVendor,
  NotFound,
  ProTectedRouteForCustomer,
  ProtectLoginRouteForCustomer,
} from "./components";
import ScrollToTop from "./ScrollToTop";
import { LoadScript } from "@react-google-maps/api";
import { OccasionProvider } from "./contexts/OccasionContext";
import { ServiceProvider } from "./contexts/ServicesContext";
import CheckoutPage from "./module/vender/dashboard/checkoutPage/CheckoutPage";
import Chat from "./module/vender/dashboard/Chat/Chat";
import { useUserChange } from "./hooks";
import MyOrders from "./module/customer/MyOrders/MyOrders";
import EditCustomerRequest from "./module/customer/EditCustomerRequest/EditCustomerRequest";
import useUser from "./hooks/useUser";

const Routess = () => {
  const user = useUser();

  return (
    <>
      <ServiceProvider>
        <OccasionProvider>
          <Toaster
            limit={1}
            toastOptions={{
              style: {
                fontSize: "18px",
              },
            }}
            containerStyle={{ zIndex: 14000 }}
          />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/forgot-password/:id" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/change-password" element={<ChangePassword />} />
            {/* Customer Login And Sign Up */}
            <Route element={<ProtectLoginRouteForCustomer />}>
              <Route path="/customersignin" element={<CustomerLogin />} />
              <Route path="/customersignup" element={<CustomerSignUp />} />
            </Route>
            {/* Vender Login And Sign Up */}
            <Route element={<ProtectLoginRouteForVendor />}>
              <Route path="/vender-signin" element={<VenderLogin />} />
              <Route path="/vender-signup" element={<VenderSignUp />} />
            </Route>
            {/* user layout */}
            <Route path="" element={<UserLayout />}>
              <Route path="/support" element={<SupportPage />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/aboutus" element={<AboutUsPage />} />
              <Route
                path="/search/:id?/:heading?/:city?"
                element={<SearchPage />}
              />
              <Route path="/search-results" element={<SearchResult />} />

              <Route path="/viewcategory" element={<ViewCategory />} />
              <Route path="/viewservices" element={<ViewServices />} />
              <Route path="/event-detail/:id?" element={<EventDetail />} />
              <Route path="/vender-shop/:vendorId" element={<VenderShop />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/term-for-customer" element={<TcForCustomer />} />
              <Route path="/term-for-supplier" element={<TcForSupplier />} />
            </Route>
            {/* Customer DashBoard Routes */}
            <Route element={<ProTectedRouteForCustomer />}>
              <Route path="" element={<CustomerPanelLayout />}>
                <Route
                  path="/customer-message/:id?"
                  element={<CustomerMessage />}
                />
                <Route
                  path="/admin-customer-message"
                  element={<AdminCustomerMessage />}
                />
                <Route path="/favourite" element={<Favourite />} />
                <Route path="/customer-profile" element={<MyAccount />} />
                <Route
                  path="/customer-dashboard"
                  element={<CustomerDashboard />}
                />

                <Route
                  path="/customer-settings"
                  element={<CustomerSettings />}
                />
                <Route
                  path="/customer-edit-profile"
                  element={<EditProfile />}
                />
                <Route path="/my-orders" element={<MyOrders />} />
                <Route
                  path="/requests/edit/:requestId"
                  element={<EditCustomerRequest />}
                />
                {user?.role === "User" && (
                  <Route path="/chat" element={<Chat />} />
                )}
              </Route>
            </Route>
            {/* Vender Routes */}
            <Route element={<ProTectedRouteForVendor />}>
              <Route path="" element={<VenderLayout />}>
                <Route path="/vender-dashboard" element={<DashBoard />} />
                <Route path="/vender-settings" element={<VendorSettings />} />
                <Route path="/vender-profile" element={<VenderProfile />} />
                <Route
                  path="/vender-verfication"
                  element={<VerificationForm />}
                />
                <Route
                  path="/vender-edit-profile"
                  element={<VenderEditProfile />}
                />
                <Route path="/create-service" element={<CreateService />} />
                <Route path="/my-services" element={<MyServices />} />
                <Route path="/service-detail/:id" element={<ServiceDetail />} />
                <Route path="/edit-service/:id" element={<EditService />} />
                <Route path="/customer-request" element={<CustomerRequest />} />
                <Route
                  path="/manage-membership"
                  element={<ManageMembership />}
                />
                <Route path="/checkout" element={<CheckoutPage />} />
                <Route path="/vender-message" element={<VendorMessage />} />
                <Route
                  path="/vender-admin-message"
                  element={<AdminMessage />}
                />
                <Route path="/vender-payments" element={<Payments />} />
                {/* Old */}
                <Route path="/upgrade-plain" element={<UpgradePlain />} />
                <Route
                  path="/findout-membership"
                  element={<FindOutMembership />}
                />

                {user?.role === "Vendor" && (
                  <Route path="/chat" element={<Chat />} />
                )}
              </Route>
            </Route>

            <Route
              path="/not-found"
              element={<h1 className="text-white">Not found</h1>}
            />
            <Route path="/access-denied" element={<AccessDenied />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </OccasionProvider>
      </ServiceProvider>
    </>
  );
};
export default Routess;
