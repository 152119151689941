import React from "react";
import "./Card.scss";

import { Link } from "react-router-dom";

import { BsEye, BsSuitHeartFill } from "react-icons/bs";
import { verified } from "../../assests";
import styled from "@emotion/styled";
const card = (props) => {
  return (
    <>
      <Link to={`/event-detail/${props.id}`} title={props.name}>
        <div className="card">
          <div className="card-container">
            <div className="card-container-image">
              <img src={props.image} alt="card" />
            </div>
            <div className="card-container-data">
              <div className="card-container-data-name align-item-center">
                <h3>
                  <span>{props.name}</span>{" "}
                  {props.verified && <img src={verified} alt="verify" />}
                </h3>
              </div>
              <p>{props.description}</p>
              <StyledLikeRow className="card-container-data-views align-item-center">
                <span>
                  <BsSuitHeartFill />
                  <span>{props.likes || 0}</span>
                </span>
                {/* <span>
                  <BsEye />
                  <span>{props.views || 0}</span>
                </span> */}
              </StyledLikeRow>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default card;

const StyledLikeRow = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  align-items: center;
  gap: 0.8rem;

  & > span {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    align-items: center;
    gap: 0.4rem;
  }
`;
