import styled from "@emotion/styled";
import { Menu, Modal } from "@mui/material";

export const ProfileDropDownStyled = styled(Menu)`
  & > .MuiPaper-root {
    border-radius: 1.5rem;
    margin-top: 1rem;
    & > ul {
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 1.5rem;
    }
  }
`;

export const NavbarModalStyled = styled(Modal)`
  z-index: 15000;

  & > .MuiBackdrop-root {
    backdrop-filter: blur(0.4rem);
  }
`;

export const MobileNavbarStyled = styled.nav`
  &,
  & * {
    box-sizing: border-box;
  }
  position: absolute;
  top: 10rem;
  left: 50%;
  transform: translate(-50%, 0%);
  max-height: calc(100vh - 20rem);
  max-height: calc(100svh - 20rem);
  overflow: auto;
  max-width: 50rem;
  width: 95vw;
  background-color: #f2f1f1;
  border-radius: 1.6rem;

  & > .close {
    display: grid;
    place-items: center;

    position: absolute;
    top: 2rem;
    right: 2rem;
    font-size: 1.6rem;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    will-change: opacity;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 3rem 0;
  position: relative;

  & input {
    padding: 0.8rem 4rem 0.8rem 1.6rem;
    background-color: white;
    border-radius: 1000rem;
    border: none;
    outline: none;
    box-shadow: 0px 4px 4px 0px #00000040;
    width: 100%;
  }

  & > button {
    background-color: transparent;
    border: none;
    place-items: center;
    display: grid;
    cursor: pointer;
    font-size: 1.6rem;
    position: absolute;
    top: 50%;
    right: 1.6rem;
    transform: translate(0, -50%);

    &:hover {
      opacity: 0.5;
    }
  }
`;
