import React, { useEffect, useMemo, useState } from "react";
import "./HomePage.scss";
import {
  Container,
  Heading,
  ProductSlider,
  Text,
  CategoryCard,
  Videos,
  HowWorks,
  Review,
  LinkBtn,
  HeroSection,
} from "../../../components";
// import { eventData } from "./../../../data/eventData";
import { servicesData } from "./../../../data/serviceData";
import { Link } from "react-router-dom";
import { Footer, Navbar } from "../../../components/common";
import { BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Autocomplete from "../../../components/Autocomplete/Autocomplete";
import { BACKEND_STATIC_URL } from "../../../constants";
import AutoCompleteNew from "../../../components/AutoCompleteNew/AutoCompleteNew";
import styled from "@emotion/styled";
import { FaSearch } from "react-icons/fa";
import { useFormik } from "formik";
import { homePageServiceSearch } from "../../../schema";
import { objToStr } from "../../../utils";
import useUser from "../../../hooks/useUser";

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 0.5rem;
  padding-right: 5rem;

  & input {
    padding: 0.8rem 1.6rem;
    background-color: white;
    border-radius: 1000rem;
    border: none;
    outline: none;
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  & > button {
    background-color: transparent;
    border: none;
    place-items: center;
    display: grid;
    cursor: pointer;
    font-size: 1.6rem;

    &:hover {
      opacity: 0.5;
    }
  }
`;

const HomePage = () => {
  const navigate = useNavigate();
  const { landingPageInfo } = useSelector((s) => s.publicReducer);
  const { servicesTypes } = useSelector((s) => s.servicesTypesReducer);
  const { getAllTopOccasionsData } = useSelector((s) => s.occasion2Reducer);
  const { occasions } = useSelector((s) => s.occasionReducer);
  const loggedInUser = useUser();

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      service: null,
    },
    validationSchema: homePageServiceSearch,

    onSubmit: (values) => {
      navigate(
        `/search-results?${objToStr({ service: values?.service?._id })}`
      );
    },
  });

  const fixedServicesTypesDataStructure = useMemo(() => {
    switch (servicesTypes.length) {
      case 1:
        return Array(3).fill(servicesTypes[0]);
      case 2:
        return [
          servicesTypes[0],
          servicesTypes[0],
          servicesTypes[1],
          servicesTypes[1],
        ];

      default:
        return servicesTypes;
    }
  }, [servicesTypes]);

  const transformedOccassions = useMemo(() => {
    const o =
      landingPageInfo?.howOccasionShowOnHomePage === "Selected"
        ? getAllTopOccasionsData?.results?.map(({ occasion }) => occasion)
        : occasions;

    switch (o.length) {
      case 1:
        return [o[0], o[0], o[0]];

      case 2:
        return [o[0], o[1], o[0]];

      default:
        return o;
    }
  }, [
    getAllTopOccasionsData?.results,
    landingPageInfo?.howOccasionShowOnHomePage,
    occasions,
  ]);

  return (
    <>
      <div className="homepage">
        <Navbar>
          <nav className="navbar-container-menus">
            <StyledForm onSubmit={handleSubmit}>
              <AutoCompleteNew
                placeholder="Search"
                options={servicesTypes}
                keyName="serviceName"
                onChange={(v) => setFieldValue("service", v)}
                value={values.service}
              />

              <button type="submit">
                <FaSearch />
              </button>
            </StyledForm>
            <div className="navbar-container-menus-item align-item-center">
              <Link to="support">
                <p>Support</p>
              </Link>
              <Link to="/aboutus">
                <p>About us</p>
              </Link>
              {(!loggedInUser || loggedInUser?.role === "Vendor") && (
                <Link to="/vender-signin">
                  <p>Vendor</p>
                </Link>
              )}

              {(!loggedInUser || loggedInUser?.role === "User") && (
                <Link to="/customersignin">
                  <p>Customer</p>
                </Link>
              )}
            </div>
          </nav>
        </Navbar>

        {/* Hero Section */}

        <HeroSection />

        {/* main page section */}

        <Container
          className="main"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* {occasions?.results?.length === 3 && ( */}
          <Container className="md" parentStyle={{ margin: "0 2rem" }}>
            <Heading className="primary uppercase">
              What’s your party occasion?
            </Heading>

            <Text>
              Whether you are planning a wedding, birthday party, corporate
              event, or any other type of gathering, occasion base provides an
              easy and convenient way to find and book the services you need.
              Just choose the occasion.
            </Text>

            <ProductSlider autoSpeed={1800}>
              {/* {occasions?.results?.map(({ occasion }, index) => { */}
              {transformedOccassions?.map((occasion, index) => {
                return (
                  <>
                    <Container className="slider">
                      <CategoryCard
                        key={index}
                        image={`${BACKEND_STATIC_URL}${occasion?.occasionImage}`}
                        name={occasion?.occasionName}
                        heading="occassion"
                        to={`/search-results?occassion=${occasion?._id}`}
                      />
                    </Container>
                  </>
                );
              })}
            </ProductSlider>

            <LinkBtn className="center uppercase m-5" to="/viewcategory">
              view all
            </LinkBtn>
          </Container>
          {/* // )} */}

          <Container className="md" parentStyle={{ margin: "0 2rem" }}>
            <Heading className="primary uppercase">
              Services and products
            </Heading>
            <Text>
              Let us help you create an unforgettable experience for your loved
              ones with our wide range of services and products. Click below to
              see what's available.
            </Text>

            <ProductSlider autoSpeed={1500}>
              {fixedServicesTypesDataStructure.map((data) => {
                return (
                  <>
                    <Container className="slider">
                      <CategoryCard
                        key={data.id}
                        image={BACKEND_STATIC_URL + data.image}
                        name={data.name}
                        to={`/search-results?service=${data._id}`}
                      />
                    </Container>
                  </>
                );
              })}
            </ProductSlider>
            <LinkBtn className="center  uppercase m-5" to="/viewservices">
              view all
            </LinkBtn>
          </Container>
        </Container>

        <div className="homepage-become-vender m-5">
          <Container className="main">
            <div className="homepage-become-vender-container m-5">
              <h1>Do You Provide Event Services?</h1>
              <p>
                Are you a vendor looking to expand your customer base and
                generate more leads? It's easy to become a vendor on
                OccasionBase and it's FREE!
              </p>
              <div className="homepage-become-vender-container-button">
                <Link to="/vender-signup">Become a vendor</Link>
              </div>
            </div>
          </Container>
        </div>

        <Container className="main">
          <div className="m-5">
            <Heading className="primary center uppercase m-5">
              What is Occasion base?
            </Heading>
            <Container className="md">
              <Videos
                src={
                  landingPageInfo?.videoLink ??
                  "https://www.youtube.com/embed/koynCuJBdnw"
                }
                title="YouTube video player"
              />
            </Container>
          </div>
        </Container>
        {/* how it works section */}
        <HowWorks />
        {/* Review Section */}
        <Review />
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
