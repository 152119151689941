import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { Container, Heading } from "../../../../components";
import {
  correct_silver,
  correct_gold,
  correct_bronze,
  correct_platinum,
} from "../../../../assests";
import { LineChart } from "@mui/x-charts/LineChart";

import {
  getTotalViews,
  getTotalFavourite,
  clearErrors,
  getTotalLike,
  getLoggedInVendor,
} from "./../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { BottomStyled } from "./styles";
import { CenteredLoader } from "../Chat/Chat";
const Dashboard = () => {
  const [days, setDays] = useState("--");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { totalViews, totalLikes, errors, loading } = useSelector(
    (state) => state.vendorReducer
  );
  const [subscriptionImage, setsubscriptionImage] = useState(null);
  const { getUserDetails2Data } = useSelector((s) => s.userReducer);
  useEffect(() => {
    if (getUserDetails2Data?.data) {
      switch (getUserDetails2Data?.data?.subscription?.plan?.product?.name) {
        case "Gold":
          setsubscriptionImage(correct_gold);
          break;
        case "Silver":
          setsubscriptionImage(correct_silver);

          break;
        case "Bronze":
          setsubscriptionImage(correct_bronze);

          break;
        case "Platinum":
          setsubscriptionImage(correct_platinum);

          break;
        default:
          setsubscriptionImage(null);

          break;
      }
    }
    if (getUserDetails2Data?.data?.subscription) {
      const expirationDate = new Date(
        getUserDetails2Data?.data?.subscription?.current_period_end * 1000
      );

      const currentDate = new Date();
      const remainingDays = Math.ceil(
        (expirationDate - currentDate) / (1000 * 60 * 60 * 24)
      );

      setDays(`${remainingDays} d`);
    }
  }, [getUserDetails2Data?.data]);

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
  }, [errors, dispatch]);

  useEffect(() => {
    dispatch(getTotalViews());
    dispatch(getTotalLike());
    dispatch(getLoggedInVendor());
  }, [dispatch]);

  return getUserDetails2Data?.loading ? (
    <CenteredLoader />
  ) : (
    <>
      <div className="m-5 dashboard-main-container-responsive">
        <Container className="md">
          <Heading className="lighter m-3">
            Welcome Back {getUserDetails2Data?.data?.fullName || "--"}
            {getUserDetails2Data?.data?.userId
              ? ` (${getUserDetails2Data?.data?.userId})`
              : ""}
            ,
          </Heading>
          <div className="dashboard-cards">
            <DashBoardCard
              heading="Likes"
              description="Likes you have on your services"
            >
              <h1>{totalLikes}</h1>
            </DashBoardCard>
            <DashBoardCard
              heading="Page Views"
              description="Views on your pages"
            >
              <h1>{totalViews?.totalViews}</h1>
            </DashBoardCard>
            <DashBoardCard
              heading=" Membership"
              description={
                getUserDetails2Data?.data?.subscription?.plan?.product?.name ||
                "--"
              }
              onClick={() => navigate("/manage-membership")}
            >
              {getUserDetails2Data?.data?.subscription?.plan?.product?.name ? (
                <img src={subscriptionImage} alt="correct" />
              ) : (
                <h2>Get Vendor's Membership</h2>
              )}
            </DashBoardCard>
            <DashBoardCard
              heading={
                getUserDetails2Data?.data?.subscription?.cancel_at_period_end
                  ? "Expires in"
                  : "Membership renewal"
              }
            >
              <h1>{days}</h1>
            </DashBoardCard>

            <BottomStyled>
              {loading ? (
                <CenteredLoader />
              ) : !totalViews?.dailyViews ? (
                <p>No Date Found!</p>
              ) : (
                <div className="graph-container">
                  <h5>Traffic</h5>
                  <hr />
                  <div className="container-line-chart">
                    <div className="dash-chart">
                      <LineChart
                        xAxis={[
                          {
                            scaleType: "band",
                            data: Object.keys(totalViews?.dailyViews).map(
                              (str) => new Date(str).toLocaleDateString()
                            ),
                          },
                        ]}
                        series={[
                          {
                            curve: "linear",
                            data: Object.values(totalViews?.dailyViews),

                            area: true,
                            color: "#FFC841",
                          },
                        ]}
                        // height={250}
                        sx={{
                          "& .MuiAreaElement-root": {
                            fill: "#ffc941b3",
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </BottomStyled>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;

const DashBoardCard = (props) => {
  return (
    <>
      <div className="dashboardcard" onClick={props.onClick}>
        <div className="dashboardcard-container">
          <h5>{props.heading}</h5>
          <div className="dashboardcard-container-item">{props.children}</div>
          <p>{props.description}</p>
        </div>
      </div>
    </>
  );
};
