// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.changepassword {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }
  .changepassword-container {
    text-align: center;
    width: 280px; }
    .changepassword-container > img {
      width: 50%; }
`, "",{"version":3,"sources":["webpack://./src/module/customer/changepassword/ChangePassword.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB,EAAA;EACvB;IACE,kBAAkB;IAClB,YAAY,EAAA;IAFb;MAIG,UAAU,EAAA","sourcesContent":[".changepassword {\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  &-container {\n    text-align: center;\n    width: 280px;\n    & > img {\n      width: 50%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
