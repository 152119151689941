import { profile } from "../assests";

export const dummy__messages = [
  {
    id: "1",
    message: "how are you ?",
    type: "reciver",
  },
  {
    id: "2",
    message: "hello world",
  },
  {
    id: "3",
    message: "hello world",
  },
  {
    id: "4",
    message: "hello world",
  },
  {
    id: "5",
    message: "hello world",
  },
  {
    id: "6",
    message: "hi",
    type: "reciver",
  },
  {
    id: "7",
    message:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,",
  },
];

export const userData = [
  {
    id: 1,
    img: profile,
    name: "Robert Hill",
    message: "Thank you contacting us....",
    newmsg: 4,
  },
  {
    id: 2,
    img: profile,
    name: "Aden Smith",
    message: "Thank you contacting us....",
    newmsg: 1,
  },
  {
    id: 3,
    img: profile,
    name: "Eric Hayes",
    message: "Thank you contacting us....",
    newmsg: 5,
  },
  {
    id: 4,
    img: profile,
    name: "Mark Clay",
    message: "Thank you contacting us....",
    newmsg: 9,
  },
];
