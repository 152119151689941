import styled from "@emotion/styled";
import { useCallback, useRef, useState } from "react";
import { useMemo } from "react";

const AutoCompleteNewStyled = styled.div`
  position: relative;

  & > .overlay-af23.show {
    transform: scaleY(1);
    opacity: 1;
  }

  & > .overlay-af23 {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 0.5rem);
    background-color: white;
    box-shadow: 0px 4px 4px 0px #00000040;
    z-index: 10;
    border-radius: 0.8rem;
    max-height: 25rem;
    overflow-y: auto;
    transform-origin: center 0;
    transform: scaleY(0);
    opacity: 0;
    transition: all 0.3s;

    display: grid;

    & > button {
      width: 100%;
      box-sizing: border-box;
      padding: 0.8rem 1.2rem;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 1.4rem;
      text-align: left;
      background-color: transparent;

      &:hover {
        background-color: #f7f6f6;
      }
    }
  }
`;

const AutoCompleteNew = ({
  options,
  keyName,
  placeholder,
  onChange,
  className,
  value,
}) => {
  if (!keyName) {
    throw new Error("Key name prop is required");
  }

  const [inputVal, setInputVal] = useState(value ? value[keyName] : "");
  const [show, setShow] = useState(false);
  const isSelectedThisTime = useRef(false);
  const remainingOptions = useMemo(() => {
    return options?.filter(
      (o) =>
        o &&
        o[keyName] &&
        o[keyName]?.toLowerCase().startsWith(inputVal?.toLowerCase())
    );
  }, [options, keyName, inputVal]);

  const onFocus = useCallback(() => {
    setShow(true);
  }, []);
  const onBlur = useCallback(() => {
    setTimeout(() => {
      setShow(false);
    }, 200);

    setTimeout(() => {
      if (!isSelectedThisTime.current) {
        onChange && onChange(null);
        setInputVal("");
      }
      isSelectedThisTime.current = false;
    }, 500);
  }, [onChange]);

  const onClick = useCallback(
    (index) => {
      isSelectedThisTime.current = true;
      const selectedOption = remainingOptions[Number(index)];
      setInputVal(selectedOption[keyName]);
      onChange && onChange(selectedOption);
    },
    [keyName, onChange, remainingOptions]
  );

  return (
    <AutoCompleteNewStyled>
      <input
        type="text"
        placeholder={placeholder}
        value={inputVal}
        onChange={(e) => setInputVal(e.target.value)}
        className={className || ""}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      <div className={`overlay-af23 ${show ? "show" : ""}`}>
        {remainingOptions?.map((o, i) => (
          <button onClick={() => onClick(i)} key={i}>
            {o[keyName]}
          </button>
        ))}
      </div>
    </AutoCompleteNewStyled>
  );
};

export default AutoCompleteNew;
