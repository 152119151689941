import React, { useEffect, useMemo, useState } from "react";
import { useQueryParams } from "../../../hooks";
import { Puff } from "react-loader-spinner";
import { Button, Container, Heading } from "../../../components";
import MyPagination from "../../../components/common/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerOrders } from "../../../store/actions";
import { useNavigate } from "react-router-dom";
import useCallOnDependencyChange from "../../../hooks/useCallOnDependencyChange";
import {
  NOTIFICATION_TYPES,
  updateNotificationSeen,
} from "../../../firebase/notfication";
import { getChatOfAvailableChats } from "../../../utils/getChat";
import useUser from "../../../hooks/useUser";
import { ActionStyledCell } from "./styles";
import { Tooltip } from "../../../components/common";

const MyOrders = () => {
  const { getCustomerOrdersData } = useSelector((s) => s.customerReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allNotifications } = useSelector((s) => s.notificationReducer);
  const user = useUser();

  useEffect(() => {
    dispatch(getCustomerOrders());
  }, [dispatch]);

  useEffect(() => {
    updateNotificationSeen(NOTIFICATION_TYPES.CUSTOMER_REQUEST);
  }, [allNotifications]);

  useCallOnDependencyChange(() => {
    if (
      allNotifications?.filter(
        (n) => n.notficationType === NOTIFICATION_TYPES.CUSTOMER_REQUEST
      ).length
    )
      dispatch(getCustomerOrders());
  }, [allNotifications]);

  return (
    <>
      {getCustomerOrdersData.loading ? (
        <Puff
          height="60"
          width="60"
          radius="6"
          color="black"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
        />
      ) : (
        <Container className="lg">
          <Heading className="center m-5">My enquiries</Heading>

          <div className="table-container">
            <div className="customer-request-table">
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Service name</th>
                    <th>Vendor</th>
                    <th>Phone #</th>
                    <th className="center">Booking Date</th>
                    <th>Message</th>
                    <th>Location</th>
                    <th>Status</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {getCustomerOrdersData?.data?.length > 0 ? (
                    getCustomerOrdersData?.data?.map((data, ind) => {
                      return (
                        <tr ke={ind}>
                          <td>{1 + ind}</td>
                          <td>
                            {data?.service?.customServiceName ||
                              data?.service?.serviceName?.serviceName ||
                              "--"}
                          </td>
                          <td>
                            {data?.vendor?.fullName || ""}
                            {data?.vendor?.userId
                              ? ` (${data?.vendor?.userId})`
                              : ""}
                          </td>
                          <td>{data?.phoneNumber || "Not Provided"}</td>
                          <td className="center">
                            {data?.date && new Date(data.date).toDateString()}
                          </td>
                          <td>{data?.message || ""}</td>
                          <td>{data?.location || ""}</td>
                          <td>{data?.status || ""}</td>
                          <ActionStyledCell>
                            <Button
                              onClick={() =>
                                navigate(
                                  `/chat?chatId=${
                                    getChatOfAvailableChats(
                                      data?.vendor?.id,
                                      user?.id
                                    )?._id
                                  }`
                                )
                              }
                              disabled={
                                !getChatOfAvailableChats(
                                  data?.vendor?.id,
                                  user?.id
                                )
                              }
                            >
                              Go to Chat
                            </Button>
                            <Tooltip
                              notShow={data?.status === "rejected"}
                              title="You cannot edit your enquiry until it is rejected by the Vendor."
                            >
                              <div>
                                <Button
                                  onClick={() =>
                                    navigate(`/requests/edit/${data.id}`)
                                  }
                                  disabled={data?.status !== "rejected"}
                                >
                                  Edit
                                </Button>
                              </div>
                            </Tooltip>
                            <Button
                              onClick={() =>
                                navigate(
                                  `/event-detail/${
                                    data?.service?._id || data?.service?.id
                                  }`
                                )
                              }
                              disabled={
                                !data?.service?.id && !data?.service?._id
                              }
                            >
                              View Service
                            </Button>
                          </ActionStyledCell>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>No record found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default MyOrders;
