import React from "react";
import { Link } from "react-router-dom";
import { Container, Text, Heading } from "../../../components";
const TcForSupplier = () => {
  return (
    <>
      <Container className="main">
        <Container className="md">
          <div className="list-view">
            <Heading className="primary center m-5">
              Terms and conditions for suppliers
            </Heading>
            <Text>
              These terms and conditions outline the rules and regulations for
              the use of OccasionBase’s website, located at
              https://www.occasionbase.com.
            </Text>
            <Text>
              By accessing this website, we assume you accept these terms and
              conditions in full. If you disagree with these terms and
              conditions or any part of these terms and conditions, you must not
              use this website.
            </Text>
            <div className="m-1" />
            <ol>
              <li>
                <Text>
                  Introduction: OccasionBase is an online marketing platform
                  that allows vendors to advertise their services and products.
                  Our website is designed to help customers organize events for
                  all occasions, from weddings to birthday parties, corporate
                  events to family reunions.
                </Text>
              </li>
              <li>
                <Text>
                  Intellectual Property Rights: Unless otherwise stated,
                  OccasionBase and/or its licensors own the intellectual
                  property rights in the website and material on the website.
                  Subject to the license below, all these intellectual property
                  rights are reserved.
                </Text>
              </li>
              <li>
                <Text>
                  License to Use Website: You may view, download for caching
                  purposes only, and print pages from the website for your own
                  personal use, subject to the restrictions set out below and
                  elsewhere in these terms and conditions.
                </Text>
              </li>
              <li>
                <Text>
                  LimitaƟons of Liability: OccasionBase will not be liable to
                  you (whether under the law of contact, the law of torts or
                  otherwise) in relation to the contents of, or use of, or
                  otherwise in connecƟon with, this website for any indirect,
                  special or consequential loss or damage, or for any business
                  losses, loss of revenue, income, profits or anticipated
                  savings, loss of contracts or business relationships, loss of
                  reputation or goodwill, or loss or corrupƟon of information or
                  data.
                </Text>
              </li>
              <li>
                <Text>
                  Occasion base has right to use vendors content on all their
                  social media platforms and occasion base will mention the
                  source of the video i.e. tagging vendor in the description.
                </Text>
              </li>
              <li>
                <Text>
                  Membership Fees: OccasionBase offers various membership
                  packages. By subscribing to our services, you agree to pay the
                  applicable membership fees. Payment can be made by credit card
                  or other payment methods specified by OccasionBase.
                </Text>
              </li>
              <li>
                <Text>
                  Termination of Membership: OccasionBase reserves the right to
                  terminate your membership at any time, without notice or
                  explanation, if you breach these terms and condiƟons. You may
                  also terminate your membership at any time by giving notice to
                  OccasionBase.
                </Text>
              </li>
              <li>
                <Text>
                  Payment and Refunds Membership fees are non-refundable.
                  OccasionBase does not guarantee any parƟcular level of success
                  or income from the use of our plaƞorm. You acknowledge and
                  agree that you are responsible for paying any applicable taxes
                  on your membership fees.
                </Text>
              </li>
              <li>
                <Text>
                  User Conduct You agree not to use the website for any unlawful
                  purpose or in any way that violates these terms and
                  conditions. You agree to use the website only for lawful
                  purposes and in accordance with all applicable laws and
                  regulations.
                </Text>
              </li>
              <li>
                <Text>
                  User Accounts You are responsible for maintaining the
                  confidentiality of your login details and any activity that
                  occurs under your account. You agree to noƟfy OccasionBase
                  immediately if you suspect any unauthorized access or use of
                  your account.
                </Text>
              </li>
              <li>
                <Text>
                  Third-Party Links OccasionBase may provide links to
                  third-party websites or resources for your convenience. We do
                  not endorse the content of these websites and are not
                  responsible for their content or availability. Accessing and
                  using these third-party websites is at your own risk.
                </Text>
              </li>

              <li>
                <Text>
                  Limitations of Liability OccasionBase will not be liable for
                  any damages arising from the use of our website, including but
                  not limited to direct, indirect, incidental, punitive, and
                  consequential damages.
                </Text>
              </li>
              <li>
                <Text>
                  Indemnification You agree to indemnify and hold OccasionBase,
                  its affiliates, officers, agents, and employees, harmless from
                  any claim or demand, including reasonable attorneys’ fees,
                  made by any third-party due to or arising out of your use of
                  our website or violation of these terms and conditions
                </Text>
              </li>
              <li>
                <Text>
                  Entire Agreement These terms and conditions constitute the
                  entire agreement between you and OccasionBase and supersede
                  any prior agreements or understandings, whether written or
                  oral, relaƟng to the use of our website.
                </Text>
              </li>
              <li>
                <Text>
                  Third-Party Links OccasionBase may provide links to
                  third-party websites or resources for your convenience. We do
                  not endorse the content of these websites and are not
                  responsible for their content or availability. Accessing and
                  using these third-party websites is at your own risk.
                </Text>
              </li>

              <li>
                <Text>
                  Contact Us: If you have any questions about these terms and
                  conditions, please contact us at{" "}
                  <Link to="mailto:info@occasionbase.com">
                    info@occasionbase.com.
                  </Link>
                </Text>
              </li>
            </ol>
          </div>

          <div className="m-5"></div>
          <div className="m-5"></div>
        </Container>
      </Container>
    </>
  );
};

export default TcForSupplier;
