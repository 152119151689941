import { customerAuthConstant, customerConstant } from "./../constants";

const initialState = {
  errors: [],
  message: "",
  loading: false,

  getCustomerInfoData: {
    loading: false,
    data: null,
  },

  getCustomerOrdersData: {
    loading: false,
    data: null,
  },

  getMessageRequestForCustomerData: {
    loading: false,
    data: null,
  },

  updateMessageRequestForCustomerData: {
    loading: false,
  },

  toggleAddedToMailListData: {
    loading: false,
  },
};

const customerAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case customerConstant.CHANGE_PASSWORD_REQUEST:
    case customerConstant.EDIT_PROFILE_REQUEST:
    case customerConstant.MESSAGE_TO_VENDOR_REQUEST:
    case customerConstant.POST_SUPPORT_REQUEST:
      return {
        ...state,
        loading: true,
        message: "",
        errors: [],
      };
    case customerConstant.CHANGE_PASSWORD_SUCCESS:
    case customerConstant.EDIT_PROFILE_SUCCESS:
    case customerConstant.MESSAGE_TO_VENDOR_SUCCESS:
    case customerConstant.POST_SUPPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case customerConstant.CHANGE_PASSWORD_FAILURE:
    case customerConstant.EDIT_PROFILE_FAILURE:
    case customerConstant.MESSAGE_TO_VENDOR_FAILURE:
    case customerConstant.POST_SUPPORT_FAILUREFAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload.err,
        message: "",
      };

    case customerConstant.GET_USER_INFO_REQUEST:
      return {
        ...state,
        getCustomerInfoData: { ...state.getCustomerInfoData, loading: true },
      };
    case customerConstant.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        getCustomerInfoData: {
          ...state.getCustomerInfoData,
          loading: false,
          data: action.payload,
        },
      };
    case customerConstant.GET_USER_INFO_FAILURE:
      return {
        ...state,
        getCustomerInfoData: { ...state.getCustomerInfoData, loading: false },
      };

    case customerConstant.GET_CUSTOMER_ORDERS_REQUEST:
      return {
        ...state,
        getCustomerOrdersData: {
          ...state.getCustomerOrdersData,
          loading: true,
        },
      };
    case customerConstant.GET_CUSTOMER_ORDERS_SUCCESS:
      return {
        ...state,
        getCustomerOrdersData: {
          ...state.getCustomerOrdersData,
          loading: false,
          data: action.payload,
        },
      };
    case customerConstant.GET_CUSTOMER_ORDERS_FAILURE:
      return {
        ...state,
        getCustomerOrdersData: {
          ...state.getCustomerOrdersData,
          loading: false,
        },
      };

    // ----------------
    case customerConstant.GET_MESSAGE_REQUEST_FOR_CUSTOMER_REQUEST:
      return {
        ...state,
        getMessageRequestForCustomerData: {
          ...state.getMessageRequestForCustomerData,
          loading: true,
        },
      };
    case customerConstant.GET_MESSAGE_REQUEST_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        getMessageRequestForCustomerData: {
          ...state.getMessageRequestForCustomerData,
          loading: false,
          data: action.payload,
        },
      };
    case customerConstant.GET_MESSAGE_REQUEST_FOR_CUSTOMER_FAILURE:
      return {
        ...state,
        getMessageRequestForCustomerData: {
          ...state.getMessageRequestForCustomerData,
          loading: false,
        },
      };
    // ----------------
    case customerConstant.UPDATE_MESSAGE_REQUEST_FOR_CUSTOMER_REQUEST:
      return {
        ...state,
        updateMessageRequestForCustomerData: {
          ...state.updateMessageRequestForCustomerData,
          loading: true,
        },
      };
    case customerConstant.UPDATE_MESSAGE_REQUEST_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        updateMessageRequestForCustomerData: {
          ...state.updateMessageRequestForCustomerData,
          loading: false,
        },
      };
    case customerConstant.UPDATE_MESSAGE_REQUEST_FOR_CUSTOMER_FAILURE:
      return {
        ...state,
        updateMessageRequestForCustomerData: {
          ...state.updateMessageRequestForCustomerData,
          loading: false,
        },
      };

    case customerConstant.ADD_CUSTOMER_TO_EMAIL_LIST_REQUEST:
      return {
        ...state,
        toggleAddedToMailListData: {
          ...state.toggleAddedToMailListData,
          loading: true,
        },
      };
    case customerConstant.ADD_CUSTOMER_TO_EMAIL_LIST_SUCCESS:
    case customerConstant.ADD_CUSTOMER_TO_EMAIL_LIST_FAILURE:
      return {
        ...state,
        toggleAddedToMailListData: {
          ...state.toggleAddedToMailListData,
          loading: false,
        },
      };

    // ----------------

    case customerAuthConstant.CLEAR_MESSAGES:
      return {
        ...state,
        loading: false,
        message: "",
        errors: [],
      };
    case customerAuthConstant.CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        errors: [],
        message: "",
      };
    default:
      return state;
  }
};

export default customerAuthReducer;
