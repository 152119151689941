// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider {
  border: 2px solid #ffc841;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }
`, "",{"version":3,"sources":["webpack://./src/components/divider/Divider.scss","webpack://./src/scss/_variable.scss"],"names":[],"mappings":"AAEA;EACE,yBCHe;EDIf,2CAA2C,EAAA","sourcesContent":["@import \"../../scss/variable\";\n\n.divider {\n  border: 2px solid $primary;\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n}\n","$primary: #ffc841;\n$primary-hover: #cda814;\n\n$light-gray: #f2f1f1;\n$gray: #838282;\n\n$white: #ffffff;\n$sidebar-background: #212121;\n$footer_background: black;\n\n// Typo Graphy\n$heading-primary: #4c4d4e;\n\n$black: black;\n\n$dorger: #e0fff9;\n\n$bronze: #603b30;\n$sliver: #c5c5c5;\n$gold: #c57934;\n$platinium: #2d2d2d;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
