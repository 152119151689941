import { occasion2Constants } from "./../constants";

const initialState = {
  getAllTopOccasionsData: {
    results: [],
    loading: false,
  },
};

const occasion2Reducer = (s = initialState, action) => {
  const state = { ...s };

  switch (action.type) {
    case occasion2Constants.GET_ALL_TOP_OCCASSIONS_2.pending:
      state.getAllTopOccasionsData.loading = true;
      break;

    case occasion2Constants.GET_ALL_TOP_OCCASSIONS_2.success:
      state.getAllTopOccasionsData = {
        loading: false,
        results: action.payload,
      };
      break;

    case occasion2Constants.GET_ALL_TOP_OCCASSIONS_2.error:
      state.getAllTopOccasionsData.loading = false;
      break;

    default:
      break;
  }

  return state;
};

export default occasion2Reducer;
