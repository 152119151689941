import { useState } from "react";
import "./Modal.scss";
const Modal = (props) => {
  const [display, setDisplay] = useState(true);

  return (
    <>
      {display ? (
        <div className="modal-overlay">
          <div className="modal-overlay-index">
            <div
              className="modal_container"
              onClick={() => setDisplay(!display)}
            >
              <div className="modal">{props.children}</div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Modal;
