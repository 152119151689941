import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Container,
  Grid,
  InfoItem,
  LinkBtn,
} from "../../../../components";
import "./ManageMembership.scss";
import "../checkoutPage/UpgradePlain.scss";
import { GoCheck } from "react-icons/go";
import { RxCross2 } from "react-icons/rx";
import toast from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import {
  GetMemberShipSubscription,
  AddMemberShipSubscription,
  clearMessages,
  clearErrors,
  cancelVendorMembership,
  getLoggedInVendor,
  reSubscribeVendorMembership,
  getCouponDetails,
  GetPaymentCard,
} from "./../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MemberShipCard, priorityTable } from "./MembershipCard";
import { MdFactCheck } from "react-icons/md";
import { BsCheckCircleFill, BsCheckLg } from "react-icons/bs";
import { IoMdCheckmark } from "react-icons/io";
import { Modal } from "@mui/material";
import { CenteredLoader } from "../Chat/Chat";
import { reGetUserDetails2 } from "../../../../store/actions/user.action";
import useUser from "../../../../hooks/useUser";
import { InputField } from "../checkoutPage/CheckoutPage";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import { couponFormSchema } from "../../../../schema";
import { getMonthsFromToday } from "../../../../utils";

const intialCouponDetails = {
  monthsOff: 0,
  percentageOff: 0,
  appliedCouponId: "",
};

export const subscriptionData = [
  {
    color: "brown",
    services: [
      "Ideal for Starters",
      "Perfect for small businesses and freelancers",
    ],
    name: "Bronze",
    description: "Basic",
  },
  {
    color: "silver",
    services: ["Grow Your Presence", "Suitable for growing service providers"],
    name: "Silver",
    description: "Standard",
  },
  {
    color: "gold",
    services: [
      "For Established Professionals",
      "Tailored for established event professionals",
    ],
    name: "Gold",
    description: "Advanced",
  },
  {
    color: "black",
    services: [
      "Limitless Advertising",
      "Expand your reach nationally",
      "Designed for large event companies",
    ],
    name: "Platinum",
    description: "Unlimited",
  },
];

const ManageMembership = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    getCardsDetails,
    details,
    errors,
    message,
    loading,
    loading2,
    reSubscribeVendorMembershipData,
  } = useSelector((state) => state.vendorReducer);
  const [cancellingSubscriptionPriceId, setCancellingSubscriptionPriceId] =
    useState("");
  const [
    upgradeDowngradeConfirmationModalData,
    setUpgradeDowngradeConfirmationModalData,
  ] = useState({
    open: false,
    priceId: "",
  });
  const { getUserDetails2Data } = useSelector((s) => s.userReducer);
  const [isResubscribeModalOpen, setIsResubscribeModalOpen] = useState(false);
  const [isAddCouponModalOpen, setIsAddCouponModalOpen] = useState(false);
  const [couponDetails, setCouponDetails] = useState(intialCouponDetails);
  const user = useUser();
  const isSubscribedPlanWillCancelling = useMemo(
    () => user?.subscription?.cancel_at_period_end,
    [user?.subscription?.cancel_at_period_end]
  );

  const { needToAddCard, getCardsDetailsLoading } = useMemo(
    () => ({
      getCardsDetailsLoading: getCardsDetails.loading,
      needToAddCard:
        getCardsDetails.data?.length === 0 && Boolean(user?.subscription),
    }),
    [getCardsDetails.data?.length, getCardsDetails.loading, user?.subscription]
  );

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message !== "") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/vender-dashboard"), 2000);
    }
  }, [errors, dispatch, message, navigate]);

  useEffect(() => {
    dispatch(GetMemberShipSubscription());
  }, [dispatch]);

  useEffect(() => {
    dispatch(GetPaymentCard());
  }, [dispatch]);

  return loading || getCardsDetailsLoading ? (
    <div className="m-5">
      <CenteredLoader />
    </div>
  ) : (
    <>
      <Container className="md">
        <div className="membership-top-section m-5">
          <InfoItem
            title="Memberships"
            content="Boost your event service business with OccasionBase's exclusive membership options! Subscribe now to connect with top event planners and clients. Enjoy premium benefits, special discounts, and increased visibility for your business. Enhance your offerings and make your services stand out with OccasionBase. Join our vendor community today!"
          />
        </div>

        <Grid className="grid-4 pb-5">
          {details?.map((data, i) => (
            <MemberShipCard
              key={i}
              className={`bg-${data?.name}`}
              name={data?.name}
              desc={`(${subscriptionData[i]?.description})`}
              price={data?.price}
              subscriptionId={data?._id}
              priceId={data?.priceId}
              onCancel={() => {
                setCancellingSubscriptionPriceId(data?.priceId);
              }}
              onUpgradeDowngrade={() => {
                if (needToAddCard && data?.price !== 0) {
                  toast.error(
                    "Please add a card before upgrading or downgrading"
                  );
                  navigate("/vender-settings#card-input");
                  return;
                }
                setUpgradeDowngradeConfirmationModalData({
                  open: true,
                  priceId: data?.priceId,
                });
              }}
              onReSubscribe={() => {
                if (needToAddCard && data?.price !== 0) {
                  toast.error("Please add a card before re-subscribing");
                  navigate("/vender-settings#card-input");
                  return;
                }
                setIsResubscribeModalOpen(true);
              }}
              coupon={data?.coupons && data?.coupons[0]}
              features={(() => {
                try {
                  return [
                    subscriptionData[i]?.services[0],
                    `Advertise up to ${data?.services || 0} service${
                      data?.services > 1 ? "s" : ""
                    }`,
                    ...subscriptionData[i]?.services.slice(1),
                  ];
                } catch (e) {
                  return [];
                }
              })()}
              color={subscriptionData[i]?.color}
            ></MemberShipCard>
          ))}
        </Grid>

        <CancelSubConfirmationModal
          open={Boolean(cancellingSubscriptionPriceId)}
          onClose={() => setCancellingSubscriptionPriceId("")}
          onConfirmation={() =>
            !loading2
              ? dispatch(
                  cancelVendorMembership({}, () => {
                    toast.success("Request Confirmed");
                    reGetUserDetails2({
                      onSuccess: () => {
                        setCancellingSubscriptionPriceId("");
                      },
                    });
                  })
                )
              : null
          }
          disabled={loading2 || getUserDetails2Data?.fetching}
        />

        <UpgradeDowngradeConfirmationModal
          open={upgradeDowngradeConfirmationModalData.open}
          onClose={() =>
            setUpgradeDowngradeConfirmationModalData({
              open: false,
              priceId: "",
            })
          }
          onConfirmation={() =>
            !loading2
              ? dispatch(
                  cancelVendorMembership(
                    {
                      wantUpdateOrDowngradeToAfterCancel:
                        upgradeDowngradeConfirmationModalData.priceId,
                      couponForWantUpdateOrDowngradeToAfterCancel:
                        couponDetails.appliedCouponId,
                    },
                    () => {
                      toast.success(
                        isSubscribedPlanWillCancelling
                          ? "Automatically subscription will subscribe after the current plan expire."
                          : "Request Confirmed  "
                      );
                      setCouponDetails(intialCouponDetails);
                      reGetUserDetails2({
                        onSuccess: () => {
                          setUpgradeDowngradeConfirmationModalData({
                            open: false,
                            priceId: "",
                          });
                        },
                      });
                    }
                  )
                )
              : null
          }
          disabled={loading2 || getUserDetails2Data?.fetching}
          priceId={upgradeDowngradeConfirmationModalData.priceId}
          onAddCoupon={() => {
            setIsAddCouponModalOpen(true);
          }}
          couponDetails={couponDetails}
        />

        <AddCouponModal
          open={isAddCouponModalOpen}
          onClose={() => setIsAddCouponModalOpen(false)}
          onConfirmation={(couponDetails) => {
            setCouponDetails(couponDetails);
            setIsAddCouponModalOpen(false);
          }}
          priceId={upgradeDowngradeConfirmationModalData.priceId}
        />

        <ReSubscribeConfirmationModal
          open={isResubscribeModalOpen}
          onClose={() => setIsResubscribeModalOpen(false)}
          onConfirmation={() =>
            !reSubscribeVendorMembershipData.loading
              ? dispatch(
                  reSubscribeVendorMembership(() => {
                    reGetUserDetails2({
                      onSuccess: () => {
                        setIsResubscribeModalOpen(false);
                      },
                    });
                  })
                )
              : null
          }
          disabled={
            reSubscribeVendorMembershipData?.loading ||
            getUserDetails2Data?.fetching
          }
        />
      </Container>
    </>
  );
};

export default ManageMembership;

const CancelSubConfirmationModal = ({
  open,
  onClose,
  onConfirmation,
  disabled,
}) => {
  return (
    <Modal
      open={open}
      onClose={!disabled && onClose}
      onConfirmation={onConfirmation}
      className="confirmation-modal-lksadjf239jf2"
    >
      <div className="confirmation-modal-239jv2">
        <div className="top">
          <h6>Confirm Cancellation</h6>
          <p>
            Upon canceling your subscription, please note that all services
            associated with your account will be archieved after the expiration
            of your current subscription. To prevent service interruption, we
            recommend purchasing a new subscription as your current subscription
            finished.
            <br />
            <br />
            Are you sure you want to proceed with canceling your subscription?
          </p>
        </div>
        <div className="bottom">
          <button
            type="submit"
            onClick={onConfirmation}
            className="button-9274982734987324afasdf"
            disabled={disabled}
          >
            {disabled ? "Please wait..." : "Yes"}
          </button>
          <button
            onClick={onClose}
            className="button-sdkjfkj2939j3f23"
            disabled={disabled}
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};

const UpgradeDowngradeConfirmationModal = ({
  open,
  onClose,
  onConfirmation,
  disabled,
  priceId,
  onAddCoupon,
  couponDetails,
}) => {
  const user = useUser();
  const isSubscribedPlanWillCancelling = useMemo(
    () => user?.subscription?.cancel_at_period_end,
    [user?.subscription?.cancel_at_period_end]
  );
  const { details } = useSelector((state) => state.vendorReducer);
  const previouslyWantedUpdatePlanTo = useMemo(
    () =>
      details.find(
        (d) => d.priceId === user?.wantUpdateOrDowngradeToAfterCancel
      ),
    [details, user?.wantUpdateOrDowngradeToAfterCancel]
  );
  const previouslyWantedUpdatePlanToPriority = useMemo(
    () => priorityTable[previouslyWantedUpdatePlanTo?.name] || 0,
    [previouslyWantedUpdatePlanTo?.name]
  );
  const nowWantedUpdatePlanTo = useMemo(
    () => details.find((d) => d.priceId === priceId),
    [details, priceId]
  );
  const nowWantedUpdatePlanToPriority = useMemo(
    () => priorityTable[nowWantedUpdatePlanTo?.name] || 0,
    [nowWantedUpdatePlanTo?.name]
  );
  const subscribedPlanPriority = useMemo(
    () => priorityTable[user?.subscription?.plan?.product?.name] || 0,
    [user?.subscription?.plan?.product?.name]
  );
  const isPrviouslyWantUpdating = useMemo(
    () => previouslyWantedUpdatePlanToPriority > subscribedPlanPriority,
    [subscribedPlanPriority, previouslyWantedUpdatePlanToPriority]
  );
  const isNowWantUpdating = useMemo(
    () => nowWantedUpdatePlanToPriority > subscribedPlanPriority,
    [subscribedPlanPriority, nowWantedUpdatePlanToPriority]
  );
  const currentSubscribedBillingCycleEndAt = useMemo(
    () => user?.subscription?.current_period_end,
    [user?.subscription?.current_period_end]
  );

  const PriceDetailLiComp = () => (
    <li>
      {(() => {
        const currentBillingCycleEndAtDate = new Date(
          currentSubscribedBillingCycleEndAt * 1000
        );
        const actualPrice = nowWantedUpdatePlanTo?.price;
        if (!couponDetails.appliedCouponId) {
          return `You will be charged £${actualPrice} per month starting from ${currentBillingCycleEndAtDate.toLocaleDateString()}.`;
        } else {
          const priceAfterOff = (
            actualPrice -
            (couponDetails.percentageOff / 100) * actualPrice
          ).toFixed(2);
          const dateAfterAddingCoupon = addMonths(
            currentBillingCycleEndAtDate,
            couponDetails.monthsOff
          );

          return `You will be charged £${priceAfterOff}(${
            couponDetails.percentageOff
          }% off) per month starting from ${currentBillingCycleEndAtDate.toLocaleDateString()} to ${dateAfterAddingCoupon.toLocaleDateString()}. After that you will charge £${actualPrice} per month.`;
        }
      })()}{" "}
      <StyledHaveCouponButton onClick={onAddCoupon}>
        {!couponDetails.appliedCouponId
          ? "Have Coupon?"
          : "Want to Change Coupon?"}
      </StyledHaveCouponButton>
    </li>
  );

  return (
    <>
      <Modal
        open={open}
        onClose={!disabled && onClose}
        onConfirmation={onConfirmation}
        className="confirmation-modal-lksadjf239jf2"
      >
        <div className="confirmation-modal-239jv2 hide-scrollbar">
          <div className="top">
            <h6>Confirmation Required</h6>
            {!user?.wantUpdateOrDowngradeToAfterCancel &&
              !isSubscribedPlanWillCancelling && (
                <>
                  <div>
                    <p className="text-left pb">Please note the following:</p>
                    <ol>
                      <li>
                        Your current subscription will continue until its
                        expiration date.
                      </li>
                      <li>
                        If your new subscription includes fewer services than
                        your current one, we will randomly archive services to
                        fit the new subscription limit. You can change these
                        archived services as needed later.
                      </li>
                      <PriceDetailLiComp />
                    </ol>
                  </div>
                  <p>Are you sure you want to change your current plan?</p>
                </>
              )}

            {!user?.wantUpdateOrDowngradeToAfterCancel &&
              isSubscribedPlanWillCancelling && (
                <>
                  <div>
                    <p className="text-left pb">Please note the following:</p>
                    <ol>
                      <li>
                        Your current subscription will continue until its
                        expiration date.
                      </li>
                      <li>
                        If your new subscription includes fewer services than
                        your current one, we will randomly archive services to
                        fit the new subscription limit. You can change these
                        archived services as needed later.
                      </li>
                      <PriceDetailLiComp />
                    </ol>
                  </div>
                  <p>Are you sure you want to change your current plan?</p>
                </>
              )}

            {user?.wantUpdateOrDowngradeToAfterCancel && (
              <>
                <p>
                  Recently, you decided to{" "}
                  {isPrviouslyWantUpdating ? "update" : "downgrade"} your
                  subscription to the{" "}
                  {previouslyWantedUpdatePlanTo?.name || "--"} plan. Now, you
                  want to {isNowWantUpdating ? "update" : "downgrade"} your
                  subscription to the {nowWantedUpdatePlanTo?.name || "--"}{" "}
                  plan.
                </p>

                <div>
                  <p className="text-left pb">Please note the following:</p>
                  <ol>
                    <li>
                      Your current subscription will continue until its
                      expiration date.
                    </li>
                    <li>
                      If your new subscription includes fewer services than your
                      current one, we will randomly archive services to fit the
                      new subscription limit. You can change these archived
                      services as needed later.
                    </li>
                    <PriceDetailLiComp />
                  </ol>
                </div>
                <p>
                  Are you sure you want to change your current plan to{" "}
                  {nowWantedUpdatePlanTo?.name || "--"}?
                </p>
              </>
            )}
          </div>
          <div className="bottom">
            <button
              type="submit"
              onClick={onConfirmation}
              className="button-9274982734987324afasdf"
              disabled={disabled}
            >
              {disabled ? "Please wait..." : "Yes"}
            </button>
            <button
              onClick={onClose}
              className="button-sdkjfkj2939j3f23"
              disabled={disabled}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const AddCouponModal = ({
  disabled,
  open,
  onConfirmation,
  onClose,
  priceId,
}) => {
  const dispatch = useDispatch();
  const { details, loading2 } = useSelector((state) => state.vendorReducer);
  const nowWantedUpdatePlanTo = useMemo(
    () => details.find((d) => d.priceId === priceId),
    [details, priceId]
  );
  const couponFormik = useFormik({
    initialValues: {
      couponId: "",
    },
    validationSchema: couponFormSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(
        getCouponDetails(
          values.couponId,
          (data) => {
            if (data?.productId === nowWantedUpdatePlanTo?._id) {
              onConfirmation({
                appliedCouponId: data?._id,
                percentageOff: data?.percent_off || 0,
                monthsOff: data?.duration_in_months || 0,
              });
              resetForm();
            } else {
              toast.error(
                `You can't use this promo code for ${
                  nowWantedUpdatePlanTo?.name || "--"
                } subscription.`
              );
            }
          },
          () => {}
        )
      );
    },
  });

  const _disabled = useMemo(() => disabled || loading2, [disabled, loading2]);
  return (
    <Modal
      open={open}
      onClose={!_disabled && onClose}
      onConfirmation={onConfirmation}
      className="confirmation-modal-lksadjf239jf2"
    >
      <form
        className="confirmation-modal-239jv2"
        onSubmit={couponFormik.handleSubmit}
      >
        <div className="top">
          <h6>Do you have coupon?</h6>
          <StyledAddCouponFieldContainer>
            <InputField
              label="Enter Coupon"
              error={couponFormik.errors.couponId}
              value={couponFormik.values.couponId}
              onChange={couponFormik.handleChange}
              onBlur={couponFormik.handleBlur}
              name="couponId"
            />
          </StyledAddCouponFieldContainer>
        </div>
        <div className="bottom">
          <button
            type="submit"
            className="button-9274982734987324afasdf"
            disabled={_disabled}
          >
            {_disabled ? "Please wait..." : "Apply"}
          </button>
          <button
            onClick={() => {
              couponFormik.resetForm();
              onConfirmation(intialCouponDetails);
            }}
            className="button-sdkjfkj2939j3f23"
            disabled={_disabled}
            type="button"
          >
            No
          </button>
        </div>
      </form>
    </Modal>
  );
};

const ReSubscribeConfirmationModal = ({
  open,
  onClose,
  onConfirmation,
  disabled,
}) => {
  const { details } = useSelector((state) => state.vendorReducer);
  const user = useUser();
  const toUpdatePlan = useMemo(
    () =>
      details.find(
        (d) => d.priceId === user?.wantUpdateOrDowngradeToAfterCancel
      ),
    [details, user?.wantUpdateOrDowngradeToAfterCancel]
  );
  const toUpdatePlanPriority = useMemo(
    () => priorityTable[toUpdatePlan?.name] || 0,
    [toUpdatePlan?.name]
  );
  const subscribedPlanPriority = useMemo(
    () => priorityTable[user?.subscription?.plan?.product?.name] || 0,
    [user?.subscription?.plan?.product?.name]
  );
  const isWantUpdating = useMemo(
    () => toUpdatePlanPriority > subscribedPlanPriority,
    [subscribedPlanPriority, toUpdatePlanPriority]
  );

  return (
    <Modal
      open={open}
      onClose={!disabled && onClose}
      onConfirmation={onConfirmation}
      className="confirmation-modal-lksadjf239jf2"
    >
      <div className="confirmation-modal-239jv2">
        <div className="top">
          <h6>Confirmation Required</h6>

          {user?.wantUpdateOrDowngradeToAfterCancel ? (
            <>
              <p>
                You recently requested an{" "}
                {isWantUpdating ? "update" : "downgrade"} to the{" "}
                {toUpdatePlan?.name || "--"} plan, scheduled to start at the end
                of your current subscription period.
              </p>
              <p>
                Are you sure you want to revert to your original plan instead?
              </p>
            </>
          ) : (
            <p>Are you sure you want to continue your current subscription?</p>
          )}
        </div>
        <div className="bottom">
          <button
            type="submit"
            onClick={onConfirmation}
            className="button-9274982734987324afasdf"
            disabled={disabled}
          >
            {disabled ? "Please wait..." : "Yes"}
          </button>
          <button
            onClick={onClose}
            className="button-sdkjfkj2939j3f23"
            disabled={disabled}
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};
const StyledAddCouponFieldContainer = styled.div`
  display: grid;
  width: 40rem;
  @media screen and (max-width: 500px) {
    width: 30rem;
  }
  & p.error {
    color: #d42020 !important;
    text-align: left !important;
  }
`;

function addMonths(date, months) {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + months);

  if (newDate.getDate() !== date.getDate()) {
    newDate.setDate(0);
  }

  return newDate;
}
const StyledHaveCouponButton = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  color: skyblue;
  text-decoration: underline;
  font-style: italic;
  transition: opacity 0.3s ease-in-out;
  will-change: opacity;
  &:hover {
    opacity: 0.6;
  }
`;
