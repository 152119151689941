// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upgrade > h3 {
  font-size: 1.6rem;
  font-weight: 500;
  margin-left: 2rem; }

.upgrade-container {
  border: 1px solid gray;
  margin-top: 3rem;
  padding: 3rem;
  border-radius: 3rem; }

.card-margins {
  margin: 10.5rem 0rem; }

.select-promocode-select {
  margin: 2rem 0rem; }

.select-promocode-select-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin-bottom: 3rem; }
  .select-promocode-select-heading > h5 {
    font-size: 1.6rem;
    font-weight: 400; }
  .select-promocode-select-heading > p {
    font-size: 1.3rem; }
`, "",{"version":3,"sources":["webpack://./src/module/vender/dashboard/upgragePlain/UpgradePlain.scss"],"names":[],"mappings":"AAEA;EAEI,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB,EAAA;;AAEnB;EACE,sBAAsB;EACtB,gBAAgB;EAChB,aAAa;EACb,mBAAmB,EAAA;;AAIvB;EACE,oBAAoB,EAAA;;AAGtB;EACE,iBAAiB,EAAA;;AAIjB;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,UAAU;EACV,mBAAmB,EAAA;EALpB;IAOG,iBAAiB;IACjB,gBAAgB,EAAA;EARnB;IAWG,iBAAiB,EAAA","sourcesContent":["// UpGrade Card\n\n.upgrade {\n  & > h3 {\n    font-size: 1.6rem;\n    font-weight: 500;\n    margin-left: 2rem;\n  }\n  &-container {\n    border: 1px solid gray;\n    margin-top: 3rem;\n    padding: 3rem;\n    border-radius: 3rem;\n  }\n}\n\n.card-margins {\n  margin: 10.5rem 0rem;\n}\n\n.select-promocode-select {\n  margin: 2rem 0rem;\n}\n\n.select-promocode-select {\n  &-heading {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 95%;\n    margin-bottom: 3rem;\n    & > h5 {\n      font-size: 1.6rem;\n      font-weight: 400;\n    }\n    & > p {\n      font-size: 1.3rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
