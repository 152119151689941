import React, { useEffect, useMemo } from "react";
import { Card, Container, Grid, Heading } from "../../../../components";
import { cardImage, cardImage1, cardImage2 } from "../../../../assests";
import { BiSearch } from "react-icons/bi";
import toast from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import { GetMyFavortServices, clearErrors } from "./../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { BACKEND_STATIC_URL } from "../../../../constants";
import { useQueryParams } from "../../../../hooks";
import MyPagination from "../../../../components/common/Pagination/Pagination";
import { FavouriteStyled } from "./styles";

const Favourite = () => {
  const dispatch = useDispatch();
  const { details, errors, loading } = useSelector(
    (state) => state.vendorReducer
  );
  const { query, page, setQueryParam } = useQueryParams({
    query: "",
    page: 1,
  });

  const filteredSearch = useMemo(() => {
    return details?.results?.filter(
      (s) =>
        s.serviceName.serviceName.toLowerCase().search(query?.toLowerCase()) !==
        -1
    );
  }, [details, query]);

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
  }, [errors]);

  useEffect(() => {
    dispatch(GetMyFavortServices(page));
  }, [dispatch, page]);
  return (
    <>
      <div className="m-5">
        <Container className="lg">
          <Heading className="center m-5">My Favourites</Heading>
          <div className="align-item-right" style={{ marginBottom: "5rem" }}>
            <div className="searchbar">
              <input
                type="text"
                placeholder="Search"
                onChange={(e) =>
                  setQueryParam({ query: e.target.value }, false, true)
                }
              />
              <div className="searchbar-icon">
                <BiSearch />
              </div>
            </div>
          </div>
          <FavouriteStyled>
            {loading ? (
              <Puff
                height="60"
                width="60"
                radius="6"
                color="black"
                ariaLabel="loading"
                wrapperStyle
                wrapperClass
              />
            ) : details?.results?.length > 0 ? (
              filteredSearch?.length === 0 ? (
                <h1>Try something else.</h1>
              ) : (
                filteredSearch.map((data, ind) => {
                  return (
                    <Card
                      key={ind}
                      image={
                        data?.thumbnails &&
                        data?.thumbnails[0] &&
                        `${BACKEND_STATIC_URL}${data?.thumbnails[0]}`
                      }
                      location={data.address || "--"}
                      name={data?.serviceName?.serviceName || "--"}
                      description={
                        data?.description || "No description available"
                      }
                      likes={data?.like?.length || 0}
                      views={data?.views?.length || 0}
                      id={data?.id}
                    />
                  );
                })
              )
            ) : (
              <h1>Favourite service is empty yet</h1>
            )}
          </FavouriteStyled>
        </Container>
        <MyPagination totalPages={details?.totalPages} />
      </div>
    </>
  );
};

export default Favourite;
