class OpertionalError extends Error {
  constructor(message, isOperational = true) {
    super(message);
    this.name = this.constructor.name;
    this.stack = new Error().stack;
    this.isOperational = isOperational;
  }
}

export default OpertionalError;
