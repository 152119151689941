import React, { useEffect, useMemo } from "react";
import { ViewPageOutlet } from "../../../components/common";
import { CategoryCard } from "../../../components";
// import { eventData } from "./../../../data/eventData";
import { BiSearch } from "react-icons/bi";
import { useSelector } from "react-redux";
import { BACKEND_STATIC_URL } from "../../../constants";
import { useQueryParams } from "../../../hooks";
import styled from "@emotion/styled";
const ViewCategory = () => {
  const { occasions: eventData } = useSelector((s) => s.occasionReducer);
  const { query } = useQueryParams({ query: "" });

  const filteredOccasions = useMemo(() => {
    return (
      eventData?.filter(
        (o) => o.occasionName.toLowerCase().search(query?.toLowerCase()) !== -1
      ) || []
    );
  }, [eventData, query]);

  return (
    <>
      <div className="viewpage">
        <ViewPageOutlet
          className="venderviewall"
          title="Pick a celebration"
          summery="Whether you're celebrating a special milestone or planning a momentous occasion, occasion base has got you covered! From birthdays and anniversaries to graduations and weddings, we offer services for all of life's big moments."
        >
          {filteredOccasions.map((data, index) => {
            return (
              <>
                <CategoryCard
                  key={index}
                  image={`${BACKEND_STATIC_URL}${data.image}`}
                  name={data.occasionName}
                  heading="occassion"
                  to={`/search-results?occassion=${data?._id}`}
                />
              </>
            );
          })}

          {filteredOccasions.length === 0 && (
            <StyledError>No result found. Try something else.</StyledError>
          )}
        </ViewPageOutlet>
      </div>
      <div className="mt-3" />
    </>
  );
};

export default ViewCategory;

// Sub Components for Card

export const StyledError = styled.p`
  font-size: 2.4rem;
  font-weight: 700;
`;
