import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import PropTypes from "prop-types";

function ProtectedRouteForVendor() {
  const isLoggedIn = sessionStorage.getItem("vendorToken");
  return isLoggedIn ? <Navigate to="/vender-dashboard" replace /> : <Outlet />;
}

ProtectedRouteForVendor.propTypes = {
  allowedRole: PropTypes.string,
};

export default ProtectedRouteForVendor;
