import React from "react";
import "./Container.scss";

const Container = (props) => {
  return (
    <>
      <div className={`container-${props.className}`} style={props.parentStyle}>
        <div className="container-content" style={props.style}>{props.children}</div>
      </div>
    </>
  );
};

export default Container;
