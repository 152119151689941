import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetSingleService,
  getMessageRequestForCustomer,
  updateMessageRequestForCustomer,
} from "../../../store/actions";
import { Puff } from "react-loader-spinner";
import { Container, FormInput, FormText, Heading } from "../../../components";
import FormSelect from "../../../components/FormInput/FormSelect";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  NOTIFICATION_TYPES,
  sendNotfication,
} from "../../../firebase/notfication";
import useUser from "../../../hooks/useUser";
import { GoogleLocationInput } from "../eventDetail/EventDetail";

const validation = Yup.object({
  date: Yup.date()
    .min(new Date(Date.now()), "Date must be in future.")
    .required("Date is required"),
  location: Yup.string().required("Location is required"),
  phone: Yup.number().optional(),
  message: Yup.string()
    .required("Message is required")
    .min(2, "Must be 5 character")
    .max(200, "Must be 200 characters or less"),
});

const formatDate = (d) => {
  const date = new Date(d);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const EditCustomerRequest = () => {
  const { requestId } = useParams();

  const { singleService } = useSelector((state) => state.vendorReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useUser();

  const {
    updateMessageRequestForCustomerData,
    getMessageRequestForCustomerData,
  } = useSelector((s) => s.customerReducer);

  return (
    <>
      (
      <Container className="lg">
        <Heading className="center m-5">Edit Request</Heading>

        <div>
          <div className="request-form">
            <div className="request-form-content">
              <Formik
                initialValues={{
                  date: getMessageRequestForCustomerData?.data?.date
                    ? formatDate(getMessageRequestForCustomerData?.data?.date)
                    : "",
                  location:
                    getMessageRequestForCustomerData?.data?.location || "",
                  phone:
                    getMessageRequestForCustomerData?.data?.phoneNumber || "",
                  message:
                    getMessageRequestForCustomerData?.data?.message || "",
                }}
                validateOnMount
                validationSchema={validation}
                onSubmit={(values, { resetForm }) => {
                  const { date, location, phone, message } = values;
                  let finalResult = {
                    date,
                    location,
                    phoneNumber: phone,
                    message,
                  };
                  dispatch(
                    updateMessageRequestForCustomer(
                      { _id: requestId, body: finalResult },
                      () => {
                        sendNotfication({
                          message: "New customer request has been received!",
                          notficationType: NOTIFICATION_TYPES.CUSTOMER_REQUEST,
                          productId: requestId,
                          receiverId: singleService?.vendor?.id,
                          senderId: user?.id,
                        });

                        navigate("/my-orders", { replace: true });
                        resetForm({ values: "" });
                      }
                    )
                  );
                }}
              >
                {(formik) => (
                  <Form>
                    <FormInput
                      label="Date"
                      name="date"
                      type="date"
                      className="bg-white"
                    />
                    {singleService?.isProvidingServiceAtAllLoc ? (
                      <GoogleLocationInput formik={formik} />
                    ) : (
                      <FormSelect
                        label="Location"
                        name="location"
                        type="text"
                        className="bg-white"
                      >
                        <option disabled value="">
                          Select Location
                        </option>
                        {singleService?.serviceArea &&
                          singleService.serviceArea?.map((name) => (
                            <option value={name}>{name.slice(0, -4)}</option>
                          ))}
                      </FormSelect>
                    )}
                    <FormInput
                      label="Phone number (optional)"
                      name="phone"
                      type="number"
                      className="bg-white"
                    />
                    <FormText
                      label="Message Box"
                      text="text"
                      name="message"
                      cols="10"
                      rows="10"
                      className="bg-white"
                    />

                    <div className="request-btn m-5">
                      <button
                        type="submit"
                        disabled={
                          updateMessageRequestForCustomerData.loading ||
                          !formik.dirty
                        }
                      >
                        {updateMessageRequestForCustomerData.loading
                          ? "Please wait..."
                          : "Resubmit Request"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
      )
    </>
  );
};

const WithLoader = () => {
  const { requestId } = useParams();
  const dispatch = useDispatch();
  const { getMessageRequestForCustomerData } = useSelector(
    (s) => s.customerReducer
  );
  const { loading } = useSelector((state) => state.vendorReducer);

  useEffect(() => {
    dispatch(
      getMessageRequestForCustomer(
        { _id: requestId },
        ({ service: { id } }) => {
          dispatch(GetSingleService(id));
        }
      )
    );
  }, [requestId, dispatch]);

  return (
    <>
      {getMessageRequestForCustomerData.loading || loading ? (
        <Puff
          height="60"
          width="60"
          radius="6"
          color="black"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
        />
      ) : (
        <EditCustomerRequest />
      )}
    </>
  );
};

export default WithLoader;
