import axios from "axios";
import { asyncCatch, generateRandomId } from "../../utils";
import { BACKEND_URL } from "../../constants";
import { fileUploadConstants } from "../constants";
import toast from "react-hot-toast";
import OpertionalError from "../../utils/OpertionalError";

const postFile = async (file) => {
  const formdata = new FormData();
  formdata.append("file", file);

  const toastId = generateRandomId();

  try {
    const {
      data: { files },
    } = await axios.post(`${BACKEND_URL}/v1/api/upload`, formdata, {
      onUploadProgress: (e) => {
        const percentCompleted = Math.round((e.loaded * 100) / e.total);

        toast.loading(`${percentCompleted}% - File Uploading...`, {
          id: toastId,
        });
      },
    });

    // toast.dismiss(toastId);
    toast.success("Successfully File Uploaded!", { id: toastId });
    return files[0];
  } catch (error) {
    toast.error("Failed File Uploading!", { id: toastId });

    throw error;
  }
};

export const postUploadFile = asyncCatch(async ({ payload: { files } }) => {
  for (const file of files) {
    if (file?.size < 10485760) continue;
    throw new OpertionalError(
      "File size too large. Maximum upload size is 10mb."
    );
  }
  return await Promise.all(files?.map((f) => postFile(f)));
}, fileUploadConstants.POST_FILE_UPLOAD);
