import toast from "react-hot-toast";
import globalHookData from "./globalHookData";
import actionCreator from "./actionCreator";

const asyncCatch =
  (cb = async ({ payload, config }) => null, actions) =>
  async ({
    payload = {},
    defaultErrorHandler = true,
    onError = () => {},
    onSuccess = () => {},
  } = {}) => {
    globalHookData.useDispatch(actionCreator(actions.pending));

    try {
      let config = {
        headers: {
          Authorization: `Bearer ${
            sessionStorage.getItem("userToken") ||
            sessionStorage.getItem("vendorToken") ||
            ""
          }`,
        },
      };

      const response = await cb({ payload, config });
      console.log(`Success : ${actions.success} => `, response);
      onSuccess && onSuccess(response);
      globalHookData.useDispatch(actionCreator(actions.success, response));
    } catch (error) {
      console.log(`Error : ${actions.error} => `, error);

      let errorMessage = "";
      if (error.message === "Network Error" && !navigator.onLine) {
        errorMessage = "Check your internet connection!";
      } else if (error.message === "Network Error") {
        errorMessage = "Something went wrong. Please try again later!";
      } else if (error?.response?.status === 401) {
        errorMessage = "User not authenticated!";
        sessionStorage.clear();
        globalHookData.useNavigate("/");
      } else if (typeof error?.response?.data?.message === "string") {
        errorMessage = error?.response?.data?.message;
      } else if (typeof error?.response?.data?.error === "string") {
        errorMessage = error?.response?.data?.message;
      } else if (typeof error?.response?.data === "string") {
        errorMessage = error?.response?.data;
      } else if (Array.isArray(error?.response?.data?.errors)) {
        errorMessage = error?.response?.data?.errors[0]?.message;
      } else if (error.isOperational) {
        errorMessage =
          error?.message || "Something went wrong. Please try again later!";
      } else {
        errorMessage = "Something went wrong. Please try again later!";
      }

      onError && onError(error);
      defaultErrorHandler && toast.error(errorMessage);
      globalHookData.useDispatch(actionCreator(actions.error));
    }
  };

export default asyncCatch;
