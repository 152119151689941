// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myaccount-container-image > img {
  height: 143px;
  width: 143px;
  border-radius: 50%; }
`, "",{"version":3,"sources":["webpack://./src/module/customer/dashboard/myaccount/MyAccount.scss"],"names":[],"mappings":"AAEK;EAEG,aAAa;EACb,YAAY;EACZ,kBAAkB,EAAA","sourcesContent":[".myaccount {\n  &-container {\n    &-image {\n      & > img {\n        height: 143px;\n        width: 143px;\n        border-radius: 50%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
