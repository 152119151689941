// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-profile-image > img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #ffc841; }

.edit-profile-image > *:last-child {
  margin-left: -40px;
  background-color: #ffc841;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer; }
  .edit-profile-image > *:last-child:hover {
    background-color: #cda814; }
`, "",{"version":3,"sources":["webpack://./src/module/customer/dashboard/editprofile/EditProfile.scss","webpack://./src/scss/_variable.scss"],"names":[],"mappings":"AAGG;EAEG,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,yBCTW,EAAA;;ADGd;EAUK,kBAAkB;EAClB,yBCdS;EDeT,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,eAAe,EAAA;EAhBpB;IAkBO,yBCpBa,EAAA","sourcesContent":["@import \"../../../../scss/variable\";\n\n.edit-profile {\n  &-image {\n    & > img {\n      height: 150px;\n      width: 150px;\n      border-radius: 50%;\n      object-fit: cover;\n      border: 3px solid $primary;\n    }\n    & > * {\n      &:last-child {\n        margin-left: -40px;\n        background-color: $primary;\n        height: 25px;\n        width: 25px;\n        border-radius: 50%;\n        padding: 0.5rem;\n        cursor: pointer;\n        &:hover {\n          background-color: $primary-hover;\n        }\n      }\n    }\n  }\n}\n","$primary: #ffc841;\n$primary-hover: #cda814;\n\n$light-gray: #f2f1f1;\n$gray: #838282;\n\n$white: #ffffff;\n$sidebar-background: #212121;\n$footer_background: black;\n\n// Typo Graphy\n$heading-primary: #4c4d4e;\n\n$black: black;\n\n$dorger: #e0fff9;\n\n$bronze: #603b30;\n$sliver: #c5c5c5;\n$gold: #c57934;\n$platinium: #2d2d2d;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
