import { userContants } from "./../constants";

const initialState = {
  getUserDetails2Data: {
    fetching: false,
    loading: false,
    data: null,
  },
};

const vendorServices2Reducer = (state = initialState, action) => {
  const s = { ...state };

  switch (action.type) {
    case userContants.GET_USER_DETAILS_2.pending:
      s.getUserDetails2Data.loading = true;
      s.getUserDetails2Data.fetching = true;
      break;
    case userContants.GET_USER_DETAILS_2.success:
      s.getUserDetails2Data.loading = false;
      s.getUserDetails2Data.fetching = false;
      s.getUserDetails2Data.data = action.payload;
      break;
    case userContants.GET_USER_DETAILS_2.error:
      s.getUserDetails2Data.loading = false;
      s.getUserDetails2Data.fetching = false;
      break;

    case userContants.RE_GET_USER_DETAILS_2.pending:
      s.getUserDetails2Data.fetching = true;
      break;
    case userContants.RE_GET_USER_DETAILS_2.success:
      s.getUserDetails2Data.fetching = false;
      s.getUserDetails2Data.data = action.payload;
      break;
    case userContants.RE_GET_USER_DETAILS_2.error:
      s.getUserDetails2Data.fetching = false;
      break;
    default:
      break;
  }

  return s;
};

export default vendorServices2Reducer;
