import styled from "@emotion/styled";
import React from "react";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";

const StyledComp = styled.div`
  display: grid;
  & > iframe {
    width: 100%;
    aspect-ratio: 1920/1080;
    margin: 0 auto;
    max-width: 100rem;
  }
`;

const Videos = (props) => {
  return props.src?.search("www.youtube.com") !== -1 ? (
    <StyledComp>
      {/* don't show related videos in the end of youtube video end */}
      <iframe
        src={`${props.src}?rel=0`}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen="1"
      ></iframe>
    </StyledComp>
  ) : props.src?.search("drive.google.com") !== -1 ? (
    <StyledComp>
      <iframe title="Google Drive player" src={props.src}></iframe>
    </StyledComp>
  ) : (
    <>
      <Video loop className="video-js">
        <source src={props.src} type="video/webm" />
      </Video>
    </>
  );
};

export default Videos;
