import React from "react";
import "./FindOutMembership.scss";
import { Container, GoBackBtn } from "../../../../components";

const FindOutMembership = () => {
  return (
    <>
      <Container className="lg">
        <GoBackBtn />
      </Container>
      <Container className="md">
        <div className="findmore m-5">
          <div className="table-container">
            <div className="findmore-table m-2">
              <table>
                <thead>
                  <tr>
                    <th>Bronze</th>
                    <th>Silver</th>
                    <th>Gold</th>
                    <th>Platinium</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                  </tr>
                  <tr>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                  </tr>{" "}
                  <tr>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                  </tr>{" "}
                  <tr>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                  </tr>{" "}
                  <tr>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                  </tr>{" "}
                  <tr>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                  </tr>
                  <tr>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                  </tr>
                  <tr>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                  </tr>
                  <tr>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                  </tr>{" "}
                  <tr>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                    <td>£8.99</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default FindOutMembership;
