// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.venderCard-container-image > img {
  height: 26rem;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  width: 100%;
  object-fit: cover; }

.venderCard-container-data {
  background-color: #f2f1f1;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 1.5rem 2rem;
  margin-top: -8px;
  font-size: 1.3rem;
  color: black; }
`, "",{"version":3,"sources":["webpack://./src/components/categoryCard/CategoryCard.scss","webpack://./src/scss/_variable.scss"],"names":[],"mappings":"AAGK;EAEG,aAAa;EACb,4BAA4B;EAC5B,6BAA6B;EAC7B,WAAW;EACX,iBAAiB,EAAA;;AAGrB;EACE,yBCVc;EDWd,+BAA+B;EAC/B,gCAAgC;EAChC,oBAAoB;EACpB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY,EAAA","sourcesContent":["@import \"../../scss/variable\";\n.venderCard {\n  &-container {\n    &-image {\n      & > img {\n        height: 26rem;\n        border-top-left-radius: 30px;\n        border-top-right-radius: 30px;\n        width: 100%;\n        object-fit: cover;\n      }\n    }\n    &-data {\n      background-color: $light-gray;\n      border-bottom-left-radius: 30px;\n      border-bottom-right-radius: 30px;\n      padding: 1.5rem 2rem;\n      margin-top: -8px;\n      font-size: 1.3rem;\n      color: black;\n    }\n  }\n}\n","$primary: #ffc841;\n$primary-hover: #cda814;\n\n$light-gray: #f2f1f1;\n$gray: #838282;\n\n$white: #ffffff;\n$sidebar-background: #212121;\n$footer_background: black;\n\n// Typo Graphy\n$heading-primary: #4c4d4e;\n\n$black: black;\n\n$dorger: #e0fff9;\n\n$bronze: #603b30;\n$sliver: #c5c5c5;\n$gold: #c57934;\n$platinium: #2d2d2d;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
