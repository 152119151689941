import styled from "@emotion/styled";

export const MyOrdersStyled = styled.div`
  &,
  & * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
  }

  padding: 5rem !important;
`;

export const ActionStyledCell = styled.td`
  min-width: 23rem;
`;
