import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { objToStr, removeEmpty, strToObj } from "../utils";

const useQueryParams = (obj = {}) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const queryParams = useMemo(() => {
    const queryParams = strToObj(search);
    for (const key in obj) {
      if (queryParams[key] === undefined) {
        queryParams[key] = obj[key];
      }
    }

    return queryParams;
  }, [obj, search]);

  const setQueryParam = useCallback(
    (
      obj = {},
      removePrevious = false,
      replace = false,
      returnString = false
    ) => {
      const newURL = `${pathname}?${objToStr(
        removeEmpty({
          ...(removePrevious ? {} : strToObj(search)),
          ...obj,
        })
      )}`;

      if (returnString) return newURL;

      navigate(newURL, { replace });
    },
    [navigate, pathname, search]
  );

  return { setQueryParam, ...queryParams };
};

export default useQueryParams;
