export const customerAuthConstant = {
  CUSTOMER_REGISTER_REQUEST: "CUSTOMER_REGISTER_REQUEST",
  CUSTOMER_REGISTER_SUCCESS: "CUSTOMER_REGISTER_SUCCESS",
  CUSTOMER_REGISTER_FAILURE: "CUSTOMER_REGISTER_FAILURE",
  CUSTOMER_LOGIN_REQUEST: "CUSTOMER_LOGIN_REQUEST",
  CUSTOMER_LOGIN_SUCCESS: "CUSTOMER_LOGIN_SUCCESS",
  CUSTOMER_LOGIN_FAILURE: "CUSTOMER_LOGIN_FAILURE",
  CUSTOMER_LOGOUT_REQUEST: "CUSTOMER_LOGOUT_REQUEST",
  CUSTOMER_LOGOUT_SUCCESS: "CUSTOMER_LOGOUT_SUCCESS",
  CUSTOMER_LOGOUT_FAILURE: "CUSTOMER_LOGOUT_FAILURE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
  CLEAR_MESSAGES: "CLEAR_MESSAGES",
};
