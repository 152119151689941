import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { LoginLayout } from "../../../layout";
import { customerlogin } from "../../../assests";
import { Button, FormInput, GoBackBtn } from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import { Puff } from "react-loader-spinner";
import toast from "react-hot-toast";
import {
  customerLogins,
  clearErrors,
  clearMessages,
} from "./../../../store/actions";
import { useQueryParams } from "../../../hooks";
import { reGetUserDetails2 } from "../../../store/actions/user.action";
const CustomerLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.customerAuthReducer);
  const { getUserDetails2Data } = useSelector((s) => s.userReducer);
  const { next } = useQueryParams({
    next: "",
  });
  const validation = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  // useEffect(() => {
  //   if (errors?.length > 0) {
  //     toast.error(errors);
  //     dispatch(clearErrors());
  //   }
  //   if (message != "") {
  //     toast.success(message);
  //     dispatch(clearMessages());
  //     // setTimeout(() => navigate(), 2000);
  //   }
  // }, [errors, message]);

  useEffect(() => {
    if (getUserDetails2Data?.data) {
      navigate(next || "/customer-dashboard");
    }
  }, [getUserDetails2Data?.data, navigate, next]);
  return (
    <>
      <LoginLayout image={customerlogin} heading="Login as customer">
        <GoBackBtn />
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validateOnMount
          validationSchema={validation}
          onSubmit={(values, { resetForm }) => {
            const copy = { ...values };
            copy["role"] = "User";
            copy.email = copy.email.toLowerCase();
            dispatch(
              customerLogins(copy, () => {
                reGetUserDetails2();
              })
            );
            resetForm({ values: "" });
          }}
        >
          {(formik) => (
            <Form>
              <FormInput label="Enter your email" name="email" type="email" />
              <FormInput
                label="Enter  your password"
                name="password"
                type="password"
              />
              <div className="forgot-password-link m-1">
                <Link to={"/forgot-password/User"}>forgot password ?</Link>
              </div>
              <Button className="btn-lighter-full rounded center m-3">
                {loading || getUserDetails2Data.fetching ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Puff
                      height="25"
                      width="30"
                      radius="6"
                      color="white"
                      ariaLabel="loading"
                      wrapperStyle
                      wrapperClass
                    />
                  </div>
                ) : (
                  "Login"
                )}
              </Button>
            </Form>
          )}
        </Formik>

        <div className="do-not-have-account" style={{ marginBottom: "10rem" }}>
          <h4>
            Do not have account ?{" "}
            <Link to="/customersignup">Create an account. </Link>{" "}
          </h4>
        </div>
      </LoginLayout>
    </>
  );
};

export default CustomerLogin;
