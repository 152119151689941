import React, { useEffect, useMemo } from "react";
import { ViewPageOutlet } from "../../../components/common";
import { servicesData } from "./../../../data/serviceData";
import { Container, CategoryCard } from "../../../components";
import { useQueryParams } from "../../../hooks";
import { useSelector } from "react-redux";
import { BACKEND_STATIC_URL } from "../../../constants";
import { StyledError } from "../viewCategory/ViewCategory";

const ViewServices = () => {
  const { servicesTypes } = useSelector((s) => s.servicesTypesReducer);

  const { query } = useQueryParams({
    query: "",
  });

  const fixedServicesTypes = useMemo(() => {
    return (
      servicesTypes?.filter(
        (o) => o.serviceName.toLowerCase().search(query?.toLowerCase()) !== -1
      ) || []
    );
  }, [servicesTypes, query]);

  return (
    <>
      <div className="viewoccasion">
        <ViewPageOutlet
          className="occasionviewall"
          title="Services & Products"
          summery="Occasion Base provides services and products for various events such as corporate events, charity fundraisers, and family gatherings. Our collection includes decorations, catering, photography, floral arrangements, and more. Our vendors strive to deliver high-quality products and services that cater to your specific needs and preferences, ensuring memorable experiences."
        >
          {fixedServicesTypes.map((data) => {
            return (
              <>
                <CategoryCard
                  key={data._id}
                  image={`${BACKEND_STATIC_URL}${data.serviceImage}`}
                  name={data.serviceName}
                  to={`/search-results?service=${data._id}`}
                />
              </>
            );
          })}

          {fixedServicesTypes.length === 0 && (
            <StyledError>No result found. Try something else.</StyledError>
          )}
        </ViewPageOutlet>
      </div>
      <div className="mt-3" />
    </>
  );
};

export default ViewServices;
