import { useSelector } from "react-redux";

const useUser = () => {
  const {
    getUserDetails2Data: { data: user },
  } = useSelector((s) => s.userReducer);

  return user;
};

export default useUser;
