export default function removeEmpty(data) {
  if (typeof data === "string") {
    return data.trim() ? data : "";
  } else if (Array.isArray(data)) {
    return data.filter((item) => removeEmpty(item));
  } else if (typeof data === "object" && data !== null) {
    return Object.entries(data)
      .filter(([key, value]) => removeEmpty(value))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  } else {
    return data;
  }
}
