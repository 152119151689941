import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  Container,
  FormInput,
  Heading,
  InfoItem,
  LinkBtn,
} from "../../../../components";
import { Puff } from "react-loader-spinner";
import toast from "react-hot-toast";
import {
  ChangePasswordByCustomer,
  addCustomerToEmailList,
  clearErrors,
  clearMessages,
} from "./../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit } from "react-icons/fa";
import { profile } from "../../../../assests";
import { BACKEND_STATIC_URL } from "../../../../constants";
import useUser from "../../../../hooks/useUser";
import { FormControlLabel, Switch } from "@mui/material";
import { CenteredLoader } from "../../../vender/dashboard/Chat/Chat";
import { reGetUserDetails2 } from "../../../../store/actions/user.action";

const CustomerSettings = () => {
  const dispatch = useDispatch();
  const customerUser = useUser();

  const { getUserDetails2Data } = useSelector((s) => s.userReducer);

  const { message, errors, loading, toggleAddedToMailListData } = useSelector(
    (state) => state.customerReducer
  );
  const passwordvalidation = Yup.object({
    current_password: Yup.string().required("Please enter current password."),
    new_password: Yup.string()
      .required("Please enter new password.")
      .notOneOf(
        [Yup.ref("current_password")],
        "Your are entering previous password"
      )
      .min(8, "Your password is too short."),
    retypePassword: Yup.string()
      .required("Please retype your new password.")
      .oneOf([Yup.ref("new_password")], "Your passwords do not match."),
  });

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
    }
  }, [errors, message]);
  return (
    <>
      <Container className="half">
        <div className="myaccount-container m-5">
          <div className="myaccount-container-image center">
            <img
              src={
                customerUser?.photoPath
                  ? `${BACKEND_STATIC_URL}${customerUser?.photoPath}`
                  : profile
              }
              alt="profile"
            />
          </div>
          <div className="m-3">
            {/* <Button className="rounded align-item-center align-item-right"></Button> */}

            <LinkBtn
              to="/customer-edit-profile"
              className="rounded align-item-center align-item-right"
            >
              <FaEdit style={{ marginRight: ".5rem" }} />
              Edit Profile
            </LinkBtn>
          </div>

          <div>
            <InfoItem
              title="Name"
              content={customerUser?.fullName ? customerUser?.fullName : ""}
            />
            <InfoItem
              title="Phone"
              content={customerUser?.phone ? customerUser?.phone : ""}
            />
            <InfoItem
              title="Email"
              content={customerUser?.email ? customerUser?.email : ""}
            />
            <InfoItem
              title="User Id"
              content={customerUser?.userId ? customerUser?.userId : "--"}
            />
          </div>
        </div>
      </Container>

      <div className="customersettings">
        <Container className="half">
          <Heading className="primary center m-5">Change Password</Heading>
          <Formik
            initialValues={{
              current_password: "",
              new_password: "",
              retypePassword: "",
            }}
            validateOnMount
            validationSchema={passwordvalidation}
            onSubmit={(values, { resetForm }) => {
              let { current_password, new_password } = values;
              let result = {
                oldPassword: current_password,
                newPassword: new_password,
              };
              dispatch(ChangePasswordByCustomer(result));
              resetForm({ values: "" });
            }}
          >
            {(formik) => (
              <div className="m-5">
                <Form>
                  <FormInput
                    place="Current Password"
                    name="current_password"
                    type="password"
                  />
                  <FormInput
                    place="New Password"
                    name="new_password"
                    type="password"
                  />
                  <FormInput
                    place="Confirm Password"
                    name="retypePassword"
                    type="password"
                  />
                  <div className="save-btn">
                    <Button
                      disabled={!formik.isValid}
                      className="btn-lighter rounded center m-2"
                    >
                      {loading ? (
                        <Puff
                          height="25"
                          width="30"
                          radius="6"
                          color="white"
                          ariaLabel="loading"
                          wrapperStyle
                          wrapperClass
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </Container>
      </div>
      <hr className="sectiondivider" />
      {toggleAddedToMailListData?.loading || getUserDetails2Data?.fetching ? (
        <>
          <CenteredLoader />
        </>
      ) : (
        <div className="customersettings">
          <Container className="half">
            <Heading className="primary center m-3">
              Marketing Preferences
            </Heading>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked
                    onChange={(e) => {
                      dispatch(
                        addCustomerToEmailList(() => {
                          toast.success("Toggled!");
                          reGetUserDetails2();
                        })
                      );
                    }}
                    checked={customerUser?.addedToMailList}
                  />
                }
              />
              <p>
                I would like to receive news, offers and promotions from
                Occasion Base.
              </p>
            </div>
          </Container>
        </div>
      )}
      <hr className="sectiondivider" />
    </>
  );
};

export default CustomerSettings;
