export const fakeCustomerRequests=[
  {
    date:"Aug 21 , 2023",
    service:{
      name:"Fake Service 1"
    },
    user:{
      fullName:"FakeName1"
    },
    message:"fake message 1: This is the fake message sent by the first user",
    phoneNumber:"0123483269",
    location:"Islamabad",
    status:"Online"
  },
  {
    date:"Aug 21 , 2023",
    service:{
      name:"Fake Service 1"
    },
    user:{
      fullName:"FakeName1"
    },
    message:"fake message 1: This is the fake message sent by the first user",
    phoneNumber:"0123483269",
    location:"Islamabad",
    status:"Online"
  },
  {
    date:"Aug 21 , 2023",
    service:{
      name:"Fake Service 1"
    },
    user:{
      fullName:"FakeName1"
    },
    message:"fake message 1: This is the fake message sent by the first user",
    phoneNumber:"0123483269",
    location:"Islamabad",
    status:"Online"
  },
  {
    date:"Aug 21 , 2023",
    service:{
      name:"Fake Service 1"
    },
    user:{
      fullName:"FakeName1"
    },
    message:"fake message 1: This is the fake message sent by the first user",
    phoneNumber:"0123483269",
    location:"Islamabad",
    status:"Online"
  },
  {
    date:"Aug 21 , 2023",
    service:{
      name:"Fake Service 1"
    },
    user:{
      fullName:"FakeName1"
    },
    message:"fake message 1: This is the fake message sent by the first user",
    phoneNumber:"0123483269",
    location:"Islamabad",
    status:"Online"
  },
  {
    date:"Aug 21 , 2023",
    service:{
      name:"Fake Service 1"
    },
    user:{
      fullName:"FakeName1"
    },
    message:"fake message 1: This is the fake message sent by the first user",
    phoneNumber:"0123483269",
    location:"Islamabad",
    status:"Online"
  }
]