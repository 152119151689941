import React, { useEffect, useState } from "react";
import "./VenderSignUp.scss";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Container,
  Heading,
  FormInput,
  Button,
  GoBackBtn,
  Text,
} from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import {
  VendorRegister,
  clearErrors,
  clearMessages,
} from "./../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Footer } from "../../../components/common";
import { vendersignup } from "../../../assests";
import FormSelect from "../../../components/FormInput/FormSelect";
import axios from "axios";
import { useGoogleLoc } from "../../../hooks";
import styled from "@emotion/styled";

const VenderSignUp = () => {
  // const [isCountryCode, setIsCountryCode]=useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message, errors, loading } = useSelector(
    (state) => state.vendorAuthReducer
  );
  const validation = Yup.object({
    // left side
    fullName: Yup.string()
      .min(2, "Must be 2 character")
      .max(30, "Must be 30 characters or less")
      .required("Name is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    companyName: Yup.string()
      .min(2, "Must be 2 character")
      .max(40, "Must be 40 characters or less")
      .required("Company name is required"),
    instagram: Yup.string().required("Enter your instagram page name"),
    phone: Yup.string()
      .matches(/^0/, "Phone number must start with zero")
      .matches(/^\d*$/, "Phone number must contain only numeric characters")
      .required("Contact number is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password should be minimum 8 characters long"),
    confirm_password: Yup.string()
      .required("Enter Confirm Password")
      .oneOf([Yup.ref("password"), null], "Your passwords do not match."),
    city: Yup.string()
      .min(2, "Must be 2 character")
      .max(50, "Must be 50 characters or less")
      .required("Town or City is required"),
    postcode: Yup.string()
      .min(2, "Must be 2 character")
      .max(8, "Must be 8 characters or less")
      .required("Postal code is required"),
    address1: Yup.string()
      .min(2, "Must be 2 character")
      .max(50, "Must be 50 characters or less")
      .required("Address line is required"),
    bussinessType: Yup.string()
      .min(2, "Must be 2 character")
      .max(30, "Must be 30 characters or less")
      .required("Business type is required"),
    website: Yup.string().optional(),
  });

  const checkPostalCodeValidity = async (postcode) => {
    const {
      data: { status },
    } = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${postcode}|country:UK&key=AIzaSyA1OKBW722GCRyhTTYsQkM6F4mwBLV5eKY`
    );
    return status === "OK";
  };

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/vender-signin"), 2000);
    }
  }, [errors, message]);

  return (
    <>
      <div className="vendersignup">
        <Container className="main">
          <Container className="lg">
            <GoBackBtn className="m-4" />
          </Container>
        </Container>

        <Container className="lg">
          <Container className="md">
            <div className="vendersignup-hero">
              <div className="vendersignup-hero-heading">
                <h1>
                  Transform your event business with
                  <span> Occasion Base</span>
                </h1>
              </div>
              <div className="vendersignup-hero-image">
                <img src={vendersignup} alt="hero" />
              </div>
            </div>
          </Container>
        </Container>

        <Container className="main">
          <Heading className="pirmary m-5 center">
            Become a vendor today
          </Heading>

          <Container className="md">
            <Formik
              initialValues={{
                fullName: "",
                email: "",
                companyName: "",
                instagram: "",
                phone: "",
                password: "",
                confirm_password: "",
                address1: "",
                city: "",
                postcode: "",
                bussinessType: "",
                website: "",
                isAgreed: false,
                addedToMailList: true,
              }}
              validateOnMount
              validationSchema={validation}
              onSubmit={async (v, { resetForm }) => {
                const values = { ...v };
                if (!(await checkPostalCodeValidity(values.postcode))) {
                  toast.error("Invalid postal code!");
                  return;
                }

                values["role"] = "Vendor";
                values[
                  "address"
                ] = `${values.address1}, ${values.city}, ${values.postcode}`;
                delete values["confirm_password"];
                delete values["address1"];
                delete values["postcode"];
                delete values["isAgreed"];
                values.email = values.email.toLowerCase();
                // console.log('phone no',values['phone']);
                dispatch(VendorRegister(values));
                // resetForm({ values: "" });
              }}
            >
              {(formik) => (
                <Form>
                  <div className="vendersignup-form">
                    <div className="vendersignup-form-left">
                      <FormInput
                        place="Full Name"
                        name="fullName"
                        type="text"
                      />
                      <FormInput place="Email" name="email" type="email" />
                      <FormInput
                        place="Company name"
                        name="companyName"
                        type="text"
                      />
                      <FormInput
                        place="Instagram Page"
                        name="instagram"
                        type="texts"
                      />
                      <FormInput
                        place="Website link (Optional)"
                        name="website"
                        type="string"
                      />
                      <FormSelect
                        place="Type of business"
                        name="bussinessType"
                        type="string"
                      >
                        <option>Type of business</option>
                        <option>Sole Trader</option>
                        <option>Partnership</option>
                        <option>Private Limited Company</option>
                      </FormSelect>
                    </div>
                    <div className="vendersignup-form-right">
                      <FormInput
                        place="Contact number"
                        name="phone"
                        type="text"
                      />
                      <FormInput
                        place="Password"
                        name="password"
                        type="password"
                      />
                      <FormInput
                        place="Confirm Password"
                        name="confirm_password"
                        type="password"
                      />
                      <FormInput
                        place="Address line 1"
                        name="address1"
                        type="text"
                      />
                      <CityInput
                        place="Town/city"
                        type="text"
                        name="city"
                        error={formik.touched.city && formik.errors.city}
                        onBlur={formik.handleBlur}
                        onChange={(place) => {
                          formik.setFieldValue("city", place.formatted_address);
                        }}
                      />
                      <FormInput
                        place="Postal Code"
                        name="postcode"
                        type="text"
                      />
                    </div>
                  </div>

                  <Container className="half"></Container>

                  <BottomContainerStyled>
                    <div className="group">
                      <MyCheckBox
                        label={
                          <>
                            I agree to the{" "}
                            <Link to="/term-for-supplier">T&Cs</Link> and{" "}
                            <Link to="/privacy-policy">Privacy policy</Link> of
                            Occasion Base.
                          </>
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "isAgreed",
                            !formik.values.isAgreed
                          )
                        }
                        checked={formik.values.isAgreed}
                        error={
                          formik.touched.isAgreed && formik.errors.isAgreed
                        }
                      />
                      <MyCheckBox
                        label={
                          <>
                            I would like to receive news, offers and promotions
                            from Occasion Base.
                          </>
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "addedToMailList",
                            !formik.values.addedToMailList
                          )
                        }
                        checked={formik.values.addedToMailList}
                        error={
                          formik.touched.addedToMailList &&
                          formik.errors.addedToMailList
                        }
                      />
                    </div>
                    <Button
                      className="btn-lighter rounded center "
                      disabled={!formik.dirty || !formik.values.isAgreed}
                    >
                      {loading ? (
                        <Puff
                          height="25"
                          width="30"
                          radius="6"
                          color="white"
                          ariaLabel="loading"
                          wrapperStyle
                          wrapperClass
                        />
                      ) : (
                        "Create an account"
                      )}
                    </Button>
                  </BottomContainerStyled>
                </Form>
              )}
            </Formik>
            <div className="m-5" />
          </Container>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default VenderSignUp;

const CityInput = ({ onChange, label, name, place, error, ...rest }) => {
  const cityRef = useGoogleLoc(onChange);

  return (
    <>
      <div className="forminput-container">
        <label htmlFor={name} className="form-label">
          {label}
        </label>
        <input
          ref={cityRef}
          placeholder={place}
          type="text"
          className={`form-input   ${error && "is-invalid"}`}
          autoComplete="off"
          name={name}
          {...rest}
          // style={{padding:'1.5rem 0rem'}}
        />
        {error && <div className="form-error">{error}</div>}
      </div>
    </>
  );
};

const MyCheckBox = ({ label, error, ...rest }) => {
  return (
    <MyCheckBoxStyled>
      <label>
        <input type="checkbox" {...rest} />
        <p>{label}</p>
      </label>
      {error && <p>{error}</p>}
    </MyCheckBoxStyled>
  );
};

const BottomContainerStyled = styled.div`
  margin-top: 4rem;
  display: grid;
  gap: 2rem;
`;

const MyCheckBoxStyled = styled.div`
  display: grid;
  justify-content: center;
  & > label {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    & > input {
    }
    & > p {
      font-size: 1.4rem;

      & > a {
        color: #ffc841;
        will-change: opacity;
        transition: opacity 0.25s ease-in;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  & > p {
    margin-left: 15px;
    color: red;
    font-size: 10px;
    font-weight: 400;
  }
`;
