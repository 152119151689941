import React from "react";
import "./Footer.scss";
import Container from "../../container/Container";
import { TikTok, facebook, insta, logo } from "../../../assests";
import Text from "../../text/Text";
import { Link } from "react-router-dom";
import useUser from "../../../hooks/useUser";
const Footer = () => {
  const user = useUser();
  return (
    <>
      <div className="footer">
        <Container className="main">
          <Container className="lg" parentStyle={{ margin: "2rem 5rem" }}>
            {/* List */}
            <div className="footer-container">
              <div className="footer-container-left">
                <div className="footer-logo">
                  <Link to="/">
                    <img src={logo} alt="logo" />
                  </Link>
                </div>
                <Text className="uppercase">
                  Occasion Base UK Ltd. Company registration number 09309515.
                </Text>
                {/* <Text className="upppercase">
                  4.7 of 5 by 1223 reviews on Trustpilot
                </Text> */}
              </div>
              <div className="footer-container-right">
                <Link to="/aboutus">about us</Link>
                {(!user || user?.role === "User") && (
                  <Link to="/customersignin">sign in as a customer</Link>
                )}
                {(!user || user?.role === "Vendor") && (
                  <Link to="/vender-signin">sign in as a vendor</Link>
                )}
                <Link to="/faq">faq</Link>
                <div className="socialIcon d-flex">
                  <img
                    src={insta}
                    alt="instagram"
                    style={{ marginRight: "1rem" }}
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/occasionbaseuk/?igsh=ZDJnYjU3Z3J2eGkx",
                        "_blank"
                      )
                    }
                  />
                  <img
                    onClick={() =>
                      window.open(
                        "https://www.tiktok.com/@occasionbase?_t=8mGXe2laapc&_r=1",
                        "_blank"
                      )
                    }
                    src={TikTok}
                    alt="TikTok"
                  />
                </div>
                <Link to="/support">support</Link>
              </div>
              {/* New  */}
              <div className="footer-end">
                <h5>Polices</h5>
                <Link to="/cookie-policy">Cookie Policy</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <h5>Terms and conditions</h5>
                <Link to="/term-for-customer">Customers</Link>
                <Link to="/term-for-supplier">Suppliers</Link>
              </div>
            </div>
          </Container>
        </Container>
      </div>
    </>
  );
};

export default Footer;
