import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useUser from "../hooks/useUser";

export const globalHookData = {
  useDispatch: () => {},
  useNavigate: () => {},
  useLocation: {
    pathname: "",
    search: "",
    hash: "",
    state: null,
    key: "",
  },
  useSelector: {},
  useUser: {},
};

const GlobalHook = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useSelector((s) => s);
  const user = useUser();

  useEffect(() => {
    globalHookData.useLocation = location;
    globalHookData.useNavigate = navigate;
    globalHookData.useDispatch = dispatch;
    globalHookData.useSelector = store;
    globalHookData.useUser = user;
  }, [dispatch, location, navigate, store, user]);
  return <></>;
};

export default globalHookData;
export { GlobalHook };
