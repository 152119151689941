// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-btn {
  margin: 4rem  0rem; }
  .back-btn > * {
    color: #4c4d4e;
    font-size: 2rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    font-weight: 500; }
    .back-btn > *:hover {
      color: black; }
  .back-btn > button {
    background: none;
    border: none; }
`, "",{"version":3,"sources":["webpack://./src/components/goBackBtn/GoBackBtn.scss","webpack://./src/scss/_variable.scss"],"names":[],"mappings":"AACA;EACE,kBAAkB,EAAA;EADpB;IAGI,cCOqB;IDNrB,eAAe;IACf,eAAe;IACf,4BAA4B;IAC5B,gBAAgB,EAAA;IAPpB;MASM,YAAY,EAAA;EATlB;IAaI,gBAAgB;IAChB,YAAY,EAAA","sourcesContent":["@import \"../../scss/variable\";\n.back-btn {\n  margin: 4rem  0rem;\n  & > * {\n    color: $heading-primary;\n    font-size: 2rem;\n    cursor: pointer;\n    transition: 0.3s ease-in-out;\n    font-weight: 500;\n    &:hover {\n      color: black;\n    }\n  }\n  & > button {\n    background: none;\n    border: none;\n  }\n}\n","$primary: #ffc841;\n$primary-hover: #cda814;\n\n$light-gray: #f2f1f1;\n$gray: #838282;\n\n$white: #ffffff;\n$sidebar-background: #212121;\n$footer_background: black;\n\n// Typo Graphy\n$heading-primary: #4c4d4e;\n\n$black: black;\n\n$dorger: #e0fff9;\n\n$bronze: #603b30;\n$sliver: #c5c5c5;\n$gold: #c57934;\n$platinium: #2d2d2d;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
