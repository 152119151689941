import React from "react";
import "./ChangePassword.scss";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { logo } from "../../../assests";

import { Heading, FormInput, Button } from "../../../components";
import "./ChangePassword.scss";
import { Link } from "react-router-dom";
const ChangePassword = () => {
  const passwordvalidation = Yup.object({
    password: Yup.string()
      .required("Enter Password")
      .min(8, "Password should be minimum 8 characters long"),
    confirm_password: Yup.string()
      .required("Enter Confirm Password")
      .oneOf([Yup.ref("password"), null], "Your passwords do not match."),
  });

  return (
    <>
      <div className="changepassword">
        <div className="changepassword-container">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
          <Heading className="secondry center m-1">Change password</Heading>

          <div className="m-2">
            <Formik
              initialValues={{
                password: "",
                confirm_password: "",
              }}
              validateOnMount
              validationSchema={passwordvalidation}
              onSubmit={(values, { resetForm }) => {
                console.log(values);
                resetForm({ values: "" });
              }}
            >
              {(formik) => (
                <div className="m-3">
                  <Form>
                    <FormInput
                      place="Enter new password"
                      name="password"
                      type="password"
                    />
                    <FormInput
                      place="Confirm password"
                      name="confirm_password"
                      type="password"
                    />

                    <div className="save-btn">
                      <Button
                        disabled={!formik.isValid}
                        className="rounded center m-2"
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
