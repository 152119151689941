export { default as DashBoard } from "./dashboard/Dashboard";
export { default as VenderProfile } from "./venderProfile/VenderProfile";
export { default as VendorSettings } from "./settings/VendorSettings";
export { default as VenderEditProfile } from "./venderEditProfile/VenderEditProfile";
export { default as CreateService } from "./createService/CreateService";
export { default as MyServices } from "./myServices/MyServices";
export { default as ServiceDetail } from "./serviceDetail/ServiceDetail";
export { default as EditService } from "./editService/EditService";
export { default as CustomerRequest } from "./customerrequest/CustomerRequest";
export { default as ManageMembership } from "./manageMembership/ManageMembership";
export { default as Payments } from "./payments/Payments";
export { default as UpgradePlain } from "./upgragePlain/UpgradePlain";
export { default as AddCard } from "./addCard/AddCard";
export { default as FindOutMembership } from "./findoutMembership/FindOutMembership";
export { default as VerificationForm } from "./verificationform/VerificationForm";
export { default as VendorMessage } from "./vendorMessage/VendorMessage";
export { default as AdminMessage } from "./adminMessage/AdminMessage";
