import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA78CKeR_r45WVj8mrM5q6HA7UX22RJ5Sc",
  authDomain: "occasion-base-417711.firebaseapp.com",
  projectId: "occasion-base-417711",
  storageBucket: "occasion-base-417711.appspot.com",
  messagingSenderId: "506373300329",
  appId: "1:506373300329:web:5c1d1d1bf683374eaf02f8",
  measurementId: "G-Y6SQSNRH2Y",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage();
export const db = getFirestore();
