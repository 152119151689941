import styled from "@emotion/styled";
import { Pagination } from "@mui/material";
import React, { useMemo } from "react";
import { useQueryParams } from "../../../hooks";

const MyPaginationStyled = styled.div`
  display: grid;
  place-items: center;
`;

const PaginationStyled = styled(Pagination)`
  & .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis) {
    color: black;
    background-color: #ffc841;
    font-size: 1.2rem;
    font-family: Montserrat, "Roboto", sans-serif;
    &:hover {
      background-color: #ffc841;
      color: black;
    }

    & .Mui-selected {
      background-color: #ffc841;
      color: black;
    }
  }
`;

const MyPagination = ({ totalPages, stateName }) => {
  const { setQueryParam, ...rest } = useQueryParams({
    [stateName || "page"]: "1",
  });

  const page = useMemo(() => rest[stateName || "page"], [rest, stateName]);
  return (
    totalPages > 1 && (
      <MyPaginationStyled>
        <PaginationStyled
          page={Number(page)}
          count={totalPages}
          onChange={(_, newPage) =>
            setQueryParam({ [stateName || "page"]: newPage })
          }
        />
      </MyPaginationStyled>
    )
  );
};

export default MyPagination;
