import { v4 } from "uuid";

const generatedIds = {};

const generateRandomId = () => {
  let id = Date.now() + "-" + v4();

  while (generatedIds[id]) {
    id = Date.now() + "-" + v4();
  }

  generatedIds[id] = true;
  return id;
};

export default generateRandomId;
