import axios from "axios";
import { publicConstant } from "../constants";
import { BACKEND_URL } from "../../constants";

export const getLandingPageData = () => {
  return async (dispatch) => {
    dispatch({ type: publicConstant.GET_LANDING_PAGE_DATA_REQUEST });
    try {
      const [{ data }, { data: data2 }] = await Promise.all([
        axios.get(`${BACKEND_URL}/v1/api/interface`),
        axios.get(`${BACKEND_URL}/v1/api/interface/get/review`),
        axios.post(`${BACKEND_URL}/v1/api/service/visitors/by/browsers`),
      ]);

      dispatch({
        type: publicConstant.GET_LANDING_PAGE_DATA_SUCCESS,
        payload: {
          text: data?.text ?? "",
          videoLink: data?.videoLink ?? "",
          howOccasionShowOnHomePage: data?.howOccasionShowOnHomePage ?? "",
          pictures: data?.pictures ?? "",
          heroImage: data?.heroImage || "",
          reviews:
            data2.length > 0
              ? data2
              : [
                  {
                    name: "Tony",
                    time: "2 months ago",
                    description:
                      "Easy to use and stress-free planningPlanning our wedding was a breeze thanks to OccasionBase. The website was so easy to use and helped us find amazing vendors for every aspect of our big day. We loved being able to message vendors directly through the site and keep track of all our bookings in one place. Highly recommend for stress-free planning",
                  },
                  {
                    name: "John",
                    time: "1 month ago",
                    description:
                      "Saved us time and moneyOccasionBase saved us so much time and money when planning our corporate event. Instead of spending hours searching for vendors and negotiating prices, we were able to easily find and compare vendors on the site. The prices were competitive and the quality of service was outstanding. Thanks to OccasionBase",
                  },
                ],
        },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: publicConstant.GET_LANDING_PAGE_DATA_FAILURE,
        payload: { err: error?.response?.data?.errors[0]?.message },
      });
    }
  };
};
