import axios from "axios";
import { occasionConstant} from "../constants";
import { BACKEND_URL } from "../../constants";


export const getAllOccasions = () => {
    return async (dispatch) => {
        dispatch({
            type: occasionConstant.GET_ALL_OCCASIONS_REQUEST,
        });
        const res = await axios.get(`${BACKEND_URL}/v1/api/occasions`);
        // console.log(res);
        if (res.status === 200) {
            const  occasionList  = res.data;
            // console.log(occasionList);
            dispatch({
                type: occasionConstant.GET_ALL_OCCASIONS_SUCCESS,
                payload: { occasions: occasionList },
            });
        } else {
            console.log(res.data.error);
            dispatch({
                type: occasionConstant.GET_ALL_OCCASIONS_FAILURE,
                payload: { error: res.data.error },
            });
        }
    };
}