import React, { useEffect, useMemo, useState } from "react";
import "./CustomerRequest.scss";
import { Button, Container, Heading, Modal } from "../../../../components";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import {
  CreateChatConnectionForVendor,
  GetAllCustomerCancelledRequest,
  GetAllCustomerCompletedRequest,
  GetAllCustomerRequest,
  UpdateCustomerRequest,
  addMessage,
  clearChatConnection,
  clearErrors,
  clearMessages,
  createNewChat,
  getAllChats,
} from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  RiArrowRightFill,
  RiDeleteBin6Line,
  RiPencilFill,
} from "react-icons/ri";
import { fakeCustomerRequests } from "./fakeCustomerRequestData";
import NewModal from "../../../../components/modal/NewModal";
import MyPagination from "../../../../components/common/Pagination/Pagination";
import { useQueryParams } from "../../../../hooks";
import { sendMessageNew } from "../../../../firebase/message";
import { getChatOfAvailableChats } from "../../../../utils/getChat";
import {
  NOTIFICATION_TYPES,
  sendNotfication,
  updateNotificationSeen,
} from "../../../../firebase/notfication";
import useCallOnDependencyChange from "../../../../hooks/useCallOnDependencyChange";
import styled from "@emotion/styled";
import useUser from "../../../../hooks/useUser";
import { CenteredLoader } from "../Chat/Chat";

const CustomerRequest = () => {
  const [rejectionMessage, setRejectionMessage] = useState("");
  const [selectedRequestForRejection, setSelectedRequestForRejection] =
    useState(null);
  const [cancellationMessage, setCancellationMessage] = useState("");
  const [selectedRequestForCancellation, setSelectedRequestForCancellation] =
    useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allNotifications } = useSelector((s) => s.notificationReducer);
  const {
    message,
    customerRequest,
    errors,
    loading,
    getCustomerCompletedRequestData,
    getCustomerCancelledRequestData,
  } = useSelector((state) => state.vendorReducer);

  const { page, cancelledRequestsTablePage, completedRequestsTablePage } =
    useQueryParams({
      page: "1",
      cancelledRequestsTablePage: "1",
      completedRequestsTablePage: "1",
    });

  // GetAllCustomerCancelledRequest
  // GetAllCustomerCompletedRequest

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message !== "") {
      toast.success(message);
      dispatch(clearMessages());
    }
  }, [dispatch, errors, message]);

  useEffect(() => {
    updateNotificationSeen(NOTIFICATION_TYPES.CUSTOMER_REQUEST);
  }, [allNotifications]);

  useCallOnDependencyChange(() => {
    if (
      allNotifications?.filter(
        (n) => n.notficationType === NOTIFICATION_TYPES.CUSTOMER_REQUEST
      ).length
    )
      dispatch(GetAllCustomerRequest(vendor?.id, page, limit));
  }, [allNotifications]);

  const vendor = useUser();

  const limit = useMemo(() => 10, []);

  useEffect(() => {
    vendor?.id && dispatch(GetAllCustomerRequest(vendor?.id, page, limit));
  }, [dispatch, page, vendor?.id, limit]);

  useEffect(() => {
    vendor?.id &&
      dispatch(
        GetAllCustomerCancelledRequest(
          vendor?.id,
          cancelledRequestsTablePage,
          limit
        )
      );
  }, [dispatch, cancelledRequestsTablePage, vendor?.id, limit]);

  useEffect(() => {
    vendor?.id &&
      dispatch(
        GetAllCustomerCompletedRequest(
          vendor?.id,
          completedRequestsTablePage,
          limit
        )
      );
  }, [dispatch, completedRequestsTablePage, vendor?.id, limit]);

  const createChatWithCustomer = ({ userId, onSuccess }) => {
    createNewChat({
      payload: {
        body: {
          participants: [
            {
              detail: userId,
              docModel: "User",
            },
          ],
        },
      },
      onSuccess,
    });
  };

  const user = useUser();

  const sendMessageToCustomer = ({
    message = "",
    userId,
    vendorId,
    onSuccess,
  }) => {
    const chat = getChatOfAvailableChats(userId, vendorId);

    const sendMessage = (chatId) => {
      sendMessageNew({
        message,
        chatId,
        receiverId: userId,
        senderId: vendorId,
      });
    };

    if (chat) {
      sendMessage(chat?._id);

      onSuccess && onSuccess();
    } else {
      createChatWithCustomer({
        userId,
        onSuccess: (chat) => {
          sendMessage(chat?._id);
          onSuccess && onSuccess();
          getAllChats();
        },
      });
    }
  };

  const handleRequestStatus = ({
    status,
    message,
    requestId,
    vendorId,
    userId,
    onSuccess,
  }) => {
    dispatch(
      UpdateCustomerRequest(
        requestId,
        {
          status,
        },
        vendorId ?? "",
        () => {
          dispatch(GetAllCustomerRequest(vendorId, page, limit));
          sendMessageToCustomer({ message, userId, vendorId, onSuccess });
          sendNotfication({
            message: `Vendor ${status} your request!`,
            notficationType: NOTIFICATION_TYPES.CUSTOMER_REQUEST,
            senderId: user?.id,
            receiverId: userId,
            productId: requestId,
          });
        }
      )
    );
  };

  const handleChatRedirection = ({ userId, vendorId }) => {
    const oldChatId = getChatOfAvailableChats(vendorId, userId)?._id;

    const handleNavigate = (chatId) => {
      navigate(`/chat?chatId=${chatId}`);
    };

    if (oldChatId) {
      handleNavigate(oldChatId);
    } else {
      createChatWithCustomer({
        userId,
        onSuccess: (chat) => {
          handleNavigate(chat?._id);
          getAllChats();
        },
      });
    }
  };

  return (
    <>
      <GroupStyled>
        {loading ? (
          <div className="m-5">
            <CenteredLoader />
          </div>
        ) : (
          <Container className="lg">
            <Heading className="center m-2">Customer Request</Heading>

            <SingleGroupStyled>
              <div className="table-container">
                <div className="customer-request-table">
                  <table>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Service name</th>
                        <th>Customer</th>
                        <th>Phone #</th>
                        <th className="center">Booking Date</th>
                        <th>Message</th>
                        <th>Location</th>
                        <th>STATUS</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customerRequest?.results?.length > 0 ? (
                        customerRequest?.results?.map((data, ind) => {
                          return (
                            <tr ke={ind}>
                              <td>{1 + (limit * (page - 1) + ind)}</td>
                              <td>
                                {data?.service?.customServiceName ||
                                  data?.service?.serviceName?.serviceName ||
                                  "--"}
                              </td>
                              <td>{`${data?.user?.fullName || ""} ${
                                data?.user?.userId
                                  ? ` (${data?.user?.userId})`
                                  : ""
                              }`}</td>
                              <td>{data?.phoneNumber || "Not Provided"}</td>
                              <td className="center">
                                {data?.date &&
                                  new Date(data.date).toDateString()}
                              </td>
                              <td>{data?.message || ""}</td>
                              <td>{data?.location || ""}</td>
                              <td>{data?.status || ""}</td>
                              <ActionCellStyled>
                                <Button
                                  // className="arrow-link"
                                  onClick={() => {
                                    handleChatRedirection({
                                      userId: data?.user?.id,
                                      vendorId: data?.vendor.id,
                                    });
                                  }}
                                  // disabled={data?.status === "rejected"}
                                >
                                  Go to Chat
                                  <RiArrowRightFill />
                                </Button>
                                {data?.status === "pending" && (
                                  <>
                                    <Button
                                      onClick={() => {
                                        handleRequestStatus({
                                          status: "accepted",
                                          message: `Your following request has been accepted. \n Service Name: ${
                                            data?.service?.customServiceName ||
                                            data?.service?.serviceName
                                              ?.serviceName ||
                                            ""
                                          }\n Location: ${
                                            data?.location?.slice(0, -4) || ""
                                          } \n Booking Date: ${
                                            data?.date &&
                                            new Date(data.date).toDateString()
                                          }`,
                                          requestId: data?.id,
                                          userId: data?.user?.id,
                                          vendorId: data?.vendor?.id,
                                        });
                                      }}
                                    >
                                      Accept
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        setRejectionMessage(
                                          "You request has been rejected."
                                        );
                                        setSelectedRequestForRejection(data);
                                      }}
                                    >
                                      Reject
                                    </Button>
                                  </>
                                )}
                                {data?.status === "accepted" && (
                                  <>
                                    <Button
                                      onClick={() => {
                                        setCancellationMessage(
                                          "You request has been cancelled."
                                        );
                                        setSelectedRequestForCancellation(data);
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                )}
                              </ActionCellStyled>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colspan="9">No record found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div>
                <MyPagination totalPages={customerRequest?.totalPages} />
              </div>
            </SingleGroupStyled>
          </Container>
        )}

        {loading ? (
          <div className="m-5">
            <CenteredLoader />
          </div>
        ) : (
          <Container className="lg">
            <Heading className="center m-2">Cancelled Request</Heading>

            <SingleGroupStyled>
              <div className="table-container">
                <div className="customer-request-table">
                  <table>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Service name</th>
                        <th>Customer</th>
                        <th>Phone #</th>
                        <th className="center">Booking Date</th>
                        <th>Message</th>
                        <th>Location</th>
                        <th>STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getCustomerCancelledRequestData?.data?.results?.length >
                      0 ? (
                        getCustomerCancelledRequestData?.data?.results?.map(
                          (data, ind) => {
                            return (
                              <tr ke={ind}>
                                <td>{1 + (limit * (page - 1) + ind)}</td>
                                <td>
                                  {data?.service?.customServiceName ||
                                    data?.service?.serviceName?.serviceName ||
                                    "--"}
                                </td>
                                <td>{`${data?.user?.fullName || ""} ${
                                  data?.user?.userId
                                    ? ` (${data?.user?.userId})`
                                    : ""
                                }`}</td>
                                <td>{data?.phoneNumber || "Not Provided"}</td>
                                <td className="center">
                                  {data?.date &&
                                    new Date(data.date).toDateString()}
                                </td>
                                <td>{data?.message || ""}</td>
                                <td>{data?.location || ""}</td>
                                <td>{data?.status || ""}</td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan={8}>No record found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div>
                <MyPagination
                  stateName="cancelledRequestsTablePage"
                  totalPages={getCustomerCancelledRequestData?.data?.totalPages}
                />
              </div>
            </SingleGroupStyled>
          </Container>
        )}
        {loading ? (
          <div className="m-5">
            <CenteredLoader />
          </div>
        ) : (
          <Container className="lg">
            <Heading className="center m-2">Completed Request</Heading>

            <SingleGroupStyled>
              <div className="table-container">
                <div className="customer-request-table">
                  <table>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Service name</th>
                        <th>Customer</th>
                        <th>Phone #</th>
                        <th className="center">Booking Date</th>
                        <th>Message</th>
                        <th>Location</th>
                        <th>STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getCustomerCompletedRequestData?.data?.results?.length >
                      0 ? (
                        getCustomerCompletedRequestData?.data?.results?.map(
                          (data, ind) => {
                            return (
                              <tr ke={ind}>
                                <td>{1 + (limit * (page - 1) + ind)}</td>
                                <td>
                                  {data?.service?.customServiceName ||
                                    data?.service?.serviceName?.serviceName ||
                                    "--"}
                                </td>
                                <td>{`${data?.user?.fullName || ""} ${
                                  data?.user?.userId
                                    ? ` (${data?.user?.userId})`
                                    : ""
                                }`}</td>
                                <td>{data?.phoneNumber || "Not Provided"}</td>
                                <td className="center">
                                  {data?.date &&
                                    new Date(data.date).toDateString()}
                                </td>
                                <td>{data?.message || ""}</td>
                                <td>{data?.location || ""}</td>
                                <td>Completed</td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan={8}>No record found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div>
                <MyPagination
                  totalPages={getCustomerCompletedRequestData?.data?.totalPages}
                  stateName={"completedRequestsTablePage"}
                />
              </div>
            </SingleGroupStyled>
          </Container>
        )}
      </GroupStyled>

      <NewModal
        show={Boolean(selectedRequestForRejection)}
        setShow={() => setSelectedRequestForRejection(null)}
      >
        <div className="rejection-modal">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleRequestStatus({
                status: "rejected",
                message: `Your following request has been rejected. \n Service Name: ${
                  selectedRequestForRejection?.service?.customServiceName ||
                  selectedRequestForRejection?.service?.serviceName
                    ?.serviceName ||
                  ""
                }\n Location: ${
                  selectedRequestForRejection?.location?.slice(0, -4) || ""
                } \n Booking Date: ${
                  selectedRequestForRejection?.date &&
                  new Date(selectedRequestForRejection.date).toDateString()
                }\n Rejection Reason : ${rejectionMessage || "No Provided"}`,
                requestId: selectedRequestForRejection?.id,
                userId: selectedRequestForRejection?.user?.id,
                vendorId: selectedRequestForRejection?.vendor?.id,
                onSuccess: () => {
                  setSelectedRequestForRejection(null);
                  setRejectionMessage("");
                },
              });
            }}
          >
            <label htmlFor="reason">Give Reason for rejecting</label>
            <textarea
              name="reason"
              rows={10}
              value={rejectionMessage}
              onChange={(e) => {
                setRejectionMessage(e.target.value);
              }}
            ></textarea>
            <div>
              <Button type="submit">Reject</Button>
              <Button onClick={() => setSelectedRequestForRejection(null)}>
                Close
              </Button>
            </div>
          </form>
        </div>
      </NewModal>

      <NewModal
        show={Boolean(selectedRequestForCancellation)}
        setShow={() => setSelectedRequestForCancellation(null)}
      >
        <div className="rejection-modal">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleRequestStatus({
                status: "cancelled",
                message: `Your following request has been Cancelled. \n Service Name: ${
                  selectedRequestForCancellation?.service?.customServiceName ||
                  selectedRequestForCancellation?.service?.serviceName
                    ?.serviceName ||
                  ""
                }\n Location: ${
                  selectedRequestForCancellation?.location?.slice(0, -4) || ""
                } \n Booking Date: ${
                  selectedRequestForCancellation?.date &&
                  new Date(selectedRequestForCancellation.date).toDateString()
                }\n Cancellation Reason : ${
                  cancellationMessage || "No Provided"
                }`,
                requestId: selectedRequestForCancellation?.id,
                userId: selectedRequestForCancellation?.user?.id,
                vendorId: selectedRequestForCancellation?.vendor?.id,
                onSuccess: () => {
                  setSelectedRequestForCancellation(null);
                  setCancellationMessage("");
                },
              });
            }}
          >
            <label htmlFor="reason">Give Reason for cancelling</label>
            <textarea
              name="reason"
              rows={10}
              value={cancellationMessage}
              onChange={(e) => {
                setCancellationMessage(e.target.value);
              }}
            ></textarea>
            <div>
              <Button type="submit">Cancel</Button>
              <Button onClick={() => setSelectedRequestForCancellation(null)}>
                Close
              </Button>
            </div>
          </form>
        </div>
      </NewModal>
    </>
  );
};

export default CustomerRequest;

const ActionCellStyled = styled.td`
  min-width: 40rem;
  border: none;
`;

const SingleGroupStyled = styled.div`
  display: grid;
  align-items: center;
  gap: 1rem;
`;

const GroupStyled = styled.div`
  display: grid;
  align-items: center;
  gap: 2rem;
`;
