import { Tooltip as MUITooltip, tooltipClasses } from "@mui/material";
import styled from "@emotion/styled";

const LightTooltip = styled(({ className, ...props }) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "30rem",
    fontSize: "1.6rem",
    textAlign: "center",
  },
}));

const Tooltip = ({ notShow = false, title = "", children }) => {
  return notShow ? (
    children
  ) : (
    <LightTooltip placement="top" arrow title={title}>
      {children}
    </LightTooltip>
  );
};

export default Tooltip;
