import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import "./VendorMessage.scss";
import { BiSend, BiSearch } from "react-icons/bi";
import { profile, venderprofile } from "../../assests";
import ScrollToBottom from "react-scroll-to-bottom";
import { TfiMenuAlt } from "react-icons/tfi";
import { RxCross2 } from "react-icons/rx";
import toast from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import {
  CreateChatConnectionForVendor,
  GetUserForChat,
  addMessage,
  getUserInfo,
  clearErrors,
  clearMessages,
  getAllChatIdsVendor,
  resetVendorReducer,
  getAdminInfo,
  GetAllAdmin,
} from "./../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { db, storage } from "../../firebase";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
  addDoc,
  serverTimestamp,
  writeBatch,
  getDocs,
} from "firebase/firestore";
import useUser from "../../hooks/useUser";

const Messages = (props) => {
  const location = useLocation();
  const fromCustomerRequest = location.state;
  console.log("data from location state", fromCustomerRequest);

  const dispatch = useDispatch();
  const messageRef = useRef();
  const {
    message,
    errors,
    chatUser,
    admin,
    chatConnection,
    userInfo,
    loading,
    chatConnectionIds,
  } = useSelector((state) => state.vendorReducer);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [msg, setMsg] = useState("");
  const [showContact, setshowContact] = useState(true);
  const [messages, setMessages] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [allUsers, setAllUsers] = useState(null);
  const bottomRef = useRef(null);
  const messageHandler = (event) => {
    setMsg(event.target.value);
  };

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
    }
  }, [errors, message]);

  const vendor = useUser();

  useEffect(() => {
    // console.log('Chat Connectio Ids',chatConnectionIds);

    if (!(chatConnectionIds?.length === 0 || allUsers?.length === 0)) {
      var elem = document.getElementById("chatbox");
      // console.log('all users',allUsers);

      const myChatsMembersIds = allUsers?.map((member) =>
        member.role !== "Admin" ? member?.user?.id : member?.id
      );
      const myChatConnections = chatConnectionIds?.filter((chat) =>
        myChatsMembersIds?.some(
          (id) =>
            chat?.members?.includes(id) && chat?.members?.includes(vendor?.id)
        )
      );

      const collectionRef = collection(db, "customerVendorChat");
      const unsubscribeFunctions = [];
      // console.log('MY chat Connections',myChatConnections);

      myChatConnections
        ?.map((chat) => chat?.id)
        ?.forEach((connectionId) => {
          // console.log('connectionID',connectionId);

          const q = query(
            collectionRef,
            where("conversationId", "==", connectionId),
            orderBy("createdAt")
          );

          const unsubscribe = onSnapshot(q, (snapshot) => {
            const newMessage = snapshot.docs.map((doc) => ({
              ...doc.data(),
              _id: doc.id,
            }));

            setMessages((prevMessages) => ({
              ...prevMessages,
              [connectionId]: newMessage,
            }));
          });

          unsubscribeFunctions.push(unsubscribe);
        });

      return () => {
        unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
      };
    }
  }, [chatConnectionIds, vendor?.id, allUsers]);

  useEffect(() => {
    if (chatConnection?.id && messages[chatConnection?.id]?.length > 0) {
      const f1 = async () => {
        const collectionRef = collection(db, "customerVendorChat");

        const q = query(
          collectionRef,
          where("isSeen", "==", false),
          where("conversationId", "==", chatConnection?.id),
          where(
            "senderId",
            "==",
            chatConnection?.members.find((id) => id !== vendor?.id)
          )
        );

        const querySnapshot = await getDocs(q);

        const batch = writeBatch(db);

        querySnapshot.forEach((doc) => {
          const docRef = doc.ref;
          batch.update(docRef, { isSeen: true });
        });

        await batch.commit();
      };

      f1();
    }
  }, [chatConnection, messages, vendor?.id]);

  useEffect(() => {
    dispatch(getAllChatIdsVendor());
  }, [dispatch]);

  useLayoutEffect(
    () => () => {
      dispatch(resetVendorReducer());
    },
    [dispatch]
  );

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(GetUserForChat(vendor?.id));
      await dispatch(GetAllAdmin());
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (chatUser?.length > 0 || admin?.length > 0) {
      let mergedUsers = [];
      // console.log('CHat users',chatUser);

      if (chatUser?.length > 0 && admin?.length > 0) {
        mergedUsers = chatUser?.concat(admin);
      } else {
        mergedUsers = chatUser?.length > 0 ? chatUser : admin;
      }

      const filteredUsers = mergedUsers.filter((user) => user !== undefined);

      setAllUsers(filteredUsers);

      if (filteredUsers.length > 0) {
        const result = { member: filteredUsers[0]?.id };
        dispatch(CreateChatConnectionForVendor(result));
        dispatch(getUserInfo(filteredUsers[0]?.user?.id));
        dispatch(getAdminInfo(admin[0]?.id && admin[0].id));
      }
    }

    setSearchText("");
  }, [admin]);

  let loginUser = vendor?.id;
  const hanldeChatMessage = async () => {
    // exit if the message is empty or already sending message
    if (msg === "" || sendingMessage) return;
    setSendingMessage(true);
    messageRef.current.focus();
    let result = {
      message: msg,
      conversationId: chatConnection?.id ? chatConnection.id : "",
      senderId: loginUser,
      isSeen: false,
    };

    try {
      // Wait for the message to be added to the database.
      await dispatch(addMessage(result));

      // If the message is successfully added, add it to the local state.
      setMessages((prevMessages) => ({
        ...prevMessages,
        [chatConnection?.id]: [
          ...(prevMessages[chatConnection?.id] || []),
          {
            ...result,
            _id: Math.random().toString(), // Generate a temporary ID
          },
        ],
      }));
      setMsg("");
    } catch (error) {
      // If there's an error, handle it here.
      console.error(error);
    } finally {
      setSendingMessage(false);
    }

    // Use setTimeout to wait for the message to be added before scrolling
    setTimeout(() => {
      var elem = document.getElementById("chatbox");
      elem.scrollTop = elem.scrollHeight;
    }, 500);
  };
  useEffect(() => {
    console.log("All Users", allUsers);
  }, [allUsers]);
  return (
    <>
      <div className="chat">
        <div className="chat-container">
          {showContact ? (
            <div className="chat-container-user">
              <div className="chat-container-user-search">
                <input
                  type="text"
                  placeholder="Search messages"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <BiSearch className="chat-container-user-search-icon" />
              </div>
              {loading ? (
                <Puff
                  height="60"
                  width="60"
                  radius="6"
                  color="black"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                />
              ) : allUsers?.length > 0 ? (
                allUsers
                  ?.filter((data) => {
                    if (data.role !== "Admin") {
                      return data.user?.fullName
                        .toLowerCase()
                        .startsWith(searchText.toLowerCase());
                    } else {
                      return data.fullName
                        .toLowerCase()
                        .startsWith(searchText.toLowerCase());
                    }
                  })
                  .map((data, ind) => {
                    if (data.role !== "Admin") {
                      return (
                        <UserMessage
                          key={ind}
                          to={data.id}
                          profile={
                            data?.user?.photoPath && data.user?.photoPath
                          }
                          name={data?.user?.fullName && data.user?.fullName}
                          openChat={() => setshowContact(!showContact)}
                          fromCustomerRequest={fromCustomerRequest}
                          userId={data?.user?.id}
                          unseenMessages={
                            messages[
                              chatConnectionIds.find(
                                (chat) =>
                                  chat?.members?.includes(data?.user?.id) &&
                                  chat?.members?.includes(vendor?.id)
                              )?.id
                            ]
                              ?.filter((message) => message?.isSeen === false)
                              .filter(
                                (message) => message?.senderId !== vendor?.id
                              ).length
                          }
                        />
                      );
                    } else {
                      return (
                        <UserMessage
                          key={ind}
                          to={data.id}
                          profile={
                            data?.profilePicture ? data.profilePicture : profile
                          }
                          name={data?.fullName && data.fullName}
                          openChat={() => setshowContact(!showContact)}
                          userId={data?.id}
                          unseenMessages={
                            messages[
                              chatConnectionIds.find(
                                (chat) =>
                                  chat?.members?.includes(data?.id) &&
                                  chat?.members?.includes(vendor?.id)
                              )?.id
                            ]
                              ?.filter((message) => message?.isSeen === false)
                              .filter(
                                (message) => message?.senderId !== vendor?.id
                              ).length
                          }
                        />
                      );
                    }
                  })
              ) : (
                <h1>No Users Found</h1>
              )}
            </div>
          ) : (
            ""
          )}
          {chatConnection?.id ? (
            <div className="chat-container-feed">
              <div className="chat-container-feed-profile space-between">
                <div className="chat-container-feed-profile-flex  align-item-center">
                  <div
                    className="chat-container-feed-profile-menuicon"
                    onClick={() => setshowContact(!showContact)}
                  >
                    <TfiMenuAlt />
                  </div>
                  <img
                    src={userInfo?.photoPath ? userInfo.photoPath : profile}
                    alt="profile"
                  />
                  <h3>{userInfo?.fullName && userInfo.fullName}</h3>
                </div>

                {showContact ? (
                  <div
                    className="chat-container-feed-profile-menuicon-cross"
                    onClick={() => setshowContact(!showContact)}
                  >
                    <RxCross2 />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="chat-container-feed-box" id="chatbox">
                {chatConnection?.id &&
                  messages[chatConnection?.id]?.map((msg) =>
                    msg.senderId != loginUser ? (
                      <Message
                        class="recieve-message"
                        key={msg.id}
                        text={msg.message}
                      />
                    ) : (
                      <Message
                        class="send-message"
                        key={msg.id}
                        text={msg.message}
                      />
                    )
                  )}
              </div>

              <div className="chat-container-feed-input">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    hanldeChatMessage();
                  }}
                >
                  <input
                    type="text"
                    placeholder="Type a message"
                    value={msg}
                    onChange={messageHandler}
                    ref={messageRef}
                  />
                  <button
                    type="submit"
                    className="message-send-btn"
                    disabled={sendingMessage}
                  >
                    <BiSend />
                  </button>
                </form>
              </div>
            </div>
          ) : (
            <center
              style={{
                color: "black",
                margin: "18%",
              }}
            >
              <h1>Click On Any User For Chat</h1>
            </center>
          )}
        </div>
      </div>
    </>
  );
};

export default Messages;

const UserMessage = (props) => {
  const dispatch = useDispatch();
  const handleChatConnection = (userId) => {
    let result = { member: userId };
    dispatch(CreateChatConnectionForVendor(result));
    dispatch(getUserInfo(userId));
    dispatch(getAdminInfo(userId));
  };
  const handleClick = () => {
    handleChatConnection(props.userId);
    props.openChat();
    var elem = document.getElementById("chatbox");
    if (elem) {
      elem.scrollTop = elem.scrollHeight;
    }
  };
  useEffect(() => {
    // if(props.fromCustomerRequest){
    //   handleChatConnection(props.userId)
    // }
    if (props.fromCustomerRequest?.user?.id === props?.userId) {
      console.log(
        "setting chat connection and chat with userID",
        props?.userId
      );

      handleClick();
    }
  }, [props.fromCustomerRequest]);
  return (
    <>
      <div className="usermessage" onClick={handleClick}>
        <div className="usermessage" id={props.active}>
          <div className="usermessage-container">
            <div className="usermessage-container-flex">
              <div className="usermessage-container-left">
                <div className="usermessage-container-image">
                  <img src={props.profile ?? profile} alt="profile" />
                </div>
                <div className="usermessage-container-name">
                  <h3>{props.name}</h3>
                  <p>{props.message}</p>
                </div>
              </div>
              {props.unseenMessages ? (
                <div className="usermessage-container-right">
                  <span>
                    {props.unseenMessages > 9 ? "9+" : props.unseenMessages}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Message = (props) => {
  const messagesEndRef = useRef();
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  }, [props.children]);
  return (
    <>
      <div className={`message ${props.class}`}>
        <div className="message-container">
          <p ref={messagesEndRef}>{props.text}</p>
        </div>
      </div>
    </>
  );
};
