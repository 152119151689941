import React, { useEffect } from "react";

const useUserChange = (cb) => {
  if (!cb) {
    throw new Error("Cb is required!");
  }

  useEffect(() => {
    const f1 = () => {
      return cb();
    };
    window.addEventListener("user-state-change", f1);
    f1();

    return () => {
      window.removeEventListener("user-state-change", f1);

      f1 && f1();
    };
  }, []);
};

export default useUserChange;
