import React from "react";
import "./LoginLayout.scss";
import { Container, Heading } from "../../components";
import { Footer } from "../../components/common";

const LoginLayout = (props) => {


  return (
    <>
      <div className="loginlayout">
        <Container className="main">
          <div className="loginlayout-container">
            <div className="loginlayout-container-form">
              <Container className="sm">
                <Heading className="primary m-4 center">
                  {props.heading}
                </Heading>
                {props.children}
              </Container>
            </div>
            <div className="loginlayout-container-image">
              <img src={props.image} alt="" />
            </div>
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default LoginLayout;
