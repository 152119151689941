import { customerAuthConstant } from "./../constants";
import { customerOrVendorRegister, customerOrVendorLogin } from "./../../http";
import { emitUserStateChange } from "../../utils";
import toast from "react-hot-toast";

export const CustomerRegister = (user) => {
  return async (dispatch) => {
    dispatch({ type: customerAuthConstant.CUSTOMER_REGISTER_REQUEST });
    try {
      await customerOrVendorRegister(user);
      dispatch({
        type: customerAuthConstant.CUSTOMER_REGISTER_SUCCESS,
        payload: "Created",
      });
      emitUserStateChange();
    } catch (error) {
      dispatch({
        type: customerAuthConstant.CUSTOMER_REGISTER_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const customerLogins = (user, onSuccess) => {
  return async (dispatch) => {
    dispatch({ type: customerAuthConstant.CUSTOMER_LOGIN_REQUEST });
    try {
      const result = await customerOrVendorLogin(user);
      const { data } = result;
      dispatch({
        type: customerAuthConstant.CUSTOMER_LOGIN_SUCCESS,
        payload: "",
      });
      sessionStorage.setItem("userToken", data.tokens.access.token);
      sessionStorage.setItem("userRefreshToken", data.tokens.refresh.token);
      emitUserStateChange();
      toast.success("Login Successfully");
      onSuccess && onSuccess();
    } catch (error) {
      dispatch({
        type: customerAuthConstant.CUSTOMER_LOGIN_FAILURE,
        payload: { err: "" },
      });

      error.response.data.errors[0].message &&
        toast.error(error.response.data.errors[0].message);
    }
  };
};

export const logOut = (onSuccess) => {
  return async (dispatch) => {
    dispatch({ type: customerAuthConstant.CUSTOMER_LOGOUT_REQUEST });
    try {
      sessionStorage.clear();
      dispatch({
        type: customerAuthConstant.CUSTOMER_LOGOUT_SUCCESS,
        payload: "",
      });
      onSuccess && onSuccess();
      emitUserStateChange();
    } catch (error) {
      dispatch({
        type: customerAuthConstant.CUSTOMER_LOGOUT_FAILURE,
        payload: { err: "" },
      });
    }
  };
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: customerAuthConstant.CLEAR_ERRORS });
};

// Clearing Messages
export const clearMessages = () => async (dispatch) => {
  dispatch({ type: customerAuthConstant.CLEAR_MESSAGES });
};
