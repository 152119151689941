// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.autocomplete-input {
  border-radius: 50px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 25rem;
  height: 2rem;
  flex-shrink: 0;
  padding: 1rem;
  border: none; }

.search {
  display: flex;
  justify-content: center;
  align-items: center; }

ul.options {
  display: block;
  list-style: none;
  width: 30rem;
  transition: width 0.3s;
  margin: auto;
  position: absolute; }

ul.options li {
  display: block;
  background: white;
  padding: 10px;
  font-size: 1.2rem;
  width: 100%; }

ul.options li:hover {
  font-weight: bold;
  color: #000;
  cursor: pointer;
  transition: 0.3s all; }

ul.options li.option-active {
  font-weight: bold;
  background: whitesmoke;
  color: #000; }

.no-options {
  color: white; }
`, "",{"version":3,"sources":["webpack://./src/components/Autocomplete/Autocomplete.scss"],"names":[],"mappings":"AAAA;EACI,mBAAmB;EACnB,gBAAgB;EAChB,+CAA+C;EAC/C,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,aAAa;EACb,YAAY,EAAA;;AAGhB;EACI,aAAa;EACb,uBAAuB;EACvB,mBAAmB,EAAA;;AAIrB;EACE,cAAc;EACd,gBAAgB;EAChB,YAAY;EACZ,sBAAsB;EACtB,YAAY;EACZ,kBAAkB,EAAA;;AAGpB;EACE,cAAc;EACd,iBAAiB;EACjB,aAAa;EACb,iBAAiB;EACjB,WAAW,EAAA;;AAEb;EACE,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,oBAAoB,EAAA;;AAGtB;EACE,iBAAiB;EACjB,sBAAsB;EACtB,WAAW,EAAA;;AAEb;EACE,YAAY,EAAA","sourcesContent":[".autocomplete-input{\n    border-radius: 50px;\n    background: #FFF;\n    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);\n    width: 25rem;\n    height: 2rem;\n    flex-shrink: 0;\n    padding: 1rem;\n    border: none;\n}\n\n.search{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n  \n  ul.options {\n    display: block;\n    list-style: none;\n    width: 30rem;\n    transition: width 0.3s;\n    margin: auto;\n    position: absolute;\n  }\n  \n  ul.options li {\n    display: block;\n    background: white;\n    padding: 10px;\n    font-size: 1.2rem;\n    width: 100%;\n  }\n  ul.options li:hover {\n    font-weight: bold;\n    color: #000;\n    cursor: pointer;\n    transition: 0.3s all;\n  }\n  \n  ul.options li.option-active {\n    font-weight: bold;\n    background: whitesmoke;\n    color: #000;\n  }\n  .no-options {\n    color: white;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
