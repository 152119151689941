import React from "react";
import "./ProductSlider.scss";
import Slider from "react-slick";

const ProductSlider = (props) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    slickNext: true,
    autoplay: true,
    autoplaySpeed: props.autoSpeed,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="productslider">
        <Slider {...settings}>{props.children}</Slider>
      </div>
    </>
  );
};

export default ProductSlider;
