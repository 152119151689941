import styled from "@emotion/styled";
import { Menu } from "@mui/material";
import { Link } from "react-router-dom";

export const ChatStyled = styled.div`
  &,
  & > * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  height: calc(100vh - 67px);
  height: calc(100svh - 67px);
  display: grid;
  grid-template-columns: 37rem 1fr;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    position: relative;
  }
`;

export const LeftChatStyled = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: calc(100vh - 67px);
  height: calc(100svh - 67px);
  border-right: 0.1rem solid rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 800px) {
    position: absolute;
    transition: left 0.2s ease-in-out;
    will-change: left;
    left: ${(p) => (p.chatSelected ? "calc(-100% - 50px)" : "0px")};
    width: 100%;
    z-index: 2;
    background-color: #fff;
  }

  & > .input {
    padding: 1.9rem;
  }

  & > .group {
    height: 100%;
    overflow-y: auto;

    & > p {
      padding: 0 2.2rem;
    }
  }
`;

export const SearchFormStyled = styled.form`
  position: relative;

  & > input {
    outline: none;
    background-color: #d9d9d9;
    border-radius: 100rem;
    padding: 1rem 4.2rem 1rem 2.2rem;
    font-size: 1.6rem;
    font-family: "Montserrat", sans-serif;
    border: none;
    width: 100%;
  }

  & > button {
    position: absolute;
    top: 50%;
    right: 1.2rem;
    transform: translate(0, -50%);
    display: grid;
    place-items: center;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 2.4rem;
  }
`;

export const MessageProfileCardStyled = styled(Link)`
  background-color: ${(p) => p.selected && "#ffc941"};
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: ${(p) => (p.selected ? "#ffc941" : "#ffc9416f")};
  }

  padding: 1rem;

  display: grid;
  ${(p) =>
    p.numberOfMessages === 0
      ? `
     grid-template-columns : auto 1fr;
  `
      : `
  grid-template-columns : auto 1fr auto;
  `}

  align-items: center;
  gap: 1.3rem;

  & > img {
    width: 4.1rem;
    height: 4.1rem;
    border-radius: 50%;
    object-fit: cover;
  }

  & > div {
    display: grid;
    align-items: center;

    & > span.name {
      font-size: 1.4rem;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      font-weight: 500;
    }

    & > span.role {
      font-size: 1rem;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  & > .counts {
    display: block;
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background-color: #f23d33;
    font-size: 1.4rem;
    color: #fff;
    font-family: "Montserrat", sans-serif;
  }
`;

export const RightChatStyled = styled.div`
  height: calc(100vh - 67px);
  height: calc(100svh - 67px);
  overflow: auto;

  display: grid;
  grid-template-rows: 6.5rem calc(100vh - 67px - 6.5rem - 5.4rem) 5.4rem;
`;

export const TopBarStyled = styled.div`
  background-color: #000;
  padding: 1.2rem 1.6rem;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1.3rem;
    align-items: center;

    & > a {
      display: none;
    }

    @media screen and (max-width: 800px) {
      grid-template-columns: auto auto 1fr;

      & > a {
        display: grid;
        place-items: center;
        background-color: transparent;
        color: #fff;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 2.4rem;
        transition: opacity 0.2s ease-in-out;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    & > img {
      width: 4.1rem;
      height: 4.1rem;
      object-fit: cover;
      border-radius: 100%;
    }

    & > span {
      font-size: 1.4rem;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      font-weight: 500;
      color: #fff;
    }
  }

  & > button {
    display: grid;
    place-items: center;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 2.4rem;
    transition: opacity 0.2s ease-in-out;
    color: #fff;

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const MoreOptionsMenuStyled = styled(Menu)`
  & > .MuiMenu-paper > .MuiMenu-list {
    padding: 0.8rem 0;
    background-color: #2b2a2a;

    & > button {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 0.8rem 1.2rem;
      font-weight: 500;
      color: #fff;

      &:hover {
        background-color: #ffc84133;
      }
    }
  }
`;

export const MessagesStyled = styled.div`
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.3rem;
  align-content: start;
  height: 100%;
  overflow: auto;
`;

export const MessageCardStyled = styled.p`
  background-color: ${(p) => (p.me ? "#12983f" : "#D9D9D9")};
  color: ${(p) => (p.me ? "#fff" : "#000")};
  margin-left: ${(p) => (p.me ? "auto" : "0")};
  padding: 1.2rem;
  padding-bottom: 3rem;
  border-radius: 0.8rem;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  max-width: 60%;
  min-width: 15rem;
  width: fit-content;
  position: relative;

  & > .date-time {
    position: absolute;
    bottom: 1.2rem;
    right: 1.2rem;
    font-size: 1rem;
    color: ${(p) => (p.me ? "#fff" : "#000")};
    opacity: 0.7;
  }
`;

export const TypeMessageStyled = styled.form`
  padding: 0 2rem 1.2rem;

  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1.6rem;
  align-items: end;

  & > input {
    width: 100%;
    padding: 1.2rem 2rem;
    background-color: #f2f1f1;
    border: #dfdfdf;
    border-radius: 1.2rem;
    outline: none;
    font-size: 1.4rem;
    font-family: "Montserrat", sans-serif;
  }

  & > button {
    background-color: #12983f;
    color: #ffc841;
    place-items: center;
    display: grid;
    height: 4.2rem;
    width: 4.2rem;
    border-radius: 1.2rem;
    border: none;
    outline: none;
    font-size: 2.4rem;
    cursor: pointer;

    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.5;
    }
  }
`;

export const NoChatSelectedStyled = styled.div`
  display: grid;
  place-items: center;
  height: calc(100vh - 67px);
  height: calc(100svh - 67px);
`;

export const CenteredLoaderStyled = styled.div`
  display: grid;
  place-items: center;
  max-height: 20rem;
`;
