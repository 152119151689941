import {
  backdrop,
  BalloonDisplays,
  BarHire,
  BigLetters,
  BouncyCastle,
  Cakes,
  CandyFloss,
  ChocolateFountain,
  Confectionary,
  DJ,
  Gifts,
  Venue,
  Flowers,
  Games,
  Karaoke,
  Lighting,
  Marquee,
  PhotoBooth,
  Photographer,
  Popcorn,
  Singer,
  Slushie,
  SoftPlayUnit,
  SweetCarts,
  TableandChairHire,
  Videographer,
  WeddingFavours,
  WeddingStage,
  rentacar,
} from "../assests";

export const servicesData = [
  {
    id: 1,
    name: "Back Drop",
    to: "/backdrop",
    image: backdrop,
  },
  {
    id: 2,
    name: "Ballon Displays",
    to: "/ballon",
    image: BalloonDisplays,
  },
  {
    id: 3,
    name: "Bar Hire",
    to: "/barhire",
    image: BarHire,
  },
  {
    id: 5,
    name: "Big Letters",
    to: "/bigletters",
    image: BigLetters,
  },
  {
    id: 6,
    name: "Bouncy Castle",
    to: "/bouncycastle",
    image: BouncyCastle,
  },
  {
    id: 7,
    name: "Cakes",
    to: "/cakes",
    image: Cakes,
  },
  {
    id: 8,
    name: "Candy Floss",
    to: "/candyfloss",
    image: CandyFloss,
  },
  {
    id: 9,
    name: "Chocolate Fountain",
    to: "/chocolatefountain",
    image: ChocolateFountain,
  },
  {
    id: 10,
    name: "Confectionary",
    to: "/confectionary",
    image: Confectionary,
  },
  {
    id: 11,
    name: "DJ",
    to: "/dj",
    image: DJ,
  },
  {
    id: 12,
    name: "Flowers",
    to: "/flowers",
    image: Flowers,
  },
  {
    id: 13,
    name: "Games",
    to: "/games",
    image: Games,
  },

  {
    id: 14,
    name: "Karaoke",
    to: "/karaoke",
    image: Karaoke,
  },
  {
    id: 15,
    name: "Lighting",
    to: "/lighting",
    image: Lighting,
  },
  {
    id: 16,
    name: "Marquee",
    to: "/marquee",
    image: Marquee,
  },
  {
    id: 17,
    name: "Photo Booth",
    to: "/photobooth",
    image: PhotoBooth,
  },
  {
    id: 18,
    name: "Photographer",
    to: "/photographer",
    image: Photographer,
  },
  {
    id: 19,
    name: "Popcorn",
    to: "/popcorn",
    image: Popcorn,
  },
  {
    id: 20,
    name: "Singer",
    to: "/singer",
    image: Singer,
  },
  {
    id: 21,
    name: "Slushie",
    to: "/slushie",
    image: Slushie,
  },
  {
    id: 22,
    name: "Soft Play Unit",
    to: "/softplay",
    image: SoftPlayUnit,
  },
  {
    id: 23,
    name: "Sweet Carts",
    to: "/sweetcarts",
    image: SweetCarts,
  },
  {
    id: 24,
    name: "Table and Chair Hire",
    to: "/tablechaire",
    image: TableandChairHire,
  },
  {
    id: 25,
    name: "Videographer",
    to: "/videographer",
    image: Videographer,
  },
  {
    id: 26,
    name: "Wedding Favours",
    to: "/weddingfavours",
    image: WeddingFavours,
  },
  {
    id: 27,
    name: "Wedding Stage",
    to: "/weddingStage",
    image: WeddingStage,
  },

  {
    id: 28,
    name: "Gifts",
    to: "/gifts",
    image: Gifts,
  },
  {
    id: 29,
    name: "Venue",
    to: "/venue",
    image: Venue,
  },
  {
    id: 30,
    name: "Rent A Car",
    to: "/rentacar",
    image: rentacar,
  },
];
