export { default as HomePage } from "./homePage/HomePage";
export { default as SupportPage } from "./supportPage/SupportPage";
export { default as Faq } from "./faq/Faq";
export { default as AboutUsPage } from "./aboutUsPage/AboutUsPage";
export { default as SearchPage } from "./searchPage/SearchPage";
export { default as ViewCategory } from "./viewCategory/ViewCategory";
export { default as ViewServices } from "./viewServices/ViewServices";
export { default as EventDetail } from "./eventDetail/EventDetail";
export { default as VenderShop } from "./venderShop/VenderShop";
export { default as CustomerSignUp } from "./customerSignUp/CustomerSignUp";
export { default as CustomerLogin } from "./customerLogin/CustomerLogin";
export { default as ChangePassword } from "./changepassword/ChangePassword";
export { default as ForgotPassword } from "./forgotpassword/ForgotPassword";
export { default as ResetPassword } from "./resetpassword/ResetPassword";

// New Pages

export { default as CookiePolicy } from "./cookiePolicy/CookiePolicy";
export { default as PrivacyPolicy } from "./privacyPolicy/PrivacyPolicy";
export { default as TcForCustomer } from "./tcForCustomer/TcForCustomer";
export { default as TcForSupplier } from "./tcForSupplier/TcForSupplier";

export { default as SearchResult } from "./SearchResult/SearchResult";
