import React from "react";
import "./HowWorks.scss";
import Container from "../container/Container";
import Heading from "../heading/Heading";
import { HiSearch } from "react-icons/hi";
import { MdOutlineCelebration, MdOutlineSpeakerNotes } from "react-icons/md";

const HowWorks = () => {
  return (
    <>
      <div className="howit">
        <Heading className="primary center uppercase">How it works</Heading>
        <Container className="md">
          <Item
            icon={<HiSearch />}
            heading="Explore"
            content="Begin your event planning journey by specifying the occasion and location, whether it's a wedding, birthday, or any special event. A simple click of 'Search' and you'll instantly discover a variety of matching services and products. "
          />
          <Item
            icon={<MdOutlineSpeakerNotes />}
            heading="Connect"
            content="Connecting with vendors is a breeze. Utilize the contact form on their profile to share event details and preferences. Vendors respond promptly, providing the information you need.  "
          />
          <Item
            icon={<MdOutlineCelebration />}
            heading="Select"
            content="Review vendor responses and make selections that best align with your event needs. Once you've made your choice, let the vendor take it from there, and you're on your way to planning a memorable event, whether it's a wedding, birthday celebration, or any special occasion."
          />
        </Container>
      </div>
    </>
  );
};

export default HowWorks;

const Item = (props) => {
  return (
    <>
      <div className="workitem">
        <div className="workitem-container">
          <div className="workitem-container-icon">{props.icon}</div>
          <div className="workitem-container-border"></div>
          <div className="workitem-container-content">
            <h3>{props.heading}</h3>
            <p>{props.content}</p>
          </div>
        </div>
      </div>
    </>
  );
};
