export const chatContants = {
  GET_ALL_CHATS: {
    pending: "GET_ALL_CHATS_REQUEST",
    success: "GET_ALL_CHATS_SUCCESS",
    error: "GET_ALL_CHATS_FAILURE",
  },
  POST_NEW_CHAT: {
    pending: "POST_NEW_CHAT_REQUEST",
    success: "POST_NEW_CHAT_SUCCESS",
    error: "POST_NEW_CHAT_FAILURE",
  },
  POST_CHATS_WITH_ADMINS: {
    pending: "POST_CHATS_WITH_ADMINS_REQUEST",
    success: "POST_CHATS_WITH_ADMINS_SUCCESS",
    error: "POST_CHATS_WITH_ADMINS_FAILURE",
  },

  UPDATE_MESSAGE: "UPDATE_MESSAGE",
};
