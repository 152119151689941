import { NotificationConstants } from "./../constants";

const initialState = {
  allNotifications: [],
};

const notificationReducer = (s = initialState, action) => {
  const state = { ...s };

  switch (action.type) {
    case NotificationConstants.UPDATE_NOTIFICATION:
      state.allNotifications = action.payload;
      break;

    default:
      break;
  }

  return state;
};

export default notificationReducer;
