export const userContants = {
  GET_USER_DETAILS_2: {
    pending: "GET_USER_DETAILS_2_REQUEST",
    success: "GET_USER_DETAILS_2_SUCCESS",
    error: "GET_USER_DETAILS_2_FAILURE",
  },

  RE_GET_USER_DETAILS_2: {
    pending: "RE_GET_USER_DETAILS_2_REQUEST",
    success: "RE_GET_USER_DETAILS_2_SUCCESS",
    error: "RE_GET_USER_DETAILS_2_FAILURE",
  },
};
