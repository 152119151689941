import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { useQueryParams } from "../../hooks";

function ProtectedRouteForCustomer() {
  const isLoggedIn = sessionStorage.getItem("userToken");
  const { next } = useQueryParams({
    next: "",
  });

  return isLoggedIn ? (
    <Navigate to={next || "/customer-dashboard"} replace />
  ) : (
    <Outlet />
  );
}

ProtectedRouteForCustomer.propTypes = {
  allowedRole: PropTypes.string,
};

export default ProtectedRouteForCustomer;
