import React, { useEffect, useState } from "react";

// Library
import { Formik, Form } from "formik";
import * as Yup from "yup";
//Components
import {
  Button,
  Container,
  FormInput,
  FormText,
  Heading,
  GoBackBtn,
} from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearErrors,
  clearMessages,
  sendSupportForm,
} from "../../../store/actions";
import { toast } from "react-hot-toast";
import { Puff } from "react-loader-spinner";

const SupportPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Required"),
    subject: Yup.string()
      .min(2, "Name must be 2 character")
      .max(20, "Must be 35 characters or less")
      .required("Required"),
    message: Yup.string()
      .min(6, "Message have at least 6 charaters")
      .max(100, "Message must be 100 character or less.")
      .required("Required"),
  });
  return (
    <>
      {loading ? (
        <Puff
          height="60"
          width="60"
          radius="6"
          color="black"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
        />
      ) : (
        <div className="m-6">
          <Container className="main">
            <Container className="lg">
              <GoBackBtn className="m-4" />
            </Container>
          </Container>

          <Container className="main">
            <Container className="extra-small">
              <Heading className="center secondry m-4">
                Occasion Base Support
              </Heading>
              <div className="support-form">
                <Formik
                  initialValues={{
                    email: "",
                    subject: "",
                    message: "",
                  }}
                  validationSchema={validate}
                  onSubmit={({ email, subject, message }) => {
                    dispatch(
                      sendSupportForm(
                        {
                          email,
                          subject,
                          message,
                        },
                        () => {
                          toast.success("Submitted Successfully!");
                          setLoading(false);
                          navigate("/");
                        },
                        () => {
                          toast.success("Something went wrong!");
                          setLoading(false);
                        }
                      )
                    );
                    setLoading(true);
                  }}
                >
                  {(formik) => (
                    <Form>
                      <FormInput label="Email" name="email" type="email" />
                      <FormInput label="Subject" name="subject" type="text" />
                      <FormText
                        label={"Message"}
                        name="message"
                        cols="70"
                        rows="24"
                      />

                      <div className="support-form-btn">
                        <Button className="uppercase rounded center m-3">
                          Submit
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Container>
          </Container>
        </div>
      )}
    </>
  );
};

export default SupportPage;
