// Upgrade Plain

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./UpgradePlain.scss";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import { Selector } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  AddMemberShipSubscription,
  AddPaymentCardBeforeSubscription,
  clearErrors,
  clearMessages,
  getCouponDetails,
  GetMemberShipSubscription,
  GetPaymentCard,
  updateVendorMembership,
} from "../../../../store/actions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Puff } from "react-loader-spinner";
import {
  couponFormSchema,
  postalFormSchema,
  subscriptionFormSchema,
} from "../../../../schema";
import { getMonthsFromToday } from "../../../../utils";
import { Modal } from "@mui/material";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";
import styled from "@emotion/styled";
import AutoCompleteNew from "../../../../components/AutoCompleteNew/AutoCompleteNew";
import useUser from "../../../../hooks/useUser";
import { reGetUserDetails2 } from "../../../../store/actions/user.action";
import { MemberShipCard } from "../manageMembership/MembershipCard";
import { subscriptionData } from "../manageMembership/ManageMembership";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const intialCouponDetails = {
  startAt: new Date().toLocaleDateString(),
  percentageOff: 0,
  appliedCouponId: "",
};

const CheckoutPage = () => {
  const {
    getCardsDetails,
    errors,
    message,
    loading2,
    subscriptionLoading,
    details: subscriptionDetails,
    loading: subcriptionsLoading,
  } = useSelector((state) => state.vendorReducer);

  const { cardDetails, loading } = useMemo(
    () => ({
      loading: getCardsDetails.loading,
      cardDetails: getCardsDetails.data,
    }),
    [getCardsDetails]
  );

  const [details, setDetails] = useState({});
  const { getUserDetails2Data } = useSelector((s) => s.userReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const { search } = useLocation();
  const queryParams = useMemo(() => {
    const query = new URLSearchParams(search);
    return {
      name: query.get("name"),
    };
  }, [search]);

  const selectedSubscription = useMemo(() => {
    return subscriptionDetails?.find((sub) => sub?.name === queryParams.name);
  }, [subscriptionDetails, queryParams.name]);

  const selectedSubscriptionData = useMemo(() => {
    return subscriptionData.find((s) => s.name === queryParams.name);
  }, [queryParams.name]);

  const [couponDetails, setCouponDetails] = useState(intialCouponDetails);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [ispostalvalid, setispostalvalid] = useState(false);
  const [downgradeExceedErrorModalOpen, setDowngradeExceedErrorModalOpen] =
    useState(false);

  const user = useUser();

  const couponFormik = useFormik({
    initialValues: {
      couponId: "",
    },
    validationSchema: couponFormSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(
        getCouponDetails(
          values.couponId,
          (data) => {
            if (data?.productId === selectedSubscription._id) {
              setCouponDetails({
                appliedCouponId: data._id,
                percentageOff: data?.percent_off || 0,
                startAt: getMonthsFromToday(data?.duration_in_months || 0),
              });
            } else {
              setCouponDetails(intialCouponDetails);
              toast.error(
                `You can't use this promo code for ${queryParams.name} subscription.`
              );
            }
          },
          () => {
            setCouponDetails(intialCouponDetails);
          }
        )
      );
    },
  });

  const subscriptionFormik = useFormik({
    initialValues: {
      addressLine1: "",
      cityTown: "",
      postalCode: "",
      phoneNumber: "",
      houesNo: "",
      loading: false,
    },
    validationSchema: subscriptionFormSchema,
    onSubmit: async (v, { setFieldValue }) => {
      const addSubscription = () => {
        setFieldValue("loading", true);
        const f1 = user?.subscription
          ? updateVendorMembership
          : AddMemberShipSubscription;

        const values = {
          ...v,
          addressLine1: `${v.houesNo}, ${v.addressLine1}`,
        };
        delete values.houesNo;
        delete values.loading;
        dispatch(
          f1(
            {
              priceId: selectedSubscription.priceId,
              coupon: couponDetails.appliedCouponId || undefined,
              ...values,
            },
            () => {
              setFieldValue("loading", false);
              reGetUserDetails2({
                onSuccess: () => {
                  setFieldValue("loading", false);
                },
                onError: () => {
                  setFieldValue("loading", false);
                },
              });
            },
            (error) => {
              if (error.response?.data?.errors[0]?.message === "E_353") {
                setIsConfirmModalOpen(false);
                setDowngradeExceedErrorModalOpen(true);
              } else toast.error(error.response?.data?.errors[0]?.message);
              setFieldValue("loading", false);
            }
          )
        );
      };

      if (details?.last4 || selectedSubscription?.price === 0) {
        addSubscription();
        return;
      }

      if (!stripe || !elements) {
        toast.error("Stripe library hasn't loaded yet! Please try again.");
        setIsConfirmModalOpen(false);
        return;
      }

      const cardElement = elements.getElement(CardElement);
      const { token, error } = await stripe.createToken(cardElement);

      if (error) {
        toast.error(
          "Something went wrong with token creation. Please try again!"
        );
        setIsConfirmModalOpen(false);
        return;
      }
      dispatch(
        AddPaymentCardBeforeSubscription({ id: token.id }, () => {
          addSubscription();
        })
      );
    },
  });

  const postalFormik = useFormik({
    initialValues: {
      postalCode: "",
      loading: false,
    },
    validationSchema: postalFormSchema,
    onSubmit: async (values, { setFieldValue }) => {
      if (ispostalvalid) {
        setispostalvalid(false);
        return;
      }

      setFieldValue("loading", true);

      if (!details?.last4 && selectedSubscription?.price !== 0) {
        const { error } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
        });

        if (error) {
          toast.error(error?.message);
          setFieldValue("loading", false);
          return;
        }
      }

      const {
        data: {
          results: [postal_details],
          status,
        },
      } = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${values.postalCode}|country:UK&key=AIzaSyA1OKBW722GCRyhTTYsQkM6F4mwBLV5eKY`
      );
      if (status === "OK") {
        const { postcode_localities: localities, address_components } =
          postal_details;
        const setFieldValue = subscriptionFormik.setFieldValue;

        for (const obj of address_components) {
          const { long_name, types } = obj;

          if (types.includes("postal_code")) {
            setFieldValue("postalCode", long_name);
          } else if (types.includes("postal_town"))
            setFieldValue("cityTown", long_name);
          else if (types.includes("route"))
            setFieldValue("addressLine1", long_name);
        }

        localities && setFieldValue("addressLine1", localities[0]);
        setispostalvalid(true);
      } else {
        toast.error("Please enter a valid postal code");
      }
      setFieldValue("loading", false);
    },
  });

  useEffect(() => {
    if (!queryParams.name) {
      navigate("/manage-membership", { replace: true });
      toast.error("You are accessing this page in forbidden way.");
      return;
    }
    dispatch(GetPaymentCard());
    dispatch(GetMemberShipSubscription());
  }, [dispatch, navigate, queryParams]);

  useEffect(() => {
    if (!ispostalvalid) {
      subscriptionFormik.resetForm();
    }
  }, [ispostalvalid]);

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
      setIsConfirmModalOpen(false);
    }
    if (message !== "") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/vender-dashboard"), 2000);
      setIsConfirmModalOpen(false);
    }
  }, [dispatch, errors, message, navigate]);

  useEffect(() => {
    const mainCard = cardDetails.find((card) => {
      return card.isDefault;
    });
    setDetails(mainCard);
  }, [cardDetails]);

  return loading || subcriptionsLoading || !Boolean(selectedSubscription) ? (
    <div className="center-loader-nlkj21j3">
      <Puff
        height="60"
        width="60"
        radius="6"
        color="black"
        ariaLabel="loading"
        wrapperStyle
        wrapperClass
      />
    </div>
  ) : (
    <>
      <div className="unqiue-id-aslkdjf2325k2j">
        <div className="top">
          <div className="box-2">
            <h2>Account Details</h2>
            <div className="box">
              <div>
                <h6>Email</h6>
                <p>{getUserDetails2Data?.data?.email || "--"}</p>
              </div>
              <div>
                <h6>Full Name</h6>
                <p>{getUserDetails2Data?.data?.fullName || "--"}</p>
              </div>
              {selectedSubscription?.price !== 0 &&
                (details?.last4 ? (
                  <>
                    <div>
                      <h6>Card Number</h6>
                      <p>**** **** **** {details?.last4 || "****"}</p>
                    </div>
                    <div>
                      <h6>CVC</h6>
                      <p>***</p>
                    </div>
                  </>
                ) : (
                  <PaymentForm disabled={ispostalvalid} />
                ))}
              <form onSubmit={postalFormik.handleSubmit}>
                <InputField
                  label="Postal Code"
                  type="text"
                  placeholder="Enter Postal Code"
                  onChange={postalFormik.handleChange}
                  onBlur={postalFormik.handleBlur}
                  value={postalFormik.values.postalCode}
                  error={
                    postalFormik.touched.postalCode &&
                    postalFormik.errors.postalCode
                  }
                  name="postalCode"
                  disabled={ispostalvalid}
                />
                <button
                  style={{ marginTop: "2rem" }}
                  className="button-9274982734987324afasdf"
                  type="submit"
                  disabled={loading2 || postalFormik.values.loading}
                >
                  {ispostalvalid
                    ? "Edit"
                    : loading2 || postalFormik.values.loading
                    ? "Checking..."
                    : "Apply"}
                </button>
              </form>

              {ispostalvalid && (
                <>
                  <InputField
                    label="House No/ Name"
                    type="text"
                    onChange={subscriptionFormik.handleChange}
                    onBlur={subscriptionFormik.handleBlur}
                    value={subscriptionFormik.values.houesNo}
                    error={
                      subscriptionFormik.touched.houesNo &&
                      subscriptionFormik.errors.houesNo
                    }
                    name="houesNo"
                  />

                  <InputField
                    label="Address Line 1"
                    type="text"
                    placeholder="Address Line 1"
                    onChange={subscriptionFormik.handleChange}
                    onBlur={subscriptionFormik.handleBlur}
                    value={subscriptionFormik.values.addressLine1}
                    error={
                      subscriptionFormik.touched.addressLine1 &&
                      subscriptionFormik.errors.addressLine1
                    }
                    name="addressLine1"
                  />

                  <InputField
                    label="City/Town"
                    type="text"
                    onChange={subscriptionFormik.handleChange}
                    onBlur={subscriptionFormik.handleBlur}
                    value={subscriptionFormik.values.cityTown}
                    error={
                      subscriptionFormik.touched.cityTown &&
                      subscriptionFormik.errors.cityTown
                    }
                    name="cityTown"
                  />
                  <InputField
                    label="Phone Number"
                    type="text"
                    placeholder="Phone Number"
                    onChange={subscriptionFormik.handleChange}
                    onBlur={subscriptionFormik.handleBlur}
                    value={subscriptionFormik.values.phoneNumber}
                    error={
                      subscriptionFormik.touched.phoneNumber &&
                      subscriptionFormik.errors.phoneNumber
                    }
                    name="phoneNumber"
                  />
                </>
              )}
            </div>
          </div>

          <div className="box-4">
            <h2>Membership details</h2>
            <div className="box">
              <div className="row">
                {selectedSubscription && (
                  <MemberShipCard
                    className={`bg-${selectedSubscription?.name}`}
                    name={selectedSubscription?.name}
                    desc={`(${selectedSubscriptionData.description})`}
                    price={selectedSubscription.price}
                    noButton
                    coupon={
                      selectedSubscription.coupons &&
                      selectedSubscription.coupons[0]
                    }
                    features={[
                      selectedSubscriptionData?.services[0],
                      `Advertise up to ${
                        selectedSubscription?.services
                      } service${
                        selectedSubscription?.services > 1 ? "s" : ""
                      }`,
                      ...selectedSubscriptionData?.services.slice(1),
                    ]}
                    color={selectedSubscriptionData?.color}
                  ></MemberShipCard>
                )}
              </div>

              <div className="row">
                <h6>Membership Selected:</h6>
                <span>{queryParams.name}</span>
                <h6>Total Amount:</h6>
                <span>
                  £{" "}
                  {(
                    selectedSubscription.price -
                    (couponDetails.percentageOff / 100) *
                      selectedSubscription.price
                  ).toFixed(2)}
                  {couponDetails.percentageOff !== 0
                    ? ` (${couponDetails.percentageOff}% off)`
                    : ""}
                </span>

                <p>
                  You will be charged £{selectedSubscription.price} per month
                  starting from {couponDetails?.startAt}
                </p>

                <form className="bottom" onSubmit={couponFormik.handleSubmit}>
                  <div className="top">
                    <InputField
                      style={{ fontSize: "1.5rem" }}
                      label="Promo Code"
                      placeholder="Enter Promo Code"
                      name="couponId"
                      onChange={couponFormik.handleChange}
                      onBlur={couponFormik.handleBlur}
                      value={couponFormik.values.couponId}
                    />
                  </div>
                  <div className="bottom">
                    <button
                      className="button-9274982734987324afasdf"
                      type="submit"
                      disabled={
                        !couponFormik.isValid || !couponFormik.dirty || loading2
                      }
                    >
                      {loading2 ? "Checking..." : "Apply"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom">
          <button
            type="submit"
            className="button-9274982734987324afasdf"
            onClick={() => setIsConfirmModalOpen(true)}
            disabled={
              subscriptionLoading ||
              !subscriptionFormik.isValid ||
              !subscriptionFormik.dirty
            }
          >
            {subscriptionLoading ? "Subscribing..." : "Confirm Payment"}
          </button>
        </div>
      </div>

      <ConfirmationModal
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirmation={() => subscriptionFormik.handleSubmit()}
        disabled={
          subscriptionLoading ||
          !subscriptionFormik.isValid ||
          !subscriptionFormik.dirty ||
          subscriptionFormik.values.loading
        }
      />

      <DowngradeExceedErrorModal
        open={downgradeExceedErrorModalOpen}
        onClose={() => setDowngradeExceedErrorModalOpen(false)}
      />
    </>
  );
};

const CheckoutFormWithStripeWrapper = () => (
  <Elements stripe={stripePromise}>
    <CheckoutPage />
  </Elements>
);

export default CheckoutFormWithStripeWrapper;

export const InputField = ({ label, error, customerInput, ...rest }) => {
  return (
    <label className="InputField-2349823981nidsfwe">
      <h6>{label}</h6>
      <div>
        {customerInput || <input {...rest} />}
        {error && <p className="error">{error}</p>}
      </div>
    </label>
  );
};

const PaymentForm = ({ disabled }) => {
  return (
    <label className="InputField-2324232098238">
      <h6>Card Detail</h6>
      <div>
        <CardElement
          className="stripe-input-23423824sd"
          options={{ hidePostalCode: true, disableLink: true, disabled }}
        />
      </div>
    </label>
  );
};

const ConfirmationModal = ({ open, onClose, onConfirmation, disabled }) => {
  return (
    <Modal
      open={open}
      onClose={!disabled && onClose}
      onConfirmation={onConfirmation}
      className="confirmation-modal-lksadjf239jf2"
    >
      <div className="confirmation-modal-239jv2">
        <div className="top">
          <h6>Confirm Payment</h6>
          <p>Are you sure you want to purchase this item?</p>
        </div>
        <div className="bottom">
          <button
            type="button"
            onClick={onConfirmation}
            className="button-9274982734987324afasdf"
            disabled={disabled}
          >
            {disabled ? "Subscribing..." : "Yes"}
          </button>
          <button
            onClick={onClose}
            className="button-sdkjfkj2939j3f23"
            disabled={disabled}
            type="button"
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};

const DowngradeExceedErrorModal = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      className="confirmation-modal-lksadjf239jf2"
    >
      <div className="confirmation-modal-239jv2">
        <div className="top">
          <h6>Downgrade Error</h6>
          <p>
            We regret to inform you that your plan cannot be downgraded at this
            time due to an excess of created services exceeding the limitations
            of the plan you are attempting to purchase. Should you wish to
            proceed with purchasing this plan, we kindly advise you to first
            delete any surplus services, thereby bringing your service count
            within the acceptable range for downgrading. Alternatively, you may
            choose to maintain your current plan.
          </p>
        </div>
        <div className="bottom">
          <button
            type="submit"
            className="button-9274982734987324afasdf"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

const AutoCompleteStyled = styled(AutoCompleteNew)`
  width: 100%;
  outline: none;
  border-radius: 2.9rem;
  border: none;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
  color: #575757;
  font-family: Montserrat;
  font-size: 1.7825rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 2rem 2.1rem;
  box-sizing: border-box;
`;
