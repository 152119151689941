import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { logo } from "../../../assests";
import { Heading, FormInput, Button } from "../../../components";
import toast from "react-hot-toast";
import {
  Forgotpassword,
  clearErrors,
  clearMessages,
} from "./../../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const ForgotPasswordComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const { message, errors, loading } = useSelector(
    (state) => state.vendorAuthReducer
  );
  const { id } = useParams();
  const validation = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
  });

  let resetPasswordData = { email: resetPasswordEmail, role: id };

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(
        () => navigate("/reset-password", { state: resetPasswordData }),
        2000
      );
    }
  }, [errors, message]);

  return (
    <>
      <div className="changepassword">
        <div className="changepassword-container">
          <img
            src={logo}
            alt="logo"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          />
          <Heading className="secondry center m-1">Forgot password</Heading>

          <div className="m-2">
            <Formik
              initialValues={{
                email: "",
              }}
              validateOnMount
              validationSchema={validation}
              onSubmit={(values) => {
                values["role"] = id;
                setResetPasswordEmail(values.email);
                dispatch(Forgotpassword(values));
              }}
            >
              {(formik) => (
                <div className="m-3">
                  <Form>
                    <FormInput
                      place="Enter email to reset password"
                      name="email"
                      type="email"
                    />
                    <div className="save-btn">
                      <Button
                        className="rounded center m-2"
                        disabled={loading ? true : false}
                      >
                        {loading ? "Please wait..." : "Get email"}
                      </Button>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordComponent;
