import React, { useEffect, useState } from "react";
import { Container, Heading } from "../../../../components";
import {
  correct_silver,
  correct_gold,
  correct_bronze,
  correct_platinum,
} from "../../../../assests";
import { LineChart } from "@mui/x-charts/LineChart";

import {
  getTotalViews,
  getTotalFavourite,
  clearErrors,
  getCustomerInfo,
} from "./../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import useUser from "../../../../hooks/useUser";
import { CenteredLoaderStyled } from "../../../vender/dashboard/Chat/styles";
import { Puff } from "react-loader-spinner";
const Dashboard = () => {
  const user = useUser();
  const { getCustomerInfoData } = useSelector((s) => s.customerReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allMessages } = useSelector((s) => s.chatReducer);

  useEffect(() => {
    dispatch(getCustomerInfo());
  }, [dispatch]);

  return getCustomerInfoData?.loading ? (
    <CenteredLoaderStyled>
      <Puff
        height="60"
        width="60"
        radius="6"
        color="black"
        ariaLabel="loading"
        wrapperStyle
        wrapperClass
      />
    </CenteredLoaderStyled>
  ) : (
    <>
      <div className="m-5 dashboard-main-container-responsive">
        <Container className="md">
          <Heading className="lighter m-3">
            Welcome Back {user?.fullName || "--"}
            {user?.userId ? ` (${user?.userId})` : ""},
          </Heading>

          <div className="dashboard-cards">
            <DashBoardCard
              heading="Favourite Services"
              description="How many favourite services do you have"
              onClick={() => navigate("/favourite")}
            >
              <h1>{getCustomerInfoData?.data?.likes || 0}</h1>
            </DashBoardCard>
            {/* <DashBoardCard
              heading="Pending Enq"
              description="How many pending request you have"
              onClick={() => navigate("/my-orders")}
            >
              <h1>{getCustomerInfoData?.data?.pendingRequests || 0}</h1>
            </DashBoardCard> */}
            <DashBoardCard
              heading="Unread Messages"
              description="How many unread messages do you have"
              onClick={() => navigate("/chat")}
            >
              <h1>
                {allMessages?.filter(
                  (m) => m.receiverId === user?.id && !m.isSeen
                ).length || 0}
              </h1>
            </DashBoardCard>
            <DashBoardCard
              heading="My Enquiries"
              description="How many enqueries do you have"
              onClick={() => navigate("/my-orders")}
            >
              <h1>
                {(getCustomerInfoData?.data?.pendingRequests || 0) +
                  (getCustomerInfoData?.data?.acceptedRequests || 0) +
                  (getCustomerInfoData?.data?.completedOrders || 0)}
              </h1>
            </DashBoardCard>
            {/* <DashBoardCard
              heading="Ongoing Enqueries"
              description="How many ongoing enqueries do you have"
              onClick={() => navigate("/my-orders")}
            >
              <h1>{getCustomerInfoData?.data?.acceptedRequests || 0}</h1>
            </DashBoardCard>
            <DashBoardCard
              heading="Completed Enqueries"
              description="How many completed enqueries do you have"
              onClick={() => navigate("/my-orders")}
            >
              <h1>{getCustomerInfoData?.data?.completedOrders || 0}</h1>
            </DashBoardCard> */}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;

const DashBoardCard = (props) => {
  return (
    <>
      <div className="dashboardcard" onClick={props.onClick}>
        <div className="dashboardcard-container">
          <h5>{props.heading}</h5>
          <div className="dashboardcard-container-item">{props.children}</div>
          <p>{props.description}</p>
        </div>
      </div>
    </>
  );
};
