import React from "react";
import "./CookiePolicy.scss";
import { Link } from "react-router-dom";
import { Container, Text, Heading } from "../../../components";

const CookiePolicy = () => {
  return (
    <>
      <Container className="main">
        <Container className="md">
          <div className="list-view">
            <Heading className="primary center m-5">Cookie policy</Heading>
            <Text>
              This cookie policy explains how OccasionBase uses cookies and
              other tracking technologies on our website, located at
              <Link to="/"> https://www.occasionbase.com.</Link>
            </Text>
            <div className="m-1" />
            <ol>
              <li>
                <Text>
                  What Are Cookies? Cookies are small data files that are placed
                  on your device when you visit a website. They allow the
                  website to remember your preferences and improve your user
                  experience. Cookies may also be used for advertising purposes.
                </Text>
              </li>
              <li>
                <Text>
                  Types of Cookies We Use We use several types of cookies on our
                  website:
                </Text>
                <ul>
                  <li>
                    <Text>
                      Essential Cookies: These cookies are necessary for the
                      website to function properly.
                    </Text>
                  </li>
                  <li>
                    <Text>
                      Analytics Cookies: These cookies help us analyze how users
                      interact with our website, so we can improve our website
                      and services.
                    </Text>
                  </li>
                  <li>
                    <Text>
                      Advertising Cookies: These cookies are used to display
                      personalized ads based on your interests.
                    </Text>
                  </li>
                </ul>
              </li>
              <li>
                <Text>
                  Third-Party Cookies We may also use third-party cookies on our
                  website, such as those from Google Analytics and Facebook.
                  These third-party cookies are subject to their own privacy
                  policies.
                </Text>
              </li>
              <li>
                <Text>
                  Your Choices You can disable cookies in your web browser
                  settings. However, please note that disabling cookies may
                  affect your user experience on our website. You can also
                  opt-out of personalized advertising by visiting the Digital
                  Advertising Alliance's opt-out page or the Network Advertising
                  Initiative's opt-out page.
                </Text>
              </li>
              <li>
                <Text>
                  Changes to this Policy We may update this Privacy/Cookies
                  Policy from time to time. We encourage you to periodically
                  review this page for the latest information on our privacy and
                  cookies practices. If we make any material changes to this
                  Policy, we will notify you either through the email address
                  you have provided us or by placing a prominent notice on our
                  website.
                </Text>
              </li>
              <li>
                <Text>
                  Contact Us If you have any questions or concerns about our
                  Privacy/Cookies Policy, or if you would like to exercise your
                  rights under applicable data protection laws, please contact
                  us at info@occasionbase.com. We will do our best to respond to
                  your inquiries in a timely and professional manner.
                </Text>
              </li>
            </ol>
            <Text>
              Conclusion At OccasionBase, we are committed to protecting your
              privacy and providing you with a safe and secure online
              experience. We use cookies to enhance your user experience,
              understand your preferences, and improve the quality of our
              services. By using our website, you consent to our use of cookies
              in accordance with this Privacy/Cookies Policy.
            </Text>
          </div>

          <div className="m-5"></div>
          <div className="m-5"></div>
        </Container>
      </Container>
    </>
  );
};

export default CookiePolicy;
