import { vendorServices2Constants } from "./../constants";

const initialState = {
  postVendorServiceData: {
    loading: false,
  },

  getAllVendorServicesData: {
    results: [],
    page: 1,
    limit: 5000,
    totalPages: 0,
    totalResults: 0,
    loading: false,
  },

  patchVendorServiceDeleteData: {
    loading: false,
  },

  patchCancelVendorServiceDeleteData: {
    loading: false,
  },

  getVendorDetailsData: {
    loading: false,
    data: null,
  },

  patchToggleArchivedStatusOfServiceData: { loading: false, data: null },
};

const vendorServices2Reducer = (s = initialState, action) => {
  const state = { ...s };

  switch (action.type) {
    case vendorServices2Constants.POST_VENDOR_SERVICE_2.pending:
      state.postVendorServiceData.loading = true;
      break;

    case vendorServices2Constants.POST_VENDOR_SERVICE_2.success:
    case vendorServices2Constants.POST_VENDOR_SERVICE_2.error:
      state.postVendorServiceData.loading = false;
      break;

    case vendorServices2Constants.GET_ALL_VENDOR_SERVICE_2.pending:
      state.getAllVendorServicesData.loading = true;
      break;

    case vendorServices2Constants.GET_ALL_VENDOR_SERVICE_2.success:
      state.getAllVendorServicesData = { ...action.payload, loading: false };
      break;

    case vendorServices2Constants.GET_ALL_VENDOR_SERVICE_2.error:
      state.getAllVendorServicesData.loading = false;
      break;

    case vendorServices2Constants.PATCH_VENDOR_SERVICE_DELETE_REQUEST_2.pending:
      state.patchVendorServiceDeleteData.loading = true;
      break;

    case vendorServices2Constants.PATCH_VENDOR_SERVICE_DELETE_REQUEST_2.success:
    case vendorServices2Constants.PATCH_VENDOR_SERVICE_DELETE_REQUEST_2.error:
      state.patchVendorServiceDeleteData.loading = false;
      break;

    case vendorServices2Constants.PATCH_CANCEL_VENDOR_SERVICE_DELETE_REQUEST_2
      .pending:
      state.patchCancelVendorServiceDeleteData.loading = true;
      break;

    case vendorServices2Constants.PATCH_CANCEL_VENDOR_SERVICE_DELETE_REQUEST_2
      .success:
    case vendorServices2Constants.PATCH_CANCEL_VENDOR_SERVICE_DELETE_REQUEST_2
      .error:
      state.patchCancelVendorServiceDeleteData.loading = false;
      break;

    case vendorServices2Constants.GET_VENDOR_DETAILS_2.pending:
      state.getVendorDetailsData.loading = true;
      break;

    case vendorServices2Constants.GET_VENDOR_DETAILS_2.success:
      state.getVendorDetailsData.loading = false;
      state.getVendorDetailsData.data = action.payload;
      break;

    case vendorServices2Constants.GET_VENDOR_DETAILS_2.error:
      state.getVendorDetailsData.loading = false;
      break;

    case vendorServices2Constants.PATCH_TOGGLE_ARCHIVED_STATUS_OF_SERVICE
      .pending:
      state.patchToggleArchivedStatusOfServiceData.loading = true;
      break;

    case vendorServices2Constants.PATCH_TOGGLE_ARCHIVED_STATUS_OF_SERVICE
      .success:
      state.patchToggleArchivedStatusOfServiceData.loading = false;
      state.patchToggleArchivedStatusOfServiceData.data = action.payload;
      break;

    case vendorServices2Constants.PATCH_TOGGLE_ARCHIVED_STATUS_OF_SERVICE.error:
      state.patchToggleArchivedStatusOfServiceData.loading = false;
      break;

    default:
      break;
  }

  return state;
};

export default vendorServices2Reducer;
