// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  background-color: #ffc841;
  color: black;
  padding: 0.9rem 2.5rem;
  cursor: pointer;
  border: none;
  transition: 0.3s ease-in-out;
  font-size: 1.5rem;
  font-weight: 600; }
  .button:hover {
    background-color: #cda814; }

.rounded {
  border-radius: 1rem; }

.pill {
  border-radius: 100px; }

.btn-lighter {
  font-weight: 300; }

.btn-lighter-full {
  font-weight: 300;
  width: 100%;
  padding: 2rem 0rem; }

.btn-full {
  width: 100%; }

button:disabled,
button:focus:disabled,
button:hover:disabled,
button:active:disabled {
  background: #ccc;
  border-color: #ccc;
  color: #666666;
  cursor: not-allowed; }
`, "",{"version":3,"sources":["webpack://./src/components/button/Button.scss","webpack://./src/scss/_variable.scss"],"names":[],"mappings":"AACA;EACE,yBCFe;EDGf,YAAY;EACZ,sBAAsB;EACtB,eAAe;EACf,YAAY;EACZ,4BAA4B;EAC5B,iBAAiB;EACjB,gBAAgB,EAAA;EARlB;IAUI,yBCVmB,EAAA;;ADavB;EACE,mBAAmB,EAAA;;AAErB;EACE,oBAAoB,EAAA;;AAGtB;EACE,gBAAgB,EAAA;;AAGlB;EACE,gBAAgB;EAChB,WAAW;EACX,kBAAkB,EAAA;;AAEpB;EACE,WAAW,EAAA;;AAGb;;;;EAIE,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,mBAAmB,EAAA","sourcesContent":["@import \"../../scss/variable\";\n.button {\n  background-color: $primary;\n  color: black;\n  padding: 0.9rem 2.5rem;\n  cursor: pointer;\n  border: none;\n  transition: 0.3s ease-in-out;\n  font-size: 1.5rem;\n  font-weight: 600;\n  &:hover {\n    background-color: $primary-hover;\n  }\n}\n.rounded {\n  border-radius: 1rem;\n}\n.pill{\n  border-radius: 100px;\n}\n\n.btn-lighter {\n  font-weight: 300;\n}\n\n.btn-lighter-full {\n  font-weight: 300;\n  width: 100%;\n  padding: 2rem 0rem;\n}\n.btn-full{\n  width: 100%;\n}\n\nbutton:disabled,\nbutton:focus:disabled,\nbutton:hover:disabled,\nbutton:active:disabled {\n  background: #ccc;\n  border-color: #ccc;\n  color: #666666;\n  cursor: not-allowed;\n}\n","$primary: #ffc841;\n$primary-hover: #cda814;\n\n$light-gray: #f2f1f1;\n$gray: #838282;\n\n$white: #ffffff;\n$sidebar-background: #212121;\n$footer_background: black;\n\n// Typo Graphy\n$heading-primary: #4c4d4e;\n\n$black: black;\n\n$dorger: #e0fff9;\n\n$bronze: #603b30;\n$sliver: #c5c5c5;\n$gold: #c57934;\n$platinium: #2d2d2d;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
