import { useState } from "react";
import "./Modal.scss";
const NewModal = (props) => {
//   const [display, setDisplay] = useState(false);

  return (
    <>
      {props.show ? (
        <div className="modal-overlay"
        
            onClick={(e) =>{e.stopPropagation(); props.setShow(!props.show)}}
        >
          <div className="modal-overlay-index"
          
          >
            <div
              className="modal_container"
            >
              <div className="modal" onClick={(E)=>E.stopPropagation()}>{props.children}</div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default NewModal;