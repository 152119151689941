import React, { useEffect, useLayoutEffect, useState } from "react";
import "./VenderShop.scss";
import { Container, Grid } from "../../../components";

import { store, storeimage, venderprofile, verified } from "../../../assests";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllServicesOfSingleVendor,
  getAllVendorServices2,
  getVendorDetails2,
  getVendorShopServiceByVendorName,
} from "../../../store/actions";
import { Puff } from "react-loader-spinner";
import { Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useQueryParams } from "../../../hooks";
import { BACKEND_STATIC_URL } from "../../../constants";
import MyPagination from "../../../components/common/Pagination/Pagination";

const useStyles = makeStyles({
  root: {
    "& .MuiPaginationItem-root": {
      color: "black",
      backgroundColor: "#ffc841",
      "&:hover": {
        backgroundColor: "#ffc841",
        color: "black",
      },
      "& .Mui-selected": {
        backgroundColor: "#ffc841",
        color: "black",
      },
    },
  },
});

const VenderShop = () => {
  const { vendorId } = useParams();
  const { getAllVendorServicesData, getVendorDetailsData } = useSelector(
    (s) => s.vendorServices2Reducer
  );
  const { page } = useQueryParams({
    page: 1,
  });

  useLayoutEffect(() => {
    getAllVendorServices2({
      payload: {
        filters: {
          vendor: vendorId,
          limit: 10,
          page: Number(page),
          status: "Accepted",
          deleteStatus: "NULL",
        },
      },
    });

    getVendorDetails2({ payload: { _id: vendorId } });
  }, [page, vendorId]);
  return getAllVendorServicesData.loading || getVendorDetailsData.loading ? (
    <Puff
      height="60"
      width="60"
      radius="6"
      color="black"
      ariaLabel="loading"
      wrapperStyle
      wrapperClass
    />
  ) : (
    <>
      <Container className="main">
        <Container className="lg">
          <div className="space-between  m-5  vendershop-profile">
            <div className="vendershop-profile-detail align-item-center">
              <div className="vendershop-profile-detail-image">
                <img
                  src={
                    getVendorDetailsData?.data?.photoPath
                      ? `${BACKEND_STATIC_URL}${getVendorDetailsData?.data?.photoPath}`
                      : venderprofile
                  }
                  alt="profile"
                />
              </div>
              <div className="vendershop-profile-detail-content">
                <div className="align-item-center">
                  <h5>{getVendorDetailsData?.data?.fullName || "--"}</h5>
                  {getVendorDetailsData?.data?.verfied && (
                    <img src={verified} alt="verify" />
                  )}
                </div>
                {/* <p>{getVendorDetailsData?.data?.address || "--"}</p> */}
              </div>
            </div>
            <div className="vendershop-profile-shop">
              {<img src={store} alt="store-icon" />}
            </div>
          </div>
          <div className="m-5 ">
            {getAllVendorServicesData.results.length === 0 ? (
              <h1>No record Found</h1>
            ) : (
              <Grid className="grid-3">
                {getAllVendorServicesData.results?.map((service, i) => (
                  <ShopCard
                    image={
                      service?.thumbnails
                        ? `${BACKEND_STATIC_URL}${service?.thumbnails[0]}`
                        : storeimage
                    }
                    heading={
                      service?.customServiceName ||
                      service?.serviceName?.serviceName ||
                      "--"
                    }
                    text={service?.description || "No description available"}
                    key={i}
                    to={`/event-detail/${service.id}`}
                  />
                ))}
              </Grid>
            )}
          </div>

          {getAllVendorServicesData.totalPages > 1 && (
            <MyPagination totalPages={getAllVendorServicesData.totalPages} />
          )}
        </Container>
      </Container>
    </>
  );
};

export default VenderShop;

const ShopCard = (props) => {
  return (
    <>
      <Link to={props.to}>
        <div className="shopcard">
          <div className="shopcard-image">
            <img src={props.image} alt="card-pic" />
          </div>
          <div className="shopcard-content">
            <h4>{props.heading}</h4>
            <p>{props.text}</p>
          </div>
        </div>
      </Link>
    </>
  );
};
