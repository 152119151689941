import React, { useEffect, useMemo } from "react";
import "./Payments.scss";
import { Button, Container, LinkBtn } from "../../../../components";
import { RiDeleteBinFill } from "react-icons/ri";
import { cardlogo } from "../../../../assests";
import { Puff } from "react-loader-spinner";
import toast from "react-hot-toast";
import {
  GetPaymentCard,
  DeletePaymentCard,
  clearErrors,
  clearMessages,
} from "./../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import AddCard from "../addCard/AddCard";

const Payments = () => {
  const dispatch = useDispatch();
  const { getCardsDetails, errors, message } = useSelector(
    (state) => state.vendorReducer
  );
  const { details, loading } = useMemo(
    () => ({
      loading: getCardsDetails.loading,
      details: getCardsDetails.data,
    }),
    [getCardsDetails]
  );

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message !== "") {
      toast.success(message);
      dispatch(clearMessages());
    }
  }, [errors, dispatch, message]);

  useEffect(() => {
    dispatch(GetPaymentCard());
  }, [dispatch]);
  return (
    <>
      <Container className="md">
        {loading ? (
          <Puff
            height="60"
            width="60"
            radius="6"
            color="black"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        ) : Object.keys(details).length > 0 ? (
          <div className="payment m-5">
            <div className="payment-container">
              <div className="payment-container-card">
                <div className="payment-card">
                  <div className="payment-card-heading space-between">
                    <h3>Card Number</h3>
                    <RiDeleteBinFill
                      onClick={() => dispatch(DeletePaymentCard())}
                    />
                  </div>
                  <div className="payment-card-number align-item-center">
                    <img src={cardlogo} alt="card-logo" />
                    <p>
                      {details?.card?.number &&
                        `**** **** **** ${details.card.number}`}
                    </p>
                  </div>
                  <div className="payment-card-cvc">
                    <h3>CVV Number </h3>
                    <p>***</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="payment-container-button">
            <AddCard />
          </div>
        )}
      </Container>
    </>
  );
};

export default Payments;
