import logo from "./Logo.png";
import profile from "./profile.png";
// import aboutfirst from "./aboutus/first.png";
import aboutfirst from "./aboutus/first1.jpg";
// import aboutsecond from "./aboutus/second.png";
import aboutsecond from "./aboutus/second1.jpg";
// import faq from "./faq.png";
import faq from "./faq.jpg";
import correct_bronze from "./correct_bronze.svg";
import correct_gold from "./correct_gold.svg";
import correct_silver from "./correct_silver.svg";
import correct_platinum from "./correct_platinum.svg";
// Payment
import cardlogo from "./cardlogo.svg";
import card from "./card.png";
// Hero Section
import slideImage1 from "./hero/slideImage1.png";
import LandingPageHeroImage from "./hero/banner2.png";
// social
import facebook from "./social/facebook.svg";
import insta from "./social/insta.svg";
// Card
import cardImage from "./card/card.png";
import cardImage1 from "./card/card1.jpg";
import cardImage2 from "./card/card2.webp";
import occasionView from "./viewPage/occasion.png";
import venderView from "./viewPage/vender.png";
// Vender Shop
import venderprofile from "./venderhop/profile.png";
import store from "./venderhop/store.svg";
import verified from "./venderhop/verified.svg";
import storeimage from "./venderhop/cardImage.png";
// import customerlogin from "./login/customer_signUp.png";
import customerlogin from "./login/customer_signUp.jpg";
import customersignup from "./login/customer_login.png";
import venderlogin from "./login/venderlogin.jpg";
// import vendersignup from "./login/vender_signup.png";
// import vendersignup from "./login/vender_signup2.jpg";
import vendersignup from "./login/vender_signup3.jpg";
// Events Catagory
import anniversary from "./viewPage/eventsImage/anniversary.jpg";
import babyShower from "./viewPage/eventsImage/babyShower.jpg";
import birthday from "./viewPage/eventsImage/birthday.jpg";
import bonfirenight from "./viewPage/eventsImage/bonfirenight.jpg";
import christmas from "./viewPage/eventsImage/christmas.jpg";
import diwali from "./viewPage/eventsImage/diwali.jpg";
import easter from "./viewPage/eventsImage/easter.jpg";
import eid from "./viewPage/eventsImage/eid.jpg";
import fatherDay from "./viewPage/eventsImage/fathersDay.jpg";
import firstdates from "./viewPage/eventsImage/firstdates.jpg";
import funeral from "./viewPage/eventsImage/funeral.jpg";
import graduation from "./viewPage/eventsImage/graduation.jpg";
import halloween from "./viewPage/eventsImage/halloween.jpg";
import hendo from "./viewPage/eventsImage/hendo.jpg";
import houseWarming from "./viewPage/eventsImage/houseWarming.jpg";
import motherday from "./viewPage/eventsImage/mothersday.jpg";
import newyear from "./viewPage/eventsImage/newyears.jpg";
import stagdo from "./viewPage/eventsImage/stagdo.jpg";
import valentines from "./viewPage/eventsImage/valentines.jpg";
import wedding from "./viewPage/eventsImage/wedding.jpg";
// Serivce and Products
import backdrop from "./services/backdrop.jpg";
import BalloonDisplays from "./services/BalloonDisplays.jpg";
import BarHire from "./services/BarHire.jpg";
import BellyDancers from "./services/BellyDancers.jpg";
import BigLetters from "./services/BigLetters.jpg";
import BouncyCastle from "./services/BouncyCastle.jpg";
import Cakes from "./services/Cakes.jpg";
import CandyFloss from "./services/CandyFloss.jpg";
import ChocolateFountain from "./services/ChocolateFountain.jpg";
import Confectionary from "./services/Confectionary.jpg";
import DJ from "./services/DJ.jpg";
import Flowers from "./services/Flowers.jpg";
import Games from "./services/Games.jpg";
import Karaoke from "./services/Karaoke.jpg";
import Lighting from "./services/Lighting.jpg";
import Marquee from "./services/Marquee.jpg";
import PhotoBooth from "./services/PhotoBooth.jpg";
import Photographer from "./services/Photographer.jpg";
import Popcorn from "./services/Popcorn.jpg";
import Singer from "./services/Singer.jpg";
import Slushie from "./services/Slushie.jpg";
import SoftPlayUnit from "./services/SoftPlayUnit.jpg";
import SweetCarts from "./services/SweetCarts.jpg";
import TableandChairHire from "./services/TableandChairHire.jpg";
import Videographer from "./services/Videographer.jpg";
import WeddingFavours from "./services/WeddingFavours.jpg";
import WeddingStage from "./services/WeddingStage.jpg";
import Gifts from "./services/gifts.jpg";
import Venue from "./services/venue.jpg";
import rentacar from "./services/rentacar.jpg";
import TikTok from "./tiktok.webp";
// exports
export {
  LandingPageHeroImage,
  TikTok,
  backdrop,
  BalloonDisplays,
  BarHire,
  BellyDancers,
  BigLetters,
  BouncyCastle,
  Cakes,
  CandyFloss,
  ChocolateFountain,
  Confectionary,
  DJ,
  Flowers,
  Games,
  Karaoke,
  Lighting,
  Marquee,
  PhotoBooth,
  Photographer,
  Popcorn,
  Singer,
  Slushie,
  SoftPlayUnit,
  SweetCarts,
  TableandChairHire,
  Videographer,
  WeddingFavours,
  WeddingStage,
  Gifts,
  Venue,
  // Others
  logo,
  profile,
  faq,
  aboutfirst,
  aboutsecond,
  cardImage,
  cardImage1,
  cardImage2,
  occasionView,
  venderView,
  venderprofile,
  store,
  verified,
  storeimage,
  vendersignup,
  venderlogin,
  customerlogin,
  customersignup,
  facebook,
  insta,
  correct_gold,
  correct_bronze,
  correct_platinum,
  correct_silver,
  cardlogo,
  card,
  // Hero Section
  slideImage1,
  // events category
  anniversary,
  babyShower,
  birthday,
  bonfirenight,
  christmas,
  diwali,
  easter,
  eid,
  fatherDay,
  firstdates,
  funeral,
  graduation,
  halloween,
  hendo,
  houseWarming,
  motherday,
  newyear,
  stagdo,
  valentines,
  wedding,
  rentacar,
};
