// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question {
  display: flex; }
  .question > *:not(:last-child) {
    margin-right: 1.5rem; }

.faq-hero {
  width: 100%;
  max-width: 80rem;
  margin: 0 auto; }
  .faq-hero > img {
    width: 100%;
    max-height: 35rem;
    object-fit: cover;
    object-position: center; }

.faq-main {
  width: 100%;
  max-width: 80rem;
  margin: 0 auto; }
`, "",{"version":3,"sources":["webpack://./src/module/customer/faq/Faq.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAA;EADf;IAKM,oBAAoB,EAAA;;AAMxB;EACE,WAAW;EACX,gBAAgB;EAChB,cAAc,EAAA;EAHf;IAKG,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,uBAAuB,EAAA;;AAI3B;EACE,WAAW;EACX,gBAAgB;EAChB,cAAc,EAAA","sourcesContent":[".question {\n  display: flex;\n\n  & > * {\n    &:not(:last-child) {\n      margin-right: 1.5rem;\n    }\n  }\n}\n\n.faq {\n  &-hero {\n    width: 100%;\n    max-width: 80rem;\n    margin: 0 auto;\n    & > img {\n      width: 100%;\n      max-height: 35rem;\n      object-fit: cover;\n      object-position: center;\n    }\n  }\n\n  &-main {\n    width: 100%;\n    max-width: 80rem;\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
