import { useState, useEffect, useCallback, useMemo } from "react";
import "./Navbar.scss";
import { logo, profile } from "../../../assests";
import { Link } from "react-router-dom";
import Container from "../../container/Container";
import { Puff } from "react-loader-spinner";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOut, clearErrors, clearMessages } from "./../../../store/actions";
// Icons
import {
  MdMessage,
  MdOutlineKeyboardArrowDown,
  MdAccountCircle,
} from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import { VscMenu } from "react-icons/vsc";
import { RxCross2 } from "react-icons/rx";
import { AiFillMessage } from "react-icons/ai";
import { RiSettings5Fill } from "react-icons/ri";
import { BACKEND_STATIC_URL } from "../../../constants";
import useUser from "../../../hooks/useUser";
import {
  MobileNavbarStyled,
  NavbarModalStyled,
  ProfileDropDownStyled,
  StyledForm,
} from "./styles";
import { FaSearch, FaTimes } from "react-icons/fa";
import { homePageServiceSearch } from "../../../schema";
import { useFormik } from "formik";
import { objToStr } from "../../../utils";
import AutoCompleteNew from "../../AutoCompleteNew/AutoCompleteNew";
import styled from "@emotion/styled";
import { reGetUserDetails2 } from "../../../store/actions/user.action";

const Navbar = (props) => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [dropDownAnchor, setDropDownAnchor] = useState(null);
  const user = useUser();
  const onMenuOpen = useCallback((e) => setDropDownAnchor(e.currentTarget), []);
  const onMenuClose = useCallback((e) => setDropDownAnchor(null), []);
  const onHamburgerClose = useCallback((e) => setHamburgerOpen(false), []);
  const onHamburgerOpen = useCallback((e) => setHamburgerOpen(true), []);
  const { allMessages } = useSelector((s) => s.chatReducer);

  const numberOfChatNotifications = useMemo(
    () =>
      allMessages?.filter((m) => m.receiverId === user?.id && !m.isSeen).length,
    [allMessages, user?.id]
  );

  return (
    <>
      <div className="navbar">
        <div className="navbar-container">
          <div className="navbar-container-logo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          {props.children}
          {user ? (
            <div className="navbar-container-profile">
              <StyledChatButtonLikeDiv className="navbar-container-profile-icon align-item-center">
                <Link to="/chat">
                  <MdMessage />

                  {Boolean(numberOfChatNotifications) ? (
                    <span>
                      <span>{numberOfChatNotifications}</span>
                    </span>
                  ) : (
                    ""
                  )}
                </Link>
              </StyledChatButtonLikeDiv>
              <button className="align-item-center" onClick={onMenuOpen}>
                Menu
                <MdOutlineKeyboardArrowDown
                  style={{ marginLeft: ".4rem", fontSize: "2rem" }}
                />
              </button>
              <img
                src={
                  user?.photoPath
                    ? `${BACKEND_STATIC_URL}${user?.photoPath}`
                    : profile
                }
                alt="profile"
              />
            </div>
          ) : (
            <div></div>
          )}
          <div className="navbar-container-profile-menuicon">
            {/* {show ? (
              <RxCross2 onClick={() => setShow(false)} />
            ) : ( */}
            <VscMenu onClick={onHamburgerOpen} />
            {/* )} */}
          </div>
        </div>
      </div>

      <ProfileDropDownStyled
        open={Boolean(dropDownAnchor)}
        onClose={onMenuClose}
        anchorEl={dropDownAnchor}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <DropdownMenuForVendor onClose={onMenuClose} />
      </ProfileDropDownStyled>

      <NavbarModalStyled open={hamburgerOpen} onClose={onHamburgerClose}>
        <Mobile onClose={onHamburgerClose} />
      </NavbarModalStyled>
    </>
  );
};

export default Navbar;

///////////
// Sub Components

const Mobile = ({ onClose }) => {
  const navigate = useNavigate();
  const { servicesTypes } = useSelector((s) => s.servicesTypesReducer);
  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      service: null,
    },
    validationSchema: homePageServiceSearch,

    onSubmit: (values) => {
      onClose();
      navigate(
        `/search-results?${objToStr({ service: values?.service?._id })}`
      );
    },
  });
  return (
    <>
      {/* <div className="mobile-navbar">
        <Container className="half">
          <div className="mobile">
            <div className="md"> */}
      <MobileNavbarStyled className="mobile-container-menus">
        <StyledForm onSubmit={handleSubmit}>
          <AutoCompleteNew
            placeholder="Search"
            options={servicesTypes}
            keyName="serviceName"
            onChange={(v) => setFieldValue("service", v)}
            value={values.service}
          />

          <button type="submit">
            <FaSearch />
          </button>
        </StyledForm>
        <div className="mobile-container-menus-item align-item-center">
          <Link to="support" onClick={onClose}>
            <p>Support</p>
          </Link>
          <Link to="/aboutus" onClick={onClose}>
            <p>About us</p>
          </Link>
          <Link to="/vender-signin" onClick={onClose}>
            <p>Vendor</p>
          </Link>
          <Link to="/customersignin" onClick={onClose}>
            <p>Customer</p>
          </Link>
        </div>

        <button className="close" onClick={onClose}>
          <FaTimes />
        </button>
      </MobileNavbarStyled>
      {/* </div>
          </div>
        </Container>
      </div> */}
    </>
  );
};

// const DropdownMenuForCustomer = () => {
//   const [display, setDisplay] = useState(true);
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { message, errors, loading } = useSelector(
//     (state) => state.customerAuthReducer
//   );
//   useEffect(() => {
//     if (errors?.length > 0) {
//       toast.error(errors);
//       dispatch(clearErrors());
//     }
//     if (message != "") {
//       toast.success(message);
//       dispatch(clearMessages());
//       setTimeout(() => navigate("/"), 2000);
//     }
//   }, [errors, message]);
//   const handleLogOut = () => {
//     setDisplay(!display);
//     dispatch(
//       logOut(() => {
//         navigate("/");
//         toast.success("Logout!");
//         dispatch(clearMessages());
//       })
//     );
//   };
//   return (
//     <>
//       {display ? (
//         <div className="dropdown">
//           <div className="dropdown-container">
//             <div className="dropdown-container-item">
//               <Link
//                 to="/chat"
//                 onClick={() => {
//                   setDisplay(!display);
//                 }}
//               >
//                 <AiFillMessage />
//                 <p>Message</p>
//               </Link>
//             </div>
//             <div className="dropdown-container-item">
//               <Link
//                 to="/customer-settings"
//                 onClick={() => {
//                   setDisplay(!display);
//                 }}
//               >
//                 <MdAccountCircle />
//                 <p>My account</p>
//               </Link>
//             </div>
//             <div className="dropdown-container-item">
//               <Link
//                 to="/customer-settings"
//                 onClick={() => {
//                   setDisplay(!display);
//                 }}
//               >
//                 <RiSettings5Fill />
//                 <p>Settings</p>
//               </Link>
//             </div>
//             <div className="dropdown-container-logout">
//               <button
//                 onClick={() => {
//                   handleLogOut();
//                 }}
//               >
//                 {loading ? (
//                   <Puff
//                     height="25"
//                     width="30"
//                     radius="6"
//                     color="white"
//                     ariaLabel="loading"
//                     wrapperStyle
//                     wrapperClass
//                   />
//                 ) : (
//                   "Logout"
//                 )}
//               </button>
//             </div>
//           </div>
//         </div>
//       ) : (
//         ""
//       )}
//     </>
//   );
// };

const DropdownMenuForVendor = ({ onClose }) => {
  const user = useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.customerAuthReducer);

  const handleLogOut = () => {
    dispatch(
      logOut(() => {
        navigate("/");
        toast.success("Logout!");
        reGetUserDetails2();
      })
    );
  };
  return (
    <>
      <div className="dropdown">
        <div className="dropdown-container">
          <div className="dropdown-container-item">
            <Link to="/chat" onClick={onClose}>
              <AiFillMessage />
              <p>Message</p>
            </Link>
          </div>
          <div className="dropdown-container-item">
            <Link
              to={
                user?.role === "Vendor"
                  ? "/vender-dashboard"
                  : "/customer-dashboard"
              }
              onClick={onClose}
            >
              <MdAccountCircle />
              <p>My dashboard</p>
            </Link>
          </div>
          <div className="dropdown-container-item">
            <Link
              to={
                user?.role === "Vendor"
                  ? "/vender-settings"
                  : "/customer-settings"
              }
              onClick={onClose}
            >
              <RiSettings5Fill />
              <p>Settings</p>
            </Link>
          </div>
          <div className="dropdown-container-logout">
            <button
              onClick={() => {
                onClose();
                handleLogOut();
              }}
            >
              {loading ? (
                <Puff
                  height="25"
                  width="30"
                  radius="6"
                  color="white"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                />
              ) : (
                "Logout"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const StyledChatButtonLikeDiv = styled.div`
  & > a {
    position: relative;
    color: #ffc841;
    display: grid;
    align-items: center;
    font-size: 2.8rem;

    & > span {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 0.8rem;
      z-index: 1;
      background-color: #f23d33;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      display: grid;
      place-items: center;
      font-weight: bold;
      color: white;
      transform: translateY(-50%) translateX(50%);

      & > span {
        line-height: 0;
        transform: translateY(0.1rem);
      }
    }
  }
`;
