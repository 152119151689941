import { useContext, useState } from "react";
import "./Selector.scss";
import { RiArrowDownSLine } from "react-icons/ri";
import { OccasionContext } from "../../contexts/OccasionContext";
const Selector = ({ fullObj = false, ...props }) => {
  // const {selectedOccasion, setSelectedOccasion} = useContext(OccasionContext)
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <div className={`selector ${props.className}`}>
        <div className="selector-container">
          <div
            className="selector-container-top"
            onClick={() => {
              setIsActive(!isActive);
            }}
          >
            <h6>{props.selected}</h6>
            <div className="selector-container-top-icon">
              <RiArrowDownSLine />
            </div>
          </div>

          {isActive && (
            <div className="selector-container-selector">
              <div className="selector-container-selector-content">
                {props.data.map((item) => {
                  return (
                    <>
                      <div
                        className="selector-container-selector-item"
                        onClick={(e) => {
                          props.setSelected(
                            fullObj ? item : e.target.textContent
                          );
                          setIsActive(!isActive);
                        }}
                      >
                        {item.name ? item.name : item}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Selector;
