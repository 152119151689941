export const fakeServiceDetailsData = [
    {
        name: "Fake Service 1",
        status: "Pending",
        reason:"Fake Reason 1 should be really long so that it can be tested on small screen and now I am going to write some more text to make it even longer",
        id: 1,
    },
    {
        name: "Fake Service 1",
        status: "Pending",
        reason:"Fake Reason 1",
        id: 1,
    },
    {
        name: "Fake Service 1",
        status: "Pending",
        reason:"Fake Reason 1",
        id: 1,
    },
    {
        name: "Fake Service 1",
        status: "Pending",
        reason:"Fake Reason 1",
        id: 1,
    },
    {
        name: "Fake Service 1",
        status: "Pending",
        reason:"Fake Reason 1",
        id: 1,
    },
]