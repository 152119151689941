// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading > h1 {
  color: #4c4d4e;
  margin: 1rem 0rem; }

.primary > h1 {
  font-weight: 700;
  font-size: 2.2rem; }

.secondry > h1 {
  font-weight: 600;
  font-size: 2rem; }

.light > h1 {
  font-weight: 400;
  font-size: 2rem; }
`, "",{"version":3,"sources":["webpack://./src/components/heading/Heading.scss","webpack://./src/scss/_variable.scss"],"names":[],"mappings":"AAEA;EAEI,cCOqB;EDNrB,iBAAiB,EAAA;;AAGrB;EAEI,gBAAgB;EAChB,iBAAiB,EAAA;;AAGrB;EAEI,gBAAgB;EAChB,eAAe,EAAA;;AAInB;EAEI,gBAAgB;EAChB,eAAe,EAAA","sourcesContent":["@import \"../../scss/variable\";\n\n.heading {\n  & > h1 {\n    color: $heading-primary;\n    margin: 1rem 0rem;\n  }\n}\n.primary {\n  & > h1 {\n    font-weight: 700;\n    font-size: 2.2rem;\n  }\n}\n.secondry {\n  & > h1 {\n    font-weight: 600;\n    font-size: 2rem;\n  }\n}\n\n.light {\n  & > h1 {\n    font-weight: 400;\n    font-size: 2rem;\n  }\n}\n","$primary: #ffc841;\n$primary-hover: #cda814;\n\n$light-gray: #f2f1f1;\n$gray: #838282;\n\n$white: #ffffff;\n$sidebar-background: #212121;\n$footer_background: black;\n\n// Typo Graphy\n$heading-primary: #4c4d4e;\n\n$black: black;\n\n$dorger: #e0fff9;\n\n$bronze: #603b30;\n$sliver: #c5c5c5;\n$gold: #c57934;\n$platinium: #2d2d2d;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
