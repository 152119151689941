import React from "react";
import { AdminVendorMessage } from "../../../../components";
import { userData, dummy__messages } from "../../../../data/messageData";

const VendorMessageComponent = () => {
  return (
    <>
      <AdminVendorMessage users={userData} messages={dummy__messages} />
    </>
  );
};

export default VendorMessageComponent;
