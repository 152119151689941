export default function scrollToTop(
  elementClassName = "customerdashboardlayout-container-content"
) {
  if (elementClassName) {
    const element = document.querySelector(`.${elementClassName}`);
    if (!element) {
      return;
    }
    element.scroll({
      behavior: "smooth",
      top: 0,
      left: 0,
    });
  } else
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
}
