import React, { createContext, useEffect, useState } from 'react';

const OccasionContext = createContext();

const OccasionProvider = ({ children }) => {
  const [selectedOccasion, setSelectedOccasion] = useState("Choose Occasion");

  useEffect(() => {
    console.log(selectedOccasion);
  }, [selectedOccasion])
  
  return (
    <OccasionContext.Provider value={{ selectedOccasion, setSelectedOccasion }}>
      {children}
    </OccasionContext.Provider>
  );
}

export { OccasionProvider, OccasionContext };
