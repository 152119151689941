import React, { useCallback, useMemo } from "react";
import "./CustomerPanelLayout.scss";
import { Outlet } from "react-router-dom";
import { Footer, Navbar, SideMenuBar } from "../../../components/common";
import { customerRoutes } from "../../../data/routesData";
import { useSelector } from "react-redux";
import {
  getNumberOfUnseenMessages,
  getOtherSideParticipant,
} from "../../../utils/getChat";
import { NOTIFICATION_TYPES } from "../../../firebase/notfication";
import useUser from "../../../hooks/useUser";

const CustomerPanelLayout = () => {
  const customerUser = useUser();

  const { allMessages } = useSelector((s) => s.chatReducer);
  const { allNotifications } = useSelector((s) => s.notificationReducer);

  const routes = useMemo(() => {
    return customerRoutes.map((route) => {
      switch (route.path) {
        case "/chat":
          route.notification = allMessages?.filter(
            (m) => m.receiverId === customerUser?.id && !m.isSeen
          ).length;
          return route;
        case "/my-orders":
          route.notification = allNotifications?.filter(
            (n) => n.notficationType === NOTIFICATION_TYPES.CUSTOMER_REQUEST
          ).length;
          return route;
        default:
          return route;
      }
    });
  }, [allMessages, allNotifications, customerUser]);

  return (
    <>
      <div className="customerdashboardlayout">
        <div className="customerdashboardlayout-container">
          <SideMenuBar
            routes={routes}
            customer={customerUser?.fullName && customerUser?.fullName}
          />
          <div className="customerdashboardlayout-container-content">
            <div className="customerdashboardlayout-container-content-nav">
              <Navbar customer={customerUser} />
            </div>
            <div className="customerdashboardlayout-container-content-outlet">
              <Outlet />
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerPanelLayout;
