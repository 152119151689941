export const vendorAuthConstant = {
  VENDOR_REGISTER_REQUEST: "VENDOR_REGISTER_REQUEST",
  VENDOR_REGISTER_SUCCESS: "VENDOR_REGISTER_SUCCESS",
  VENDOR_REGISTER_FAILURE: "VENDOR_REGISTER_FAILURE",
  VENDOR_LOGIN_REQUEST: "VENDOR_LOGIN_REQUEST",
  VENDOR_LOGIN_SUCCESS: "VENDOR_LOGIN_SUCCESS",
  VENDOR_LOGIN_FAILURE: "VENDOR_LOGIN_FAILURE",
  VENDOR_LOGOUT_REQUEST: "VENDOR_LOGOUT_REQUEST",
  VENDOR_LOGOUT_SUCCESS: "VENDOR_LOGOUT_SUCCESS",
  VENDOR_LOGOUT_FAILURE: "VENDOR_LOGOUT_FAILURE",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  GET_LOGGED_IN_USER_REQUEST: "GET_LOGGED_IN_USER_REQUEST",
  GET_LOGGED_IN_USER_SUCCESS: "GET_LOGGED_IN_USER_SUCCESS",
  GET_LOGGED_IN_USER_FAILURE: "GET_LOGGED_IN_USER_FAILURE",
};
