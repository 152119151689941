export const vendorConstant = {
  CREATE_SERVICE_REQUEST: "CREATE_SERVICE_REQUEST",
  CREATE_SERVICE_SUCCESS: "CREATE_SERVICE_SUCCESS",
  CREATE_SERVICE_FAILURE: "CREATE_SERVICE_FAILURE",
  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  GET_MY_SERVICE_REQUEST: "GET_MY_SERVICE_REQUEST",
  GET_MY_SERVICE_SUCCESS: "GET_MY_SERVICE_SUCCESS",
  GET_MY_SERVICE_FAILURE: "GET_MY_SERVICE_FAILURE",
  GET_SERVICE_REQUEST: "GET_SERVICE_REQUEST",
  GET_SERVICE_SUCCESS: "GET_SERVICE_SUCCESS",
  GET_SERVICE_FAILURE: "GET_SERVICE_FAILURE",
  DELETE_MY_SERVICE_REQUEST: "DELETE_MY_SERVICE_REQUEST",
  DELETE_MY_SERVICE_SUCCESS: "DELETE_MY_SERVICE_SUCCESS",
  DELETE_MY_SERVICE_FAILURE: "DELETE_MY_SERVICE_FAILURE",
  ADD_CARD_REQUEST: "ADD_CARD_REQUEST",
  ADD_CARD_SUCCESS: "ADD_CARD_SUCCESS",
  ADD_CARD_FAILURE: "ADD_CARD_FAILURE",
  GET_CARD_REQUEST: "GET_CARD_REQUEST",
  GET_CARD_SUCCESS: "GET_CARD_SUCCESS",
  GET_CARD_FAILURE: "GET_CARD_FAILURE",
  DELETE_CARD_REQUEST: "DELETE_CARD_REQUEST",
  DELETE_CARD_SUCCESS: "DELETE_CARD_SUCCESS",
  DELETE_CARD_FAILURE: "DELETE_CARD_FAILURE",
  MARK_CARD_AS_DEFAULT_PAYMENT_METHOD_REQUEST:
    "MARK_CARD_AS_DEFAULT_PAYMENT_METHOD_REQUEST",
  MARK_CARD_AS_DEFAULT_PAYMENT_METHOD_SUCCESS:
    "MARK_CARD_AS_DEFAULT_PAYMENT_METHOD_SUCCESS",
  MARK_CARD_AS_DEFAULT_PAYMENT_METHOD_FAILURE:
    "MARK_CARD_AS_DEFAULT_PAYMENT_METHOD_FAILURE",
  CREATE_VERIFICATION_REQUEST: "CREATE_VERIFICATION_REQUEST",
  CREATE_VERIFICATION_SUCCESS: "CREATE_VERIFICATION_SUCCESS",
  CREATE_VERIFICATION_FAILURE: "CREATE_VERIFICATION_FAILURE",
  EDIT_USER_PROFILE_REQUEST: "EDIT_USER_PROFILE_REQUEST",
  EDIT_USER_PROFILE_SUCCESS: "EDIT_USER_PROFILE_SUCCESS",
  EDIT_USER_PROFILE_FAILURE: "EDIT_USER_PROFILE_FAILURE",
  GET_SERVICE_BY_TYPE_REQUEST: "GET_SERVICE_BY_TYPE_REQUEST",
  GET_SERVICE_BY_TYPE_EVENT_SUCCESS: "GET_SERVICE_BY_TYPE_EVENT_SUCCESS",
  GET_SERVICE_BY_TYPE_FAILURE: "GET_SERVICE_BY_TYPE_FAILURE",
  GET_SERVICE_BY_TYPE_PRODUCT_SUCCESS: "GET_SERVICE_BY_TYPE_PRODUCT_SUCCESS",
  GET_SINGLE_SERVICE_REQUEST: "GET_SINGLE_SERVICE_REQUEST",
  GET_SINGLE_SERVICE_SUCCESS: "GET_SINGLE_SERVICE_SUCCESS",
  GET_SINGLE_SERVICE_FAILURE: "GET_SINGLE_SERVICE_FAILURE",
  ADD_TO_FAVOURITE_REQUEST: "ADD_TO_FAVOURITE_REQUEST",
  ADD_TO_FAVOURITE_SUCCESS: "ADD_TO_FAVOURITE_SUCCESS",
  ADD_TO_FAVOURITE_FAILURE: "ADD_TO_FAVOURITE_FAILURE",
  ADD_TO_LIKE_REQUEST: "ADD_TO_LIKE_REQUEST",
  ADD_TO_LIKE_SUCCESS: "ADD_TO_LIKE_SUCCESS",
  ADD_TO_LIKE_FAILURE: "ADD_TO_LIKE_FAILURE",
  GET_FAVOURITE_SERVICES_REQUEST: "GET_FAVOURITE_SERVICES_REQUEST",
  GET_FAVOURITE_SERVICES_SUCCESS: "GET_FAVOURITE_SERVICES_SUCCESS",
  GET_FAVOURITE_SERVICES_FAILURE: "GET_FAVOURITE_SERVICES_FAILURE",
  GET_MEMBERSHIP_SUBSCRIPTION_REQUEST: "GET_MEMBERSHIP_SUBSCRIPTION_REQUEST",
  GET_MEMBERSHIP_SUBSCRIPTION_SUCCESS: "GET_MEMBERSHIP_SUBSCRIPTION_SUCCESS",
  GET_MEMBERSHIP_SUBSCRIPTION_FAILURE: "GET_MEMBERSHIP_SUBSCRIPTION_FAILURE",
  ADD_MEMBERSHIP_SUBSCRIPTION_REQUEST: "ADD_MEMBERSHIP_SUBSCRIPTION_REQUEST",
  ADD_MEMBERSHIP_SUBSCRIPTION_SUCCESS: "ADD_MEMBERSHIP_SUBSCRIPTION_SUCCESS",
  ADD_MEMBERSHIP_SUBSCRIPTION_FAILURE: "ADD_MEMBERSHIP_SUBSCRIPTION_FAILURE",
  UPDATE_SINGLE_SERVICE_REQUEST: "UPDATE_SINGLE_SERVICE_REQUEST",
  UPDATE_SINGLE_SERVICE_SUCCESS: "UPDATE_SINGLE_SERVICE_SUCCESS",
  UPDATE_SINGLE_SERVICE_FAILURE: "UPDATE_SINGLE_SERVICE_FAILURE",
  GET_ALL_CUSTOMER_REQUESTS_REQUEST: "GET_ALL_CUSTOMER_REQUESTS_REQUEST",
  GET_ALL_CUSTOMER_REQUESTS_SUCCESS: "GET_ALL_CUSTOMER_REQUESTS_SUCCESS",
  GET_ALL_CUSTOMER_REQUESTS_FAILURE: "GET_ALL_CUSTOMER_REQUESTS_FAILURE",
  GET_ALL_CUSTOMER_COMPLETED_REQUESTS_REQUEST:
    "GET_ALL_CUSTOMER_COMPLETED_REQUESTS_REQUEST",
  GET_ALL_CUSTOMER_COMPLETED_REQUESTS_SUCCESS:
    "GET_ALL_CUSTOMER_COMPLETED_REQUESTS_SUCCESS",
  GET_ALL_CUSTOMER_COMPLETED_REQUESTS_FAILURE:
    "GET_ALL_CUSTOMER_COMPLETED_REQUESTS_FAILURE",
  GET_ALL_CUSTOMER_CANCELLED_REQUESTS_REQUEST:
    "GET_ALL_CUSTOMER_CANCELLED_REQUESTS_REQUEST",
  GET_ALL_CUSTOMER_CANCELLED_REQUESTS_SUCCESS:
    "GET_ALL_CUSTOMER_CANCELLED_REQUESTS_SUCCESS",
  GET_ALL_CUSTOMER_CANCELLED_REQUESTS_FAILURE:
    "GET_ALL_CUSTOMER_CANCELLED_REQUESTS_FAILURE",
  UPDATE_STATUS_CUSTOMER_REQUEST: "UPDATE_STATUS_CUSTOMER_REQUEST",
  UPDATE_STATUS_CUSTOMER_SUCCESS: "UPDATE_STATUS_CUSTOMER_SUCCESS",
  UPDATE_STATUS_CUSTOMER_FAILURE: "UPDATE_STATUS_CUSTOMER_FAILURE",
  GET_USER_FOR_CHAT_REQUEST: "GET_USER_FOR_CHAT_REQUEST",
  GET_USER_FOR_CHAT_SUCCESS: "GET_USER_FOR_CHAT_SUCCESS",
  GET_USER_FOR_CHAT_FAILURE: "GET_USER_FOR_CHAT_FAILURE",
  GET_VENDOR_FOR_CHAT_REQUEST: "GET_VENDOR_FOR_CHAT_REQUEST",
  GET_VENDOR_FOR_CHAT_SUCCESS: "GET_VENDOR_FOR_CHAT_SUCCESS",
  GET_VENDOR_FOR_CHAT_FAILURE: "GET_VENDOR_FOR_CHAT_FAILURE",
  CREATE_CHAT_CONNECTION_REQUEST: "CREATE_CHAT_CONNECTION_REQUEST",
  CREATE_CHAT_CONNECTION_SUCCESS: "CREATE_CHAT_CONNECTION_SUCCESS",
  CREATE_CHAT_CONNECTION_FAILURE: "CREATE_CHAT_CONNECTION_FAILURE",
  CLEAR_CHAT_CONNECTION: "CLEAR_CHAT_CONNECTION",
  ADD_MESSAGE_REQUEST: "ADD_MESSAGE_REQUEST",
  ADD_MESSAGE_SUCCESS: "ADD_MESSAGE_SUCCESS",
  ADD_MESSAGE_FAILURE: "ADD_MESSAGE_FAILURE",
  GET_USER_INFO_SUCCESS: "GET_USER_INFO_SUCCESS",
  GET_USER_INFO_FAILURE: "GET_USER_INFO_FAILURE",
  GET_TOTAL_FAVOURITE_SUCCESS: "GET_TOTAL_FAVOURITE_SUCCESS",
  GET_TOTAL_FAVOURITE_FAILURE: "GET_TOTAL_FAVOURITE_FAILURE",
  GET_TOTAL_LIKE_SUCCESS: "GET_TOTAL_LIKE_SUCCESS",
  GET_TOTAL_LIKE_FAILURE: "GET_TOTAL_LIKE_FAILURE",
  GET_TOTAL_VIEWS_SUCCESS: "GET_TOTAL_VIEWS_SUCCESS",
  GET_TOTAL_VIEWS_FAILURE: "GET_TOTAL_VIEWS_FAILURE",
  GET_ALL_ADMIN_REQUEST: "GET_ALL_ADMIN_REQUEST",
  GET_ALL_ADMIN_SUCCESS: "GET_ALL_ADMIN_SUCCESS",
  GET_ALL_ADMIN_FAILURE: "GET_ALL_ADMIN_FAILURE",
  GET_ALL_CHATS_IDS_VENDOR_REQUEST: "GET_ALL_CHATS_IDS_VENDOR_REQUEST",
  GET_ALL_CHATS_IDS_VENDOR_SUCCESS: "GET_ALL_CHATS_IDS_VENDOR_SUCCESS",
  GET_ALL_CHATS_IDS_VENDOR_FAILURE: "GET_ALL_CHATS_IDS_VENDOR_FAILURE",
  CANCEL_MEMBERSHIP_VENDOR_REQUEST: "CANCEL_MEMBERSHIP_VENDOR_REQUEST",
  CANCEL_MEMBERSHIP_VENDOR_SUCCESS: "CANCEL_MEMBERSHIP_VENDOR_SUCCESS",
  CANCEL_MEMBERSHIP_VENDOR_FAILURE: "CANCEL_MEMBERSHIP_VENDOR_FAILURE",
  RESUBSCRIBE_MEMBERSHIP_VENDOR_REQUEST:
    "RESUBSCRIBE_MEMBERSHIP_VENDOR_REQUEST",
  RESUBSCRIBE_MEMBERSHIP_VENDOR_SUCCESS:
    "RESUBSCRIBE_MEMBERSHIP_VENDOR_SUCCESS",
  RESUBSCRIBE_MEMBERSHIP_VENDOR_FAILURE:
    "RESUBSCRIBE_MEMBERSHIP_VENDOR_FAILURE",
  UPDATE_MEMBERSHIP_VENDOR_REQUEST: "UPDATE_MEMBERSHIP_VENDOR_REQUEST",
  UPDATE_MEMBERSHIP_VENDOR_SUCCESS: "UPDATE_MEMBERSHIP_VENDOR_SUCCESS",
  UPDATE_MEMBERSHIP_VENDOR_FAILURE: "UPDATE_MEMBERSHIP_VENDOR_FAILURE",
  GET_ALL_SERVICES_OF_SINGLE_VENDOR_REQUEST:
    "GET_ALL_SERVICES_OF_SINGLE_VENDOR_REQUEST",
  GET_ALL_SERVICES_OF_SINGLE_VENDOR_SUCCESS:
    "GET_ALL_SERVICES_OF_SINGLE_VENDOR_SUCCESS",
  GET_ALL_SERVICES_OF_SINGLE_VENDOR_FAILURE:
    "GET_ALL_SERVICES_OF_SINGLE_VENDOR_FAILURE",

  RESET_REDUCER: "RESET_REDUCER",

  GET_COUPON_DETAILS_REQUEST: "GET_COUPON_DETAILS_REQUEST",
  GET_COUPON_DETAILS_SUCCESS: "GET_COUPON_DETAILS_SUCCESS",
  GET_COUPON_DETAILS_FAILURE: "GET_COUPON_DETAILS_FAILURE",

  ADD_CARD_BEFORE_SUBSCRIPTION_REQUEST: "ADD_CARD_BEFORE_SUBSCRIPTION_REQUEST",
  ADD_CARD_BEFORE_SUBSCRIPTION_SUCCESS: "ADD_CARD_BEFORE_SUBSCRIPTION_SUCCESS",
  ADD_CARD_BEFORE_SUBSCRIPTION_FAILURE: "ADD_CARD_BEFORE_SUBSCRIPTION_FAILURE",

  ADD_TO_EMAIL_LIST_REQUEST: "ADD_TO_EMAIL_LIST_REQUEST",
  ADD_TO_EMAIL_LIST_SUCCESS: "ADD_TO_EMAIL_LIST_SUCCESS",
  ADD_TO_EMAIL_LIST_FAILURE: "ADD_TO_EMAIL_LIST_FAILURE",

  REMOVE_FROM_EMAIL_LIST_REQUEST: "REMOVE_FROM_EMAIL_LIST_REQUEST",
  REMOVE_FROM_EMAIL_LIST_SUCCESS: "REMOVE_FROM_EMAIL_LIST_SUCCESS",
  REMOVE_FROM_EMAIL_LIST_FAILURE: "REMOVE_FROM_EMAIL_LIST_FAILURE",

  ADD_NEW_VIEW_REQUEST: "ADD_NEW_VIEW_REQUEST",
  ADD_NEW_VIEW_SUCCESS: "ADD_NEW_VIEW_SUCCESS",
  ADD_NEW_VIEW_FAILURE: "ADD_NEW_VIEW_FAILURE",

  GET_TOTAL_NUMBER_OF_UNVIEWED_SERVICE_UPDATE_REQUEST:
    "GET_TOTAL_NUMBER_OF_UNVIEWED_SERVICE_UPDATE_REQUEST",
  GET_TOTAL_NUMBER_OF_UNVIEWED_SERVICE_UPDATE_SUCCESS:
    "GET_TOTAL_NUMBER_OF_UNVIEWED_SERVICE_UPDATE_SUCCESS",
  GET_TOTAL_NUMBER_OF_UNVIEWED_SERVICE_UPDATE_FAILURE:
    "GET_TOTAL_NUMBER_OF_UNVIEWED_SERVICE_UPDATE_FAILURE",

  GET_UNVIEWED_ALL_SERVICES_REQUEST: "GET_UNVIEWED_ALL_SERVICES_REQUEST",
  GET_UNVIEWED_ALL_SERVICES_SUCCESS: "GET_UNVIEWED_ALL_SERVICES_SUCCESS",
  GET_UNVIEWED_ALL_SERVICES_FAILURE: "GET_UNVIEWED_ALL_SERVICES_FAILURE",

  PATCH_UNVIEW_STATUS_TO_VIEW_REQUEST: "PATCH_UNVIEW_STATUS_TO_VIEW_REQUEST",
  PATCH_UNVIEW_STATUS_TO_VIEW_SUCCESS: "PATCH_UNVIEW_STATUS_TO_VIEW_SUCCESS",
  PATCH_UNVIEW_STATUS_TO_VIEW_FAILURE: "PATCH_UNVIEW_STATUS_TO_VIEW_FAILURE",
};
