import * as Yup from "yup";
export const couponFormSchema = Yup.object().shape({
  couponId: Yup.string().required("Promo Code is required"),
});

export const subscriptionFormSchema = Yup.object().shape({
  houesNo: Yup.string().required("Hosue No/ Name is required"),
  addressLine1: Yup.string().required("Address Line 1 is required"),
  cityTown: Yup.string().required("City/Town is required"),
  postalCode: Yup.string().required("Postal Code is required"),
  phoneNumber: Yup.string()
    .matches(/^0/, "Phone number must start with zero")
    .matches(/^\d*$/, "Phone number must contain only numeric characters")
    .required("Phone number is required"),
});
export const postalFormSchema = Yup.object().shape({
  postalCode: Yup.string().required("Postal Code is required"),
  loading: Yup.boolean(),
});

export const vendorServiceSchema = Yup.object().shape({
  serviceName: Yup.mixed().required("Servie or product name is required"),
  customServiceName: Yup.string()
    .max(35, "Tile too long. It must contain only 35 or less characters.")
    .required("Service Title is required"),
  occassion: Yup.array()
    .of(Yup.mixed().required())
    .min(1, "At least one occasion is required.")
    .required("Occasion is required."),
  numberOfCaters: Yup.string()
    .oneOf([
      "Up to 10 People",
      "10-30 People",
      "30-50 People",
      "Up to 100 People",
      "Up to 200 People",
      "Over 200 People",
      "Over 500 People",
    ])
    .required("Guest limit is required."),
  serviceArea: Yup.array().when("isProvidingServiceAtAllLoc", {
    is: false,
    then: () =>
      Yup.array()
        .of(Yup.string().required())
        .min(1, "At least one service area is required.")
        .required("Service Area is required."),
    else: () => Yup.array().max(0).required(),
  }),
  bookingWeekDays: Yup.array()
    .of(
      Yup.string()
        .oneOf([
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thrusday",
          "Friday",
          "Saturday",
          "Sunday",
        ])
        .required()
    )
    .min(1, "At least one day is selected.")
    .required("Days is required."),
  bookingPeriod: Yup.string()
    .oneOf([
      "Less than a week",
      "1 Week",
      "2 Weeks",
      "3 Weeks",
      "4 Weeks",
      "4 weeks +",
    ])
    .required("Booking notice period is required."),
  description: Yup.string().required("About Service is required"),
  thumbnails: Yup.array()
    .of(Yup.mixed().required())
    .min(1, "At least one thumbnail is required.")
    .required("Thumbnail is required."),
  referalLink: Yup.string(),
  isProvidingServiceAtAllLoc: Yup.boolean(),
});

export const homePageServiceSearch = Yup.object().shape({
  service: Yup.mixed().required(),
});

export const messageSchema = Yup.object().shape({
  message: Yup.string().required(),
});
