import React, { useEffect, useState } from "react";
import "./EventDetail.scss";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Button,
  Container,
  Divider,
  Grid,
  Heading,
  Text,
  Card,
  ImageSlider,
  FormInput,
  GoBackBtn,
  FormText,
} from "../../../components";
import { profile, verified } from "./../../../assests";
import { IoIosBriefcase } from "react-icons/io";
import { BsSuitHeartFill } from "react-icons/bs";
import { HiLocationMarker, HiOutlineUserGroup } from "react-icons/hi";
import { AiOutlineShop } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import {
  GetSingleService,
  GetMyService,
  AddMessageRequest,
  AddToFavorite,
  clearErrors,
  clearMessages,
  addNewView,
  AddToLike,
  createNewChat,
  getAllChats,
  getAllVendorServices2,
} from "./../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { BACKEND_STATIC_URL } from "../../../constants";
import FormSelect from "../../../components/FormInput/FormSelect";
import { MdEventSeat } from "react-icons/md";
import { FaGlobe, FaInstagram, FaLink, FaTimes } from "react-icons/fa";
import styled from "@emotion/styled";
import { useGoogleLoc, useQueryParams } from "../../../hooks";
import { Modal } from "@mui/material";
import { sendNotfication } from "../../../firebase";
import { NOTIFICATION_TYPES } from "../../../firebase/notfication";
import useUser from "../../../hooks/useUser";
import { getChatOfAvailableChats } from "../../../utils/getChat";
import { sendMessageNew } from "../../../firebase/message";
import moment from "moment";

const EventDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [showAllServiceAreaModal, setShowAllServiceAreaModal] = useState(false);
  const [showAllOccationsModal, setShowAllOccationsModal] = useState(false);
  const { singleService, loading, subscriptionLoading } = useSelector(
    (state) => state.vendorReducer
  );

  const { getAllVendorServicesData } = useSelector(
    (s) => s.vendorServices2Reducer
  );

  const user = useUser();

  useEffect(() => {
    if (!id) {
      navigate(-1);
    } else {
      window.scrollTo(0, 0);
      dispatch(GetSingleService(id));
      if (singleService.vendor) {
        dispatch(GetMyService(singleService.vendor.id, 1));
      }
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    const f1 = () => dispatch(addNewView(id));

    const timeout = setTimeout(f1, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (singleService?.vendor?.id) {
      getAllVendorServices2({
        payload: {
          filters: {
            vendor: singleService?.vendor?.id,
            limit: 5000,
            page: 1,
            status: "Accepted",
            deleteStatus: "NULL",
          },
        },
      });
    }
  }, [singleService?.vendor?.id]);

  return (
    <>
      {loading ? (
        <Puff
          height="60"
          width="60"
          radius="6"
          color="black"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
        />
      ) : (
        <StyledEventDetail className="eventdetail">
          <div className="eventdetail-container">
            <Container className="main">
              <Container className="lg">
                <GoBackBtn />
              </Container>
            </Container>
            <div className="m-4">
              <ImageSlider
                thumbnail={
                  singleService?.thumbnails?.length > 0 &&
                  singleService.thumbnails?.map(
                    (t) => `${BACKEND_STATIC_URL}${t}`
                  )
                }
              />
            </div>

            <Container className="main">
              <Container className="lg">
                <div className="grid-side-service">
                  <div className="grid-right-side-service">
                    <Container className="container">
                      <div className="primary-heading-bg center p-1">
                        <Heading className="light">
                          {singleService?.customServiceName ||
                            singleService?.serviceName?.serviceName ||
                            "--"}
                        </Heading>
                      </div>

                      <Container className="lg">
                        <LikeLayout>
                          <div
                            onClick={() =>
                              !subscriptionLoading &&
                              dispatch(
                                AddToLike(
                                  id,
                                  singleService?.like?.includes(user?.id)
                                )
                              )
                            }
                            className="left-like"
                          >
                            <div>
                              <span>Likes</span>
                              <BsSuitHeartFill />
                            </div>
                            <p>
                              {singleService?.like?.length > 0
                                ? singleService.like.length
                                : 0}
                            </p>
                          </div>
                          <div className="right-like">
                            {user && (
                              <Button
                                className="rounded btn-lighter"
                                disabled={subscriptionLoading ? true : false}
                                onClick={() =>
                                  dispatch(
                                    AddToFavorite(
                                      id,
                                      singleService?.favourite?.includes(
                                        user?.id
                                      )
                                    )
                                  )
                                }
                              >
                                {singleService?.favourite?.includes(user?.id)
                                  ? "Remove from Favourite"
                                  : "Add to Favourite"}
                              </Button>
                            )}
                          </div>
                        </LikeLayout>
                      </Container>
                      <Divider />
                      <Container>
                        <Heading className="light">About service</Heading>
                        <Text>
                          {singleService?.description &&
                            singleService.description}
                        </Text>
                      </Container>
                      <Divider />

                      <ServiceAreaAndOccasionLayout>
                        <Container>
                          <ItemHeading icon={<HiLocationMarker />}>
                            Services area
                          </ItemHeading>

                          {singleService?.isProvidingServiceAtAllLoc ? (
                            <Item className="city">All Locations</Item>
                          ) : (
                            singleService?.serviceArea &&
                            singleService.serviceArea
                              ?.slice(0, 4)
                              ?.map((name, i) => (
                                <Item className="city" key={i}>
                                  {name.slice(0, -4)}
                                </Item>
                              ))
                          )}

                          {singleService.serviceArea?.length > 4 && (
                            <StyledButton
                              onClick={() => setShowAllServiceAreaModal(true)}
                            >
                              Show More
                            </StyledButton>
                          )}
                        </Container>
                        <Divider />
                        <Container>
                          <ItemHeading icon={<MdEventSeat />}>
                            Occasions we covered
                          </ItemHeading>

                          {singleService?.occassion &&
                            singleService.occassion
                              ?.slice(0, 4)
                              ?.map((occassion) => (
                                <Item className="city">
                                  {occassion?.occasionName}
                                </Item>
                              ))}

                          {singleService.occassion?.length > 4 && (
                            <StyledButton
                              onClick={() => setShowAllOccationsModal(true)}
                            >
                              Show More
                            </StyledButton>
                          )}
                        </Container>
                      </ServiceAreaAndOccasionLayout>
                      <Divider />

                      <ItemHeading icon={<IoIosBriefcase />}>
                        Available for bookings on
                      </ItemHeading>
                      {singleService?.bookingWeekDays &&
                        getShortString(singleService.bookingWeekDays).map(
                          (item) => <Item className="city">{item}</Item>
                        )}

                      <ItemHeading icon={<IoIosBriefcase />}>
                        Booking notice period
                      </ItemHeading>
                      <Item className="service">
                        {singleService?.bookingPeriod &&
                          singleService.bookingPeriod}
                      </Item>
                      <ItemHeading icon={<HiOutlineUserGroup />}>
                        Guests limits
                      </ItemHeading>
                      <Item className="service">
                        {singleService?.numberOfCaters &&
                          singleService.numberOfCaters}
                      </Item>
                      <Divider />
                      {singleService?.vendor?.instagram && (
                        <>
                          <ItemHeading icon={<FaInstagram />}>
                            Vendor Instagram
                          </ItemHeading>
                          <Item className="service">
                            <a
                              href={
                                singleService?.vendor?.instagram?.includes(
                                  "http"
                                )
                                  ? singleService?.vendor?.instagram
                                  : `https://www.instagram.com/${singleService?.vendor?.instagram}`
                              }
                              style={{ color: "blue" }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {singleService?.vendor?.instagram?.includes(
                                "http"
                              )
                                ? singleService?.vendor?.instagram
                                : `https://www.instagram.com/${singleService?.vendor?.instagram}`}
                            </a>
                          </Item>
                        </>
                      )}
                      {singleService?.vendor?.website?.startsWith("http") && (
                        <>
                          <ItemHeading icon={<FaLink />}>
                            Vendor Website
                          </ItemHeading>
                          <Item className="service">
                            <a
                              href={singleService?.vendor?.website}
                              style={{ color: "blue" }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {singleService?.vendor?.website}
                            </a>
                          </Item>
                        </>
                      )}
                      {singleService?.referalLink?.startsWith("http") && (
                        <>
                          <Divider />
                          <ItemHeading icon={<FaGlobe />}>
                            Visit Website
                          </ItemHeading>
                          <Item className="service">
                            <a
                              href={singleService?.referalLink}
                              style={{ color: "blue" }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {singleService?.referalLink}
                            </a>
                          </Item>
                        </>
                      )}
                    </Container>
                  </div>

                  {/* Right Side */}

                  <div className="grid-left-side-service">
                    <div className="primary-heading-bg center p-1">
                      <Heading className="light">Enquiry to vendor</Heading>
                    </div>
                    <br />
                    <br />

                    <div className="request-form">
                      <div className="request-form-profile">
                        <div className="request-form-profile-name">
                          <Link
                            to={`/vender-shop/${singleService?.vendor?.id}`}
                          >
                            <img
                              src={
                                singleService?.vendor?.photoPath
                                  ? `${BACKEND_STATIC_URL}${singleService.vendor.photoPath}`
                                  : profile
                              }
                              alt="profile"
                            />
                            <div className="request-form-profile-name-section">
                              <h4>
                                <span>
                                  {singleService?.vendor?.fullName &&
                                    singleService.vendor.fullName}
                                </span>{" "}
                                {singleService?.vendor?.verfied && (
                                  <img src={verified} alt="verify" />
                                )}
                              </h4>
                            </div>
                          </Link>
                        </div>
                        <div className="request-form-profile-icon">
                          <Link
                            to={`/vender-shop/${singleService?.vendor?.id}`}
                          >
                            <AiOutlineShop />
                          </Link>
                        </div>
                      </div>

                      <div className="request-form-content">
                        <RequestForm />
                      </div>
                    </div>
                  </div>
                </div>
              </Container>

              {/* Other Services Section  */}
              {getAllVendorServicesData.results?.filter &&
                getAllVendorServicesData.results?.filter(
                  (s) => s.id !== singleService.id
                )?.length !== 0 && (
                  <>
                    <div className="m-3">
                      <Container className="main">
                        <Container className="half">
                          <Divider />
                        </Container>
                      </Container>
                    </div>
                    <Container className="main">
                      <Heading className="center primary m-4">
                        Other Services By This Vendor
                      </Heading>
                      <Container className="lg">
                        <Grid className="grid-3 m-4">
                          {getAllVendorServicesData.results
                            ?.filter((s) => s.id !== singleService.id)
                            ?.map((data, ind) => {
                              return (
                                <Card
                                  key={ind}
                                  image={
                                    data.thumbnails &&
                                    `${BACKEND_STATIC_URL}${data.thumbnails[0]}`
                                  }
                                  name={
                                    data?.customServiceName ||
                                    data.serviceName.serviceName ||
                                    "--"
                                  }
                                  description={
                                    data?.description && data.description
                                  }
                                  likes={data?.like?.length}
                                  views={data?.views?.length}
                                  id={data.id}
                                  showFalse
                                  verified={singleService?.vendor?.verfied}
                                />
                              );
                            })}
                        </Grid>
                      </Container>
                    </Container>
                  </>
                )}
            </Container>
          </div>
        </StyledEventDetail>
      )}

      <StyledModal
        open={showAllServiceAreaModal}
        onClose={() => setShowAllServiceAreaModal(false)}
      >
        <div className="modal-safd2">
          <h2>Services Area List</h2>
          <div className="list">
            {singleService?.serviceArea &&
              singleService.serviceArea?.map((name) => (
                <Item className="city">{name.slice(0, -4)}</Item>
              ))}
          </div>
          <button onClick={() => setShowAllServiceAreaModal(false)}>
            <FaTimes />
          </button>
        </div>
      </StyledModal>

      <StyledModal
        open={showAllOccationsModal}
        onClose={() => setShowAllOccationsModal(false)}
      >
        <div className="modal-safd2">
          <h2>Services Area List</h2>
          <div className="list">
            {singleService?.occassion &&
              singleService.occassion?.map((occassion) => (
                <Item className="city"> {occassion?.occasionName}</Item>
              ))}
          </div>
          <button onClick={() => setShowAllOccationsModal(false)}>
            <FaTimes />
          </button>
        </div>
      </StyledModal>
    </>
  );
};

export default EventDetail;

// Sub Component
const Item = (props) => {
  return (
    <>
      <div className={`item-flex-${props.className} align-item-center m-1`}>
        <span />
        <Text>{props.children}</Text>
      </div>
    </>
  );
};

const ItemHeading = (props) => {
  return (
    <>
      <div className="service-area-heading align-item-center">
        {props.icon}
        <Heading className="secondry">{props.children}</Heading>
      </div>
    </>
  );
};

const StoreValuesToStorage = ({ values }) => {
  useEffect(() => {
    return () => {
      localStorage.setItem("customer-request", JSON.stringify(values));
    };
  }, [values]);
  return <></>;
};

const validation = Yup.object({
  date: Yup.date()
    .min(new Date(Date.now()), "Date must be in future.")
    .required("Date is required"),
  location: Yup.string().required("Location is required"),
  phone: Yup.string()
    .matches(/^0/, "Phone number must start with zero")
    .matches(/^\d*$/, "Phone number must contain only numeric characters")
    .optional(),
  message: Yup.string()
    .required("Message is required")
    .min(2, "Must be 5 character")
    .max(200, "Must be 200 characters or less"),
});

const RequestForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { saved } = useQueryParams({
    saved: false,
  });
  const { message, singleService, errors } = useSelector(
    (state) => state.vendorReducer
  );

  const {
    message: customerMessage,
    errors: customerErrors,
    loading: customerLoading,
  } = useSelector((state) => state.customerReducer);

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (customerErrors.length > 0) {
      toast.error(customerErrors);
      dispatch(clearErrors());
    }
    if (message !== "") {
      toast.success(message);
      dispatch(clearMessages());
    }
    if (customerMessage !== "") {
      toast.success(customerMessage);
      dispatch(clearMessages());
    }
  }, [errors, message, customerMessage, customerErrors, dispatch]);

  const user = useUser();
  const customer = useUser();
  const { pathname } = useLocation();

  const createChatWithVendor = ({ userId, onSuccess }) => {
    createNewChat({
      payload: {
        body: {
          participants: [
            {
              detail: userId,
              docModel: "User",
            },
          ],
        },
      },
      onSuccess,
    });
  };

  const sendMessage = ({ onSuccess, message }) => {
    const chat = getChatOfAvailableChats(user?.id, singleService?.vendor?.id);

    const sendMessage = (chatId) => {
      sendMessageNew({
        message,
        chatId,
        receiverId: singleService?.vendor?.id,
        senderId: user?.id,
      });
    };

    if (chat) {
      sendMessage(chat?._id);

      onSuccess && onSuccess();
    } else {
      createChatWithVendor({
        userId: singleService?.vendor?.id,
        onSuccess: (chat) => {
          sendMessage(chat?._id);
          onSuccess && onSuccess();
          getAllChats();
        },
      });
    }
  };

  return (
    <Formik
      initialValues={{
        date: "",
        location: "",
        phone: "",
        message: "",

        ...(saved
          ? JSON.parse(localStorage.getItem("customer-request") || "{}")
          : {}),
      }}
      validateOnMount
      validationSchema={validation}
      onSubmit={(values, { resetForm }) => {
        if (!user) {
          navigate(`/customersignin?next=${pathname}?saved=true`, {
            replace: true,
          });
          toast.error("Please login in your customer account");
          return;
        } else if (!customer) {
          toast.error("Only customer can create request.!");
          return;
        }
        const { date, location, phone, message } = values;
        let finalResult = {
          date,
          location,
          phoneNumber: phone,
          message,
        };
        dispatch(
          AddMessageRequest(finalResult, singleService?.id, ({ id }) => {
            sendNotfication({
              message: "New customer was created!",
              notficationType: NOTIFICATION_TYPES.CUSTOMER_REQUEST,
              productId: id,
              receiverId: singleService?.vendor?.id,
              senderId: user?.id,
            });

            sendMessage({
              message: `Enquiry for ${
                singleService?.serviceName?.serviceName
              } service on ${moment(date)?.format(
                "MMMM D, YYYY"
              )} at ${location}.\n\nMore Detail:\n    ${message}`,
            });
          })
        );
        navigate(pathname, { replace: true });
        resetForm({ values: "" });
      }}
    >
      {(formik) => (
        <Form>
          <StoreValuesToStorage values={formik.values} />
          <FormInput
            label="Date"
            name="date"
            type="date"
            className="bg-white"
          />
          {singleService?.isProvidingServiceAtAllLoc ? (
            <GoogleLocationInput formik={formik} />
          ) : (
            <FormSelect
              label="Location"
              name="location"
              type="text"
              className="bg-white"
            >
              <option disabled value="">
                Select Location
              </option>
              {singleService?.serviceArea &&
                singleService.serviceArea?.map((name) => (
                  <option value={name}>{name.slice(0, -4)}</option>
                ))}
            </FormSelect>
          )}
          <FormInput
            label="Phone number (optional)"
            name="phone"
            type="text"
            className="bg-white"
          />
          <FormText
            label="Message Box"
            text="text"
            name="message"
            cols="10"
            rows="10"
            className="bg-white"
          />

          <div
            className="request-btn m-5"
            disabled={customerLoading ? true : false}
          >
            <button type="submit">
              {customerLoading ? "Please wait..." : "Message vendor"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const GoogleLocationInput = ({ formik }) => {
  const inputRef = useGoogleLoc((place) => {
    formik.setFieldValue("location", place.formatted_address);
  });

  console.log(formik);

  useEffect(() => {
    if (formik.isSubmitting && formik.isValid) inputRef.current.value = "";
  }, [formik.isSubmitting, formik.isValid, inputRef]);

  return (
    <div className="forminput-container">
      <label htmlFor="location" className="form-label">
        Location
      </label>
      <input
        ref={inputRef}
        placeholder="Enter Location"
        type="text"
        className={`form-input bg-white ${
          formik.touched?.location && formik.errors.location && "is-invalid"
        }`}
        autoComplete="off"
      />
      <ErrorMessage component="div" name="location" className="form-error" />
    </div>
  );
};

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  color: #ffc841;
  font-weight: 600;
  display: inline-block;
  margin-left: 2.7rem;

  cursor: pointer;
  font-size: 1.6rem;
  font-family: "Montserrat", sans-serif;

  &:hover {
    opacity: 0.5;
  }
`;

const StyledModal = styled(Modal)`
  display: grid;
  place-items: center;

  & .MuiBackdrop-root {
    backdrop-filter: blur(6px);
  }

  & > .modal-safd2 {
    background-color: #fff;
    max-width: 90vw;
    width: 70rem;
    position: relative;
    padding: 3rem 2rem;
    border-radius: 1.2rem;
    display: grid;
    gap: 2rem;
    border: 0.4rem solid #ffc841;

    & > h2 {
      font-size: 3rem;
      color: rgba(0, 0, 0, 0.9);
      font-weight: 500;
    }

    & > div {
      max-height: calc(95svh - 6rem - 4rem);
      overflow: auto;
    }

    & > button {
      position: absolute;
      top: 2rem;
      right: 2rem;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 1.6rem;

      &:hover {
        opacity: 0.5;
      }
    }
  }
`;

const ServiceAreaAndOccasionLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 2rem;

  & > :nth-child(2) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    gap: 0rem;
    grid-template-columns: 1fr;
    & > :nth-child(2) {
      display: block;
    }
  }
`;

const LikeLayout = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;

  & > .left-like {
    cursor: pointer;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;

    & > div {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 1rem;
      font-size: 1.6rem;
      font-weight: 500;

      & > svg {
        line-height: 0;
      }
    }

    & > p {
    }
  }
`;

const StyledEventDetail = styled.div`
  & .container-lg {
    margin: 5rem auto;
  }
`;

function getShortString(days = []) {
  const allDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thrusday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const i = (index) => days.includes(allDays[index]);
  if (i(0) && i(1) && i(2) && i(3) && i(4) && i(5) && i(6)) {
    return ["Mon-Sun"];
  } else if (i(0) && i(1) && i(2) && i(3) && i(4) && i(5)) {
    return ["Mon-Sat"];
  } else if (i(0) && i(1) && i(2) && i(3) && i(4) && i(6)) {
    return ["Mon-Fri", "Sunday"];
  } else if (i(0) && i(1) && i(2) && i(3) && i(4) && !i(5) && !i(6)) {
    return ["Mon-Fri"];
  } else if (!i(3)) {
    const arr = [];
    if (i(0) && i(1) && i(2)) {
      arr.push("Mon-Wed");
    } else {
      i(0) && arr.push(allDays[0]);
      i(1) && arr.push(allDays[1]);
      i(2) && arr.push(allDays[2]);
    }

    if (i(4) && i(5) && i(6)) {
      arr.push("Fri-Sun");
    } else {
      i(4) && arr.push(allDays[4]);
      i(5) && arr.push(allDays[5]);
      i(6) && arr.push(allDays[6]);
    }
    return arr;
  }

  return days;
}
