import { servicesTypesConstant } from "../constants";


const initialState = {
    servicesTypes: [],
}
const servicesTypesReducer = (state = initialState, action) => {
    switch (action.type) {
        case servicesTypesConstant.GET_ALL_SERVICES_TYPES_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case servicesTypesConstant.GET_ALL_SERVICES_TYPES_SUCCESS:
            console.log("Payload");
            console.log(action.payload);
            return {
                ...state,
                loading: false,
                servicesTypes: action.payload.map((servicesType)=>{
                    return {
                        ...servicesType,
                        name:servicesType.serviceName,
                        image: servicesType.serviceImage,
                        to:`/${servicesType.serviceName.replace(/\s/g, "")}`
                    }
                }),
            };

        case servicesTypesConstant.GET_ALL_SERVICES_TYPES_FAILURE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
}

export default servicesTypesReducer;