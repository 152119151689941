import { chatContants } from "./../constants";

const initialState = {
  getAllChatsData: {
    data: [],
    loading: false,
  },

  createNewChatData: {
    loading: false,
  },

  createNewChatWithAdminsData: {
    loading: false,
  },

  allMessages: [],
};

const chatReducer = (s = initialState, action) => {
  const state = { ...s };

  switch (action.type) {
    case chatContants.GET_ALL_CHATS.pending:
      state.getAllChatsData.loading = true;
      break;

    case chatContants.GET_ALL_CHATS.success:
    case chatContants.GET_ALL_CHATS.error:
      state.getAllChatsData.loading = false;
      state.getAllChatsData.data = action.payload;
      break;

    case chatContants.POST_NEW_CHAT.pending:
      state.createNewChatData.loading = true;
      break;

    case chatContants.POST_NEW_CHAT.success:
    case chatContants.POST_NEW_CHAT.error:
      state.createNewChatData.loading = false;
      break;

    case chatContants.POST_CHATS_WITH_ADMINS.pending:
      state.createNewChatWithAdminsData.loading = true;
      break;

    case chatContants.POST_CHATS_WITH_ADMINS.success:
    case chatContants.POST_CHATS_WITH_ADMINS.error:
      state.createNewChatWithAdminsData.loading = false;
      break;

    case chatContants.UPDATE_MESSAGE:
      state.allMessages = action.payload;
      break;

    default:
      break;
  }

  return state;
};

export default chatReducer;
