// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aboutus-content > * {
  margin: 3rem 0rem; }

.aboutus-content > img {
  width: 99%; }

.stats > h1 {
  font-size: 5rem;
  font-weight: 800;
  color: #ffc841; }

.stats > h4 {
  font-size: 1.8rem;
  color: #4c4d4e; }

.aboutus-img {
  height: 52.4rem;
  border-radius: 48px;
  object-fit: cover; }
`, "",{"version":3,"sources":["webpack://./src/module/customer/aboutUsPage/AboutUsPage.scss","webpack://./src/scss/_variable.scss"],"names":[],"mappings":"AAGG;EAEG,iBAAiB,EAAA;;AAFpB;EAKG,UAAU,EAAA;;AAKhB;EAEI,eAAe;EACf,gBAAgB;EAChB,cCjBa,EAAA;;ADajB;EAOI,iBAAiB;EACjB,cCVqB,EAAA;;ADazB;EACE,eAAe;EACf,mBAAmB;EACnB,iBAAiB,EAAA","sourcesContent":["@import \"../../../scss/variable\";\n\n.aboutus {\n  &-content {\n    & > * {\n      margin: 3rem 0rem;\n    }\n    & > img {\n      width: 99%;\n    }\n  }\n}\n\n.stats {\n  & > h1 {\n    font-size: 5rem;\n    font-weight: 800;\n    color: $primary;\n  }\n  & > h4 {\n    font-size: 1.8rem;\n    color: $heading-primary;\n  }\n}\n.aboutus-img {\n  height: 52.4rem;\n  border-radius: 48px;\n  object-fit: cover;\n}\n","$primary: #ffc841;\n$primary-hover: #cda814;\n\n$light-gray: #f2f1f1;\n$gray: #838282;\n\n$white: #ffffff;\n$sidebar-background: #212121;\n$footer_background: black;\n\n// Typo Graphy\n$heading-primary: #4c4d4e;\n\n$black: black;\n\n$dorger: #e0fff9;\n\n$bronze: #603b30;\n$sliver: #c5c5c5;\n$gold: #c57934;\n$platinium: #2d2d2d;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
