import styled from "@emotion/styled";

export const BottomStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 5rem;
  align-items: center;
  width: 100%;
  grid-column: span 2;

  @media screen and (max-width: 658px) {
    grid-column: span 1;
  }

  & > .graph-container {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;

    & > h5 {
      font-size: 2.4rem;
      font-weight: 300;
    }

    & > .container-line-chart {
      display: grid;
      grid-template-columns: 1fr;
      overflow: auto;

      & > .dash-chart {
        width: 100%;
        height: 40rem;
        min-width: 60rem;
      }
    }
  }
`;
