import React, { useEffect, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { SideMenuBar, Footer, Navbar } from "../../components/common";
import { venderRoutes } from "../../data/routesData";
import { useSelector } from "react-redux";
import { NOTIFICATION_TYPES } from "../../firebase/notfication";
import useUser from "../../hooks/useUser";

const VenderLayout = () => {
  const location = useLocation();
  const vendorUser = useUser();

  const { allMessages } = useSelector((s) => s.chatReducer);
  const { allNotifications } = useSelector((s) => s.notificationReducer);
  const { getWillAllMyServiceDeleteData } = useSelector((s) => s.vendorReducer);

  const routes = useMemo(() => {
    return venderRoutes.map((route) => {
      switch (route.path) {
        case "/chat":
          route.notification = allMessages?.filter(
            (m) => m.receiverId === vendorUser?.id && !m.isSeen
          ).length;
          return route;
        case "/my-services":
          route.notification = route.notification = allNotifications?.filter(
            (n) => n.notficationType === NOTIFICATION_TYPES.SERVICE
          ).length;
          route.showAlert = Boolean(
            getWillAllMyServiceDeleteData?.data?.willDelete
          );
          return route;
        case "/customer-request":
          route.notification = allNotifications?.filter(
            (n) => n.notficationType === NOTIFICATION_TYPES.CUSTOMER_REQUEST
          ).length;
          return route;

        default:
          return route;
      }
    });
  }, [
    allMessages,
    allNotifications,
    getWillAllMyServiceDeleteData?.data?.willDelete,
    vendorUser?.id,
  ]);
  return (
    <>
      <div className="customerdashboardlayout">
        <div className="customerdashboardlayout-container">
          <SideMenuBar
            routes={routes}
            vendor={vendorUser?.fullName && vendorUser?.fullName}
          />
          <div className="customerdashboardlayout-container-content">
            <div className="customerdashboardlayout-container-content-nav">
              <Navbar />
            </div>
            <div className="customerdashboardlayout-container-content-outlet">
              <Outlet />
            </div>
            {location.pathname !== "/vender-message" && <Footer />}
          </div>
        </div>
      </div>
    </>
  );
};

export default VenderLayout;
