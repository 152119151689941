import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { logo } from "../../../assests";
import { Heading, FormInput, Button } from "../../../components";
import toast from "react-hot-toast";
import {
  Resetpassword,
  clearErrors,
  clearMessages,
} from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const ResetPasswordComponent = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message, errors, loading } = useSelector(
    (state) => state.vendorAuthReducer
  );
  console.log("state is", state);
  const validation = Yup.object({
    otp: Yup.string().required("OTP is required"),
    newPassword: Yup.string()
      .required("New Password is required")
      .min(8, "New Password should be minimum 8 characters long"),
  });

  useEffect(() => {
    if (!state?.email || !state?.role) {
      navigate(-1);
    }
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/"), 2000);
    }
  }, [errors, message]);

  return (
    <>
      <div className="changepassword">
        <div className="changepassword-container">
          <img
            src={logo}
            alt="logo"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          />
          <Heading className="secondry center m-1">Reset Password</Heading>

          <div className="m-2">
            <Formik
              initialValues={{
                otp: "",
                newPassword: "",
              }}
              validateOnMount
              validationSchema={validation}
              onSubmit={(values) => {
                values["email"] = state?.email;
                values["role"] = state?.role;
                dispatch(Resetpassword(values));
              }}
            >
              {(formik) => (
                <div className="m-3">
                  <Form>
                    <FormInput
                      place="Enter otp to reset password"
                      name="otp"
                      type="number"
                    />
                    <FormInput
                      place="Enter New password"
                      name="newPassword"
                      type="password"
                    />
                    <div className="save-btn">
                      <Button
                        className="rounded center m-2"
                        disabled={loading ? true : false}
                      >
                        {loading ? "Please wait..." : "Reset"}
                      </Button>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordComponent;
