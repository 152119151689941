import { useState, useEffect, useContext } from "react";
import {
  Card,
  Container,
  GoBackBtn,
  Grid,
  Selector,
} from "../../../components";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import {
  GetService,
  clearErrors,
  clearMessages,
} from "./../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { servicesData } from "../../../data/serviceData";
import { OccasionContext } from "../../../contexts/OccasionContext";
import ServicesSelector from "../../../components/Services selctor/ServicesSelector";
import { ServicesContext } from "../../../contexts/ServicesContext";

const SearchPage = () => {
  const { selectedOccasion, setSelectedOccasion } = useContext(OccasionContext);
  const { selectedService, setSelectedService } = useContext(ServicesContext);
  const { id, heading, city } = useParams();
  useEffect(() => {
    if (heading == "name") {
      setSelectedService(id);
    } else {
      setSelectedOccasion(id);
    }
  });

  const dispatch = useDispatch();
  const { message, details, errors, loading } = useSelector(
    (state) => state.vendorReducer
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id && heading) {
      if (city) {
        let result = { heading: heading, value: id };
        dispatch(GetService(result, city));
      } else {
        let result = { heading: heading, value: id };
        dispatch(GetService(result));
      }
    }
  }, []);

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
    }
  }, [errors, message]);

  useEffect(() => {
    if (selectedService != "Choose Service") {
      let result = { heading: "name", value: selectedService };
      dispatch(GetService(result));
    }
  }, [selectedService]);

  return (
    <>
      <div className="searchpage">
        <Container className="main">
          <Container className="lg">
            <GoBackBtn />

            <div className="space-between">
              <div>
                <h4 style={{ fontSize: "1.4rem", fontWeight: "500" }}>
                  {/* 200 Search */}
                </h4>
              </div>
              <div className="align-item-right">
                <div className="search-service-selector">
                  <ServicesSelector
                    selected={selectedService}
                    setSelected={setSelectedService}
                    data={servicesData}
                  />
                </div>
              </div>
            </div>
            <Grid className="grid-3 m-5">
              {loading ? (
                <Puff
                  height="60"
                  width="60"
                  radius="6"
                  color="black"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                />
              ) : details.length > 0 ? (
                details.map((data, ind) => {
                  return (
                    <Card
                      key={ind}
                      image={data?.thumbnails && data.thumbnails[0]}
                      name={
                        heading === "occassion" ? data?.occassion : data?.name
                      }
                      description={data?.description && data.description}
                      views={
                        data?.favourite?.length > 0
                          ? data?.favourite?.length
                          : 0
                      }
                      id={data.id}
                      verified={data?.vendor?.verfied}
                    />
                  );
                })
              ) : (
                <h1>No record found</h1>
              )}
            </Grid>
          </Container>
        </Container>
      </div>
    </>
  );
};

export default SearchPage;
