import { postUploadFile } from "../store/actions";

export default function uploadFilesFromObj(
  data = {}, // any object
  accept = [] // [".png", ".jpg"],
) {
  const [files, path] = findFilesAndPaths(data, accept);

  return new Promise((resolve, reject) => {
    if (files.length === 0) return resolve(data);

    postUploadFile({
      payload: {
        files,
      },
      onSuccess: (d) => {
        const newData = replaceFilesInObject(d, path, data);
        resolve(newData);
      },
      onError: (err) => {
        reject(err);
      },
    });
  });
}

function findFilesAndPaths(data = {}, accepts = []) {
  const files = [];
  const paths = [];

  function recurse(obj, path = []) {
    if (Array.isArray(obj)) {
      obj.forEach((value, index) => {
        recurse(value, [...path, index]);
      });
    } else if (
      obj instanceof File &&
      accepts.includes(obj?.type?.split("/")[1])
    ) {
      files.push(obj);
      paths.push(path);
    } else if (typeof obj === "object" && obj !== null) {
      Object.entries(obj).forEach(([key, value]) =>
        recurse(value, [...path, key])
      );
    }
  }

  recurse(data, []);
  return [files, paths];
}

function replaceFilesInObject(files, paths, newData = {}) {
  for (let i = 0; i < files.length; i++) {
    const path = paths[i];
    const file = files[i];

    let currentObj = newData;
    for (let j = 0; j < path.length - 1; j++) {
      const key = path[j];
      if (!currentObj[key]) {
        currentObj[key] = Array.isArray(path[j + 1]) ? [] : {};
      }
      currentObj = currentObj[key];
    }

    const lastKey = path[path.length - 1];
    currentObj[lastKey] = file;
  }

  return newData;
}
