export const getMonthsFromToday = (numberOfMonths = 0) => {
  const today = new Date();

  const sixMonthsFromToday = new Date(today);
  sixMonthsFromToday.setMonth(sixMonthsFromToday.getMonth() + numberOfMonths);

  const year = sixMonthsFromToday.getFullYear();
  const month = (sixMonthsFromToday.getMonth() + 1).toString().padStart(2, "0");
  const day = sixMonthsFromToday.getDate().toString().padStart(2, "0");

  return new Date(`${year}-${month}-${day}`).toLocaleDateString();
};
