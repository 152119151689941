import { useState, useEffect } from "react";
import "./SideMenuBar.scss";
import { Link, NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { VscMenu } from "react-icons/vsc";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOut, clearErrors, clearMessages } from "./../../../store/actions";
import styled from "@emotion/styled";
import { reGetUserDetails2 } from "../../../store/actions/user.action";
import { PiWarningOctagonFill } from "react-icons/pi";

const SideMenuBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [active, setactive] = useState(window.location.pathname);
  const toggle = () => setIsOpen(!isOpen);
  const [display, setDisplay] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = window.innerWidth <= 768;
  const { message, errors, loading } = useSelector(
    (state) => state.customerAuthReducer
  );
  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
      // setTimeout(() => navigate("/"));
    }
  }, [errors, message]);

  const handleLogOut = () => {
    setDisplay(!display);
    dispatch(
      logOut(() => {
        navigate("/");
        toast.success("Logout!");
        reGetUserDetails2();
        dispatch(clearMessages());
      })
    );
  };

  return (
    <>
      <div className="sidebar">
        <motion.div
          animate={{
            width: isOpen ? "220px" : "45px",
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
        >
          <div className="sidebar-container">
            <div className="sidebar-container-name align-item-center">
              <div className="sidebar-container-name-icon align-item-center">
                <VscMenu onClick={toggle} style={{ cursor: "pointer" }} />
              </div>
              <div className="sidebar-container-name-user align-item-center">
                {isOpen ? (
                  <h6>
                    {props.customer
                      ? props.customer
                      : props.vendor
                      ? props.vendor
                      : ""}
                  </h6>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* Menu Item  */}
            <div className="sidebar-container-menu">
              <div className="sidebar-container-menu-list">
                {props.routes.map((item) => {
                  return (
                    <NavLink
                      key={item.id}
                      to={item.path}
                      onClick={() => {
                        {
                          isMobile && toggle();
                        }
                        setactive(item.path);
                      }}
                    >
                      {({ isActive }) => (
                        <SideBarItemStyled
                          isActive={isActive}
                          isNotification={Boolean(item.notification)}
                          showAlert={item.showAlert}
                          isOpen={isOpen}
                        >
                          <span className="icon">{item.icon}</span>

                          {isOpen && <p>{item.name}</p>}
                          {isOpen && Boolean(item.notification) && (
                            <span className="notification">
                              {item.notification}
                            </span>
                          )}
                          {isOpen && Boolean(item.showAlert) && (
                            <span className="alert">
                              <PiWarningOctagonFill />
                            </span>
                          )}
                        </SideBarItemStyled>
                      )}
                    </NavLink>
                  );
                })}
              </div>
              {isOpen ? (
                <button
                  className="logout-btn-sidebar uppercase"
                  onClick={() => handleLogOut()}
                >
                  {loading ? "logouting..." : "logout"}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default SideMenuBar;

const SideBarItemStyled = styled.div`
  &,
  & * {
    box-sizing: border-box;
  }

  display: grid;
  padding-bottom: 2rem;

  ${(p) =>
    !p.isOpen
      ? "grid-template-columns: 1fr;"
      : p.isNotification && p.showAlert
      ? "grid-template-columns: auto 1fr auto auto;"
      : p.isNotification || p.showAlert
      ? "grid-template-columns: auto 1fr auto;"
      : "grid-template-columns: auto 1fr;"};

  gap: 1rem;
  ${(p) => (p.isActive ? "color: #FFC841;" : "color : #808080;")}
  align-items: center;

  & > .icon {
    color: #ffc841;
    font-size: 1.8rem;
  }

  & > p {
    font-family: Montserrat;
    font-size: 1.4rem;
    line-height: 2.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & > .notification {
    font-size: 1.2rem;
    background-color: #f23d33;
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    display: grid;
    place-items: center;
    color: white;
    font-weight: bold;
  }

  & > .alert {
    display: inline-block;
    line-height: 0;
    color: #f23d33;
    font-size: 2rem;
    animation: pulse 500ms infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.5);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;
