import React, { useRef, useState, useEffect } from "react";
import "./EditProfile.scss";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { profile } from "../../../../assests";
import { Container, Heading, Button, FormInput } from "../../../../components";
import { MdModeEdit } from "react-icons/md";
import toast from "react-hot-toast";
import {
  EditCustomerProfile,
  clearErrors,
  clearMessages,
} from "./../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { BACKEND_STATIC_URL } from "../../../../constants";
import useUser from "../../../../hooks/useUser";
import { reGetUserDetails2 } from "../../../../store/actions/user.action";

const EditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message, errors, loading } = useSelector(
    (state) => state.customerReducer
  );
  const [image, setImage] = useState(null);
  const [customerImage, setCustomerImage] = useState(null);
  const imageRef = useRef();

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setCustomerImage(event.target.files[0]);
    } else {
      setCustomerImage(null);
    }
  };

  const validation = Yup.object({
    name: Yup.string()
      .min(2, "Must be 2 character")
      .max(30, "Must be 30 characters or less")
      .required("Name is required"),
    phone: Yup.string()
      .matches(/^0/, "Phone number must start with zero")
      .matches(/^\d*$/, "Phone number must contain only numeric characters")
      .required("Contact number is required"),
  });

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/customer-settings"), 2000);
    }
  }, [errors, message]);

  useEffect(() => {
    if (customerImage) {
      setImage(URL.createObjectURL(customerImage));
    } else {
      setImage(null);
    }
  }, [customerImage]);

  const customerUser = useUser();

  return (
    <>
      <Container className="half">
        <div className="edit-profile m-5">
          <Heading className="primary center m-5">Edit Profile</Heading>
          <div className="edit-profile-image center">
            <img
              src={
                image
                  ? image
                  : customerUser?.photoPath
                  ? `${BACKEND_STATIC_URL}${customerUser?.photoPath}`
                  : profile
              }
              alt="profile"
            />
            <MdModeEdit onClick={() => imageRef.current.click()} />
          </div>
          <div style={{ display: "none" }}>
            <input
              type="file"
              name="myImage"
              ref={imageRef}
              onChange={onImageChange}
            />
          </div>

          <div className="m-4">
            <Formik
              initialValues={{
                name: customerUser?.fullName ? customerUser?.fullName : "",
                phone: customerUser?.phone ? customerUser?.phone : "",
              }}
              validateOnMount
              validationSchema={validation}
              onSubmit={(values) => {
                let result = new FormData();
                result.append("fullName", values.name);
                result.append("phone", values.phone);
                if (customerImage) {
                  result.append("photoPath", customerImage);
                }
                dispatch(
                  EditCustomerProfile(result, () => {
                    reGetUserDetails2();
                  })
                );
              }}
            >
              {(formik) => (
                <div className="m-5">
                  <Form>
                    <FormInput place="Name" name="name" type="text" />

                    <FormInput place="Phone number" name="phone" type="text" />
                    <div className="save-btn">
                      <Button
                        disabled={!formik.isValid}
                        className="btn-lighter rounded center m-2"
                      >
                        {loading ? "Please wait..." : "Update"}
                      </Button>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </>
  );
};

export default EditProfile;
