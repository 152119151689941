import { useState, useEffect, useRef } from "react";
import "./VerificationForm.scss";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { RiUpload2Fill } from "react-icons/ri";
import toast from "react-hot-toast";
import {
  CreateVendorVerfication,
  clearErrors,
  clearMessages,
  getLoggedInVendor,
} from "./../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Heading,
  FormInput,
  Button,
  FormText,
  Grid,
} from "../../../../components";
import { CenteredLoader } from "../Chat/Chat";

const validation = Yup.object({
  yearOfExperience: Yup.number()
    .typeError("Year of Experience must be a number")
    .required("Year of Experience is required")
    .min(0, "Year of Experience must be at least 0")
    .max(49, "Year of Experience must be less than 50"),
  customerTestimonial: Yup.string()
    .required("This field is required")
    .oneOf(["Yes", "No"], 'Please enter either "Yes" or "No"'),
  customerTestimonialText: Yup.string().when("customerTestimonial", {
    is: "Yes",
    then: Yup.string().required(),
    otherwise: Yup.string().optional(),
  }),
  instagram: Yup.string()
    .required("Instagram URL is required")
    .min(2, "Must be 2 character")
    .max(200, "Must be 200 characters or less")
    .trim()
    .url("Invalid URL")
    .matches(
      /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9._?]{1,200}\/?/,
      "Invalid Instagram profile link"
    ),
  facebook: Yup.string().required("Facebook URL is required"),
  // .min(2, "Must be 2 character")
  // .max(200, "Must be 200 characters or less")
  // .trim()
  // .url("Invalid URL")
  // .matches(
  //   /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9._?]{1,200}\/?/,
  //   "Invalid Facebook profile link"
  // ),
  webiste: Yup.string().required("Website URL is required"),
  // .min(2, "Must be 2 character")
  // .max(200, "Must be 200 characters or less")
  // .trim()
  // .url("Invalid URL"),
  apply_message: Yup.string()
    .required("Required")
    .min(10, "Must be 10 character")
    .max(500, "Must be 500 characters or less"),
});

const VerificationForm = () => {
  const [image, setImage] = useState();
  const [imageAsFile, setimageAsFile] = useState(null);
  const imageRef = useRef();
  const dispatch = useDispatch();
  const { getLoggedInUserData } = useSelector((s) => s.vendorAuthReducer);
  const { message, errors, loading } = useSelector(
    (state) => state.vendorReducer
  );

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };

      reader.readAsDataURL(img);
      setimageAsFile(img);
    }
  };

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message) {
      toast.success(message);
      dispatch(clearMessages());
    }
  }, [errors, dispatch, message]);

  useEffect(() => {
    dispatch(getLoggedInVendor());
  }, [dispatch]);
  return getLoggedInUserData?.loading ? (
    <CenteredLoader />
  ) : (
    <>
      <div className="m-5">
        <Container className="sm">
          <Heading className="m-5 center">Apply for Verification</Heading>

          <div>
            <Formik
              initialValues={{
                yearOfExperience: 0,
                customerTestimonialText: "",
                customerTestimonial: "No",
                instagram: "",
                facebook: "",
                webiste: "",
                apply_message: "",
              }}
              validateOnMount
              validationSchema={validation}
              onSubmit={(values, { resetForm }) => {
                if (!imageAsFile) {
                  toast.error("Please upload an image");
                  return;
                }
                const finalResult = new FormData();
                finalResult.append("yearOfExperience", values.yearOfExperience);
                finalResult.append(
                  "customerTestimonial",
                  values.customerTestimonialText
                );
                finalResult.append(
                  "socialLinks",
                  JSON.stringify({
                    facebook: values.facebook,
                    instagram: values.instagram,
                    webiste: values.webiste,
                  })
                );
                finalResult.append("images", imageAsFile);
                finalResult.append("description", values.apply_message);
                dispatch(CreateVendorVerfication(finalResult));

                resetForm();
                imageRef.current.value = "";
                setimageAsFile(null);
                setImage(null);
              }}
            >
              {(formik) => (
                <div className="m-5">
                  <Form>
                    {/* <FormInput
                      label="Street Address"
                      name="address"
                      type="text"
                    /> */}
                    <FormInput
                      label="How long have you been working for? (in years)"
                      name="yearOfExperience"
                      type="number"
                    />

                    <div className="select-yes-or-no">
                      <label>Do you have any customer testimonials?</label>

                      <Field as="select" name="customerTestimonial">
                        <option value="">Select an option</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Field>
                    </div>

                    {formik.values?.customerTestimonial === "Yes" && (
                      <FormInput
                        label="What is that Customer Testimonial?"
                        name="customerTestimonialText"
                        type="text"
                      />
                    )}

                    <Heading className="center light m-5">Social Links</Heading>
                    <div>
                      <Container className="md">
                        <Grid className="grid-3">
                          <FormInput
                            label="Website link"
                            name="webiste"
                            type="text"
                          />
                          <FormInput
                            label="Facebook link"
                            name="facebook"
                            type="text"
                          />
                          <FormInput
                            label="Instagram link"
                            name="instagram"
                            type="text"
                          />
                        </Grid>
                      </Container>
                    </div>

                    {/* <FormInput
                      label="Type of business"
                      name="type"
                      type="string"
                    /> */}
                    {/* <FormInput
                      label="Is your company insured?"
                      name="insured"
                      type="text"
                    /> */}

                    {image && (
                      <div className="verification-images-preview">
                        <div className="create-service-images_preview m-4">
                          <img src={image} alt="service" />
                        </div>
                      </div>
                    )}

                    <div className="verification-images-btn">
                      <div className="uploadimages-btn m-5 ">
                        <div onClick={() => imageRef.current.click()}>
                          <RiUpload2Fill
                            style={{
                              marginRight: "1rem",
                            }}
                          />
                          {image ? "Change Image" : "Upload Image"}
                        </div>
                      </div>
                    </div>

                    <div style={{ display: "none" }}>
                      <input
                        type="file"
                        name="myImage"
                        ref={imageRef}
                        onChange={onImageChange}
                      />
                    </div>

                    <FormText
                      label="Why do you want to apply?"
                      text="text"
                      name="apply_message"
                      cols="30"
                      rows="20"
                    />
                    <Button className="btn-lighter rounded center m-2">
                      {loading ? "Please wait..." : "Apply"}
                    </Button>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </Container>
      </div>
    </>
  );
};

export default VerificationForm;
