import React, { useEffect, useMemo } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  Container,
  Divider,
  FormInput,
  Heading,
  InfoItem,
  LinkBtn,
} from "../../../../components";
import { Modal } from "@mui/material";
import { Puff } from "react-loader-spinner";
import toast from "react-hot-toast";
import {
  addUserToEmailList,
  ChangePasswordByVendor,
  clearErrors,
  clearMessages,
  DeletePaymentCard,
  EditCustomerProfile,
  GetPaymentCard,
  MarkCardAsDefaultPaymentMethod,
  removeUserFromEmailList,
} from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBinFill } from "react-icons/ri";
import AddCard from "../addCard/AddCard";
import { cardlogo } from "../../../../assests";
import "./VendorSettings.scss";
import { profile } from "../../../../assests";
import { FaCreditCard, FaEdit, FaHeart, FaRegHeart } from "react-icons/fa";
import { FormControlLabel, Switch } from "@mui/material";
import { IoTrashOutline } from "react-icons/io5";
import { BACKEND_STATIC_URL } from "../../../../constants";
import { CenteredLoader } from "../Chat/Chat";
import { reGetUserDetails2 } from "../../../../store/actions/user.action";
import { useScrollToHash } from "../../../../hooks";

const VendorSettings = () => {
  useScrollToHash();
  const [openConfirmationModal, setOpenConfirmationModal] =
    React.useState(false);
  const dispatch = useDispatch();
  const { getCardsDetails, errors, message, toggleMailListData } = useSelector(
    (state) => state.vendorReducer
  );
  const { details, loading } = useMemo(
    () => ({
      loading: getCardsDetails.loading,
      details: getCardsDetails.data,
    }),
    [getCardsDetails]
  );

  const { getUserDetails2Data } = useSelector((s) => s.userReducer);

  const vendorUser = useMemo(
    () => getUserDetails2Data.data,
    [getUserDetails2Data.data]
  );
  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message !== "") {
      toast.success(message);
      dispatch(clearMessages());
    }
  }, [errors, message, dispatch]);

  useEffect(() => {
    dispatch(GetPaymentCard());
  }, [dispatch]);

  const passwordvalidation = Yup.object({
    current_password: Yup.string().required("Please enter current password."),
    new_password: Yup.string()
      .required("Please enter new password.")
      .notOneOf(
        [Yup.ref("current_password")],
        "Your are entering previous password"
      )
      .min(8, "Your password is too short."),
    retypePassword: Yup.string()
      .required("Please retype your new password.")
      .oneOf([Yup.ref("new_password")], "Your passwords do not match."),
  });

  // useEffect(() => {
  //   if (errors?.length > 0) {
  //     toast.error(errors);
  //     dispatch(clearErrors());
  //   }
  // }, [errors]);

  // useEffect(() => {
  //   if (message != "") {
  //     setOpenConfirmationModal(false);
  //     toast.success(message);
  //     dispatch(clearMessages());
  //   }
  // }, [message]);

  return (
    <>
      <div className="venderprofile">
        <Container className="half">
          <div className="venderprofile-container m-5">
            <div className="venderprofile-container-image center">
              <img
                // src="hammerhead-app-k9vo7.ondigitalocean.app/uploads/photoPath-1702308873987.JPG"
                src={
                  vendorUser?.photoPath
                    ? `${BACKEND_STATIC_URL}${vendorUser?.photoPath}`
                    : profile
                }
                alt="profile"
              />
            </div>
            <div className="m-3">
              <LinkBtn
                to="/vender-edit-profile"
                className="rounded align-item-center align-item-right"
              >
                <FaEdit style={{ marginRight: ".5rem" }} />
                Edit Profile
              </LinkBtn>
            </div>

            <div className="venderprofile-container-content">
              <div className="venderprofile-container-content-left">
                <InfoItem
                  title="Name"
                  content={vendorUser?.fullName ? vendorUser?.fullName : ""}
                />
                <InfoItem
                  title="Email"
                  content={vendorUser?.email ? vendorUser?.email : ""}
                />
                <InfoItem
                  title="Company name"
                  content={
                    vendorUser?.companyName ? vendorUser?.companyName : ""
                  }
                />
                <InfoItem
                  title="Website"
                  content={vendorUser?.website ? vendorUser?.website : ""}
                />
                <InfoItem
                  title="User Id"
                  content={vendorUser?.userId || "--"}
                />
              </div>
              <div className="venderprofile-container-content-right">
                <InfoItem
                  title="Contact Number"
                  content={vendorUser?.phone ? vendorUser?.phone : ""}
                />
                <InfoItem
                  title="Address"
                  content={vendorUser?.address ? vendorUser?.address : ""}
                />
                <InfoItem
                  title="Type of Business"
                  content={
                    vendorUser?.bussinessType ? vendorUser?.bussinessType : ""
                  }
                />
                <InfoItem
                  title="Instagram"
                  content={vendorUser?.instagram ? vendorUser?.instagram : ""}
                />
              </div>
            </div>
          </div>
        </Container>
        <hr className="sectiondivider" />
      </div>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Puff
            height="60"
            width="60"
            radius="6"
            color="black"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      ) : details.length > 0 ? (
        <div className="payment m-5">
          <Heading className="primary center m-5">Manage Card</Heading>
          <div className="payment-container">
            {details.map((card, index) => {
              return (
                <div className="payment-container-card">
                  <div className="payment-card">
                    <div className="payment-card-heading space-between">
                      <h3>Card Number</h3>
                      <div className="payment-card-heading-buttons">
                        <IoTrashOutline
                          onClick={() => {
                            // if (card.isDefault) {
                            //   toast.error("You can't delete default card");
                            //   return;
                            // }

                            dispatch(DeletePaymentCard(card.id));
                          }}
                        />
                      </div>
                    </div>
                    <div className="payment-card-number align-item-center">
                      <img src={cardlogo} alt="card-logo" />
                      <p>{card?.last4 && `**** **** **** ${card?.last4}`}</p>
                    </div>
                    <div className="payment-card-cvc">
                      <h3>CVV Number </h3>
                      <p>***</p>
                    </div>
                    {
                      <div className="payment-card-default">
                        Default Card
                        <input
                          type="checkbox"
                          checked={card?.isDefault}
                          onChange={(e) => {
                            if (e.target.checked) {
                              dispatch(MarkCardAsDefaultPaymentMethod(card.id));
                            }
                          }}
                        ></input>
                      </div>
                      // <FaHeart className="heartIcon" style={{cursor:"default"}} ></FaHeart>

                      // :
                      // <FaRegHeart className="heartIcon" onClick={()=>dispatch(MarkCardAsDefaultPaymentMethod(card.payment_id))} ></FaRegHeart>
                    }
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="payment-container-button" id="card-input">
        <AddCard />
      </div>
      <hr className="sectiondivider" />
      <div className="customersettings">
        <Container className="half">
          <Heading className="primary center m-5">Change Password</Heading>
          <Formik
            initialValues={{
              current_password: "",
              new_password: "",
              retypePassword: "",
            }}
            validateOnMount
            validationSchema={passwordvalidation}
            onSubmit={(values, { resetForm }) => {
              let { current_password, new_password } = values;
              let result = {
                oldPassword: current_password,
                newPassword: new_password,
              };
              dispatch(ChangePasswordByVendor(result));
              resetForm({ values: "" });
            }}
          >
            {(formik) => (
              <div className="m-5">
                <Form>
                  <FormInput
                    place="Current Password"
                    name="current_password"
                    type="password"
                  />
                  <FormInput
                    place="New Password"
                    name="new_password"
                    type="password"
                  />
                  <FormInput
                    type="password"
                    place={"Retype Password"}
                    name="retypePassword"
                  />
                  <div className="save-btn">
                    <Button
                      disabled={!formik.isValid || !formik.dirty}
                      className="btn-lighter rounded center m-2"
                    >
                      {loading ? (
                        <Puff
                          height="25"
                          width="30"
                          radius="6"
                          color="white"
                          ariaLabel="loading"
                          wrapperStyle
                          wrapperClass
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </Container>
      </div>
      <hr className="sectiondivider" />
      {toggleMailListData?.loading || getUserDetails2Data?.fetching ? (
        <>
          <CenteredLoader />
        </>
      ) : (
        <div className="customersettings">
          <Container className="half">
            <Heading className="primary center m-3">
              Marketing Preferences
            </Heading>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked
                    onChange={(e) => {
                      dispatch(
                        addUserToEmailList(() => {
                          toast.success("Toggled!");
                          reGetUserDetails2();
                        })
                      );
                    }}
                    checked={vendorUser?.addedToMailList}
                  />
                }
              />
              <p>
                I would like to receive news, offers and promotions from
                Occasion Base.
              </p>
            </div>
          </Container>
        </div>
      )}
      <hr className="sectiondivider" />
      {/* <Container className="half">
        <Heading className="primary center m-3">Account Settings</Heading>
        <div
          style={{
            paddingBottom: "5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <a
            href="#"
            style={{
              color: "red",
              textDecoration: "underline",
              fontSize: "1.4rem",
            }}
          >
            {" "}
            Deactivate your Account{" "}
          </a>
        </div>
      </Container> */}
      {openConfirmationModal && (
        <ConfirmationModal
          onConfirmation={() => dispatch(DeletePaymentCard(details[0].id))}
          open={openConfirmationModal}
          onClose={() => setOpenConfirmationModal(false)}
        />
      )}
    </>
  );
};

export default VendorSettings;

const ConfirmationModal = ({ open, onClose, onConfirmation, disabled }) => {
  return (
    <Modal
      open={open}
      onClose={!disabled && onClose}
      onConfirmation={onConfirmation}
      className="confirmation-modal-lksadjf239jf2"
    >
      <div className="confirmation-modal-239jv2">
        <div className="top">
          <h6>Remove Card</h6>
          <p>
            Are you sure you want to remove Card? Doing so will result in
            Membership Cancellation at the end of current billing month!
          </p>
        </div>
        <div className="bottom">
          <button
            type="submit"
            onClick={onConfirmation}
            className="button-9274982734987324afasdf"
            disabled={disabled}
          >
            {disabled ? "Subscribing..." : "Yes"}
          </button>
          <button
            onClick={onClose}
            className="button-sdkjfkj2939j3f23"
            disabled={disabled}
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};
