import { faqContants } from "./../constants";

const initialState = {
  getAllFAQsData: {
    data: [],
    loading: false,
  },
};

const faqReducer = (s = initialState, action) => {
  const state = JSON.parse(JSON.stringify(s));

  switch (action.type) {
    case faqContants.GET_ALL_FAQs.pending:
      state.getAllFAQsData.loading = true;
      break;

    case faqContants.GET_ALL_FAQs.success:
      state.getAllFAQsData.loading = false;
      state.getAllFAQsData.data = action.payload;
      break;

    case faqContants.GET_ALL_FAQs.error:
      state.getAllFAQsData.loading = false;
      break;

    default:
      break;
  }

  return state;
};

export default faqReducer;
