import axios from "axios";
import { asyncCatch, objToStr, removeEmpty } from "../../utils";
import { vendorServices2Constants } from "../constants";
import { BACKEND_URL } from "../../constants";

export const postVendorService2 = asyncCatch(async ({ payload, config }) => {
  const { data } = await axios.post(
    `${BACKEND_URL}/v1/api/service/create`,
    payload,
    config
  );
  return data;
}, vendorServices2Constants.POST_VENDOR_SERVICE_2);

export const getAllVendorServices2 = asyncCatch(
  async ({ payload: { filters = {} } }) => {
    const { data } = await axios.get(
      `${BACKEND_URL}/v1/api/service?${objToStr(removeEmpty(filters))}`
    );
    return data;
  },
  vendorServices2Constants.GET_ALL_VENDOR_SERVICE_2
);

export const patchVendorServiceDeleteRequest2 = asyncCatch(
  async ({ payload: { _id, body }, config }) => {
    const { data } = await axios.patch(
      `${BACKEND_URL}/v1/api/service//delete-request/${_id}`,
      body,
      config
    );
    return data;
  },
  vendorServices2Constants.PATCH_VENDOR_SERVICE_DELETE_REQUEST_2
);

export const getVendorDetails2 = asyncCatch(
  async ({ payload: { _id }, config }) => {
    const { data } = await axios.get(
      `${BACKEND_URL}/v1/api/auth/${_id}`,
      config
    );
    return data;
  },
  vendorServices2Constants.GET_VENDOR_DETAILS_2
);

export const patchToggleArchivedStatusOfService = asyncCatch(
  async ({ payload: { _id, body }, config }) => {
    const { data } = await axios.patch(
      `${BACKEND_URL}/v1/api/service/toggle/archieve-status/${_id}`,
      body,
      config
    );
    return data;
  },
  vendorServices2Constants.PATCH_TOGGLE_ARCHIVED_STATUS_OF_SERVICE
);
