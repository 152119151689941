import { occasionConstant } from "../constants";

const initialState = {
    occasions: [],
}

const occasionReducer = (state = initialState, action) => {
    switch (action.type) {
        case occasionConstant.GET_ALL_OCCASIONS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case occasionConstant.GET_ALL_OCCASIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                occasions: action.payload.occasions.map((occasion)=>{
                    return {
                        ...occasion,
                        name:occasion.occasionName,
                        image: occasion.occasionImage,
                        to:`/${occasion.occasionName.replace(/\s/g, "")}`
                    }
                }),
            };

        case occasionConstant.GET_ALL_OCCASIONS_FAILURE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
}

export default occasionReducer;