import React from "react";
import "./ViewPageOutlet.scss";
import Container from "../../container/Container";
import Grid from "../../grid/Grid";

import { BiSearch } from "react-icons/bi";
import GoBackBtn from "../../goBackBtn/GoBackBtn";
import { useQueryParams } from "../../../hooks";
import { removeEmpty } from "../../../utils";
import styled from "@emotion/styled";

const ViewPageOutlet = (props) => {
  const { setQueryParam } = useQueryParams({ query: "" });
  return (
    <>
      <div className="viewpage">
        <div className={`viewpage-hero ${props.className}`}>
          <div>
            <Container className="main">
              <Container className="half">
                <div className="center viewpage-hero-content ">
                  <h1 className="uppercase">{props.title}</h1>
                  <p>{props.summery}</p>
                </div>
              </Container>
            </Container>
          </div>
        </div>
        <Container
          className="main"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Container className="lg">
            <div className="space-between m-3" style={{ flexWrap: "wrap" }}>
              <div>
                <GoBackBtn />
              </div>
              <div className="align-item-right">
                <div className="searchbar">
                  <input
                    type="text"
                    placeholder="Browse"
                    onChange={(e) =>
                      setQueryParam(
                        removeEmpty({ query: e.target.value }),
                        true,
                        true
                      )
                    }
                  />
                  <div className="searchbar-icon">
                    <BiSearch />
                  </div>
                </div>
              </div>
            </div>

            <StyledGrid>{props.children}</StyledGrid>
          </Container>
        </Container>
      </div>
    </>
  );
};

export default ViewPageOutlet;

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 750px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
