import React, { useEffect } from "react";
import "./ServiceDetail.scss";
import { Button, Container, Heading, InfoItem } from "../../../../components";
import { Puff } from "react-loader-spinner";
import toast from "react-hot-toast";
import { GetSingleService, clearErrors } from "./../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { BACKEND_STATIC_URL } from "../../../../constants";
import { ImLocation2 } from "react-icons/im";
import { FaCalendarCheck } from "react-icons/fa";
import { HiOutlineUserGroup } from "react-icons/hi";
import { MdOutlineStorefront } from "react-icons/md";
import { profile } from "../../../../assests";
const ServiceDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleService, errors, loading } = useSelector(
    (state) => state.vendorReducer
  );

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
  }, [errors]);

  useEffect(() => {
    dispatch(GetSingleService(id));
  }, []);
  return (
    <>
      {loading ? (
        <Puff
          height="60"
          width="60"
          radius="6"
          color="black"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
        />
      ) : Object.keys(singleService).length > 0 ? (
        <Container className="lg">
          {/* <div className="servicedetail m-5"> */}

          {/* </div> */}
          <div className="servicedetail-container-image m-5">
            <div className="servicedetail-container-image-section">
              {singleService.thumbnails &&
                singleService.thumbnails.map((item, ind) => {
                  return (
                    <img
                      key={ind}
                      src={`${BACKEND_STATIC_URL}${item}`}
                      alt="service-photos"
                    />
                  );
                })}
            </div>
          </div>

          <div className="servicedetail-container m-5">
            <div>
              <Heading className="primary m-5 servicedetail-title">
                {singleService.name && singleService.name}
              </Heading>
              <span></span>
              <div className="">
                <h3>About Service</h3>
                <p>{singleService.description && singleService.description}</p>
              </div>
              <span />
              <div className="">
                <h3>{<ImLocation2></ImLocation2>} Services Area</h3>
                <ul>
                  {singleService.serviceArea &&
                    singleService.serviceArea.map((area) => {
                      return <li>{area}</li>;
                    })}
                </ul>
              </div>
              <span></span>
              <div>
                <h3>{<FaCalendarCheck />} Book Period Prior Needed</h3>
                <p>
                  {singleService.bookingPeriod && singleService.bookingPeriod}
                </p>
              </div>
              <div>
                <h3>{<FaCalendarCheck />} Available for Booking On:</h3>
                <p>
                  {singleService.bookingWeekDays &&
                    singleService.bookingWeekDays.map((item, ind) => {
                      return <span key={ind}>{`${item.day} , `}</span>;
                    })}
                </p>
              </div>
              <div>
                <h3>
                  {<HiOutlineUserGroup></HiOutlineUserGroup>} Guests limits
                </h3>
                <p>
                  {singleService.numberOfCaters && singleService.numberOfCaters}
                </p>
              </div>
              <span></span>
            </div>
            <div className="vender-message-tab">
              <div className="vender-message-tab-heading">
                <img src={profile} alt="" />
                <div className="vender-message-tab-heading-text">
                  <div>Fake Name</div>
                  <p> Fake Discription</p>
                </div>
                <span>
                  <MdOutlineStorefront />
                </span>
              </div>
              <div>
                <form onSubmit={(e) => e.preventDefault()}>
                  <label htmlFor="date">Date</label>
                  <input
                    type="date"
                    name="date"
                    id="date"
                    placeholder="select a date"
                  />
                  {/* <label htmlFor="occasion">Time</label> */}
                  <label htmlFor="location">Location</label>
                  <select
                    name="location"
                    id="location"
                    placeholder="select location"
                  >
                    {singleService.serviceArea &&
                      singleService.serviceArea.map((item, ind) => {
                        return <option key={ind}>{item}</option>;
                      })}
                  </select>
                  <label htmlFor="phoneno">Phone No.</label>
                  <input
                    type="text"
                    name="phoneno"
                    id="phoneno"
                    placeholder="mobile no"
                  />
                  <label htmlFor="message">Message</label>
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="10"
                    placeholder="hi"
                  ></textarea>
                  <Button className="btn-lighter-full pill ">
                    Message Vendor
                  </Button>
                </form>
              </div>
            </div>
          </div>
          <div className="not-ready">
            <div>Not ready to proceed with a booking?</div>
            <Button className="pill  ">Message Vendor</Button>
          </div>
        </Container>
      ) : (
        <h1>No detail found</h1>
      )}
    </>
  );
};

export default ServiceDetail;
