import React, { useEffect } from "react";
import "./VenderProfile.scss";
import { profile } from "../../../../assests";
import { Button, Container, InfoItem, LinkBtn } from "../../../../components";
import { FaEdit } from "react-icons/fa";
import { BACKEND_STATIC_URL } from "../../../../constants";
import useUser from "../../../../hooks/useUser";
const VenderProfile = () => {
  const vendorUser = useUser();

  return (
    <>
      <div className="venderprofile">
        <Container className="half">
          <div className="venderprofile-container m-5">
            <div className="venderprofile-container-image center">
              <img
                crossOrigin="anonymous"
                // src="hammerhead-app-k9vo7.ondigitalocean.app/uploads/photoPath-1702308873987.JPG"
                src={
                  vendorUser?.photoPath
                    ? `${BACKEND_STATIC_URL}${vendorUser?.photoPath}`
                    : profile
                }
                alt="profile"
              />
            </div>
            <div className="m-3">
              <LinkBtn
                to="/vender-edit-profile"
                className="rounded align-item-center align-item-right"
              >
                <FaEdit style={{ marginRight: ".5rem" }} />
                Edit Profile
              </LinkBtn>
            </div>

            <div className="venderprofile-container-content">
              <div className="venderprofile-container-content-left">
                <InfoItem
                  title="Name"
                  content={vendorUser?.fullName ? vendorUser?.fullName : ""}
                />
                <InfoItem
                  title="Email"
                  content={vendorUser?.email ? vendorUser?.email : ""}
                />
                <InfoItem
                  title="Company name"
                  content={
                    vendorUser?.companyName ? vendorUser?.companyName : ""
                  }
                />
                <InfoItem
                  title="Website"
                  content={vendorUser?.website ? vendorUser?.website : ""}
                />
              </div>
              <div className="venderprofile-container-content-right">
                <InfoItem
                  title="Contact Number"
                  content={vendorUser?.phone ? vendorUser?.phone : ""}
                />
                <InfoItem
                  title="Address"
                  content={vendorUser?.address ? vendorUser?.address : ""}
                />
                <InfoItem
                  title="Type of Business"
                  content={
                    vendorUser?.bussinessType ? vendorUser?.bussinessType : ""
                  }
                />
                <InfoItem
                  title="Instagram"
                  content={vendorUser?.instagram ? vendorUser?.instagram : ""}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default VenderProfile;
