export const customerConstant = {
  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  EDIT_PROFILE_REQUEST: "EDIT_PROFILE_REQUEST",
  EDIT_PROFILE_SUCCESS: "EDIT_PROFILE_SUCCESS",
  EDIT_PROFILE_FAILURE: "EDIT_PROFILE_FAILURE",
  MESSAGE_TO_VENDOR_REQUEST: "MESSAGE_TO_VENDOR_REQUEST",
  MESSAGE_TO_VENDOR_SUCCESS: "MESSAGE_TO_VENDOR_SUCCESS",
  MESSAGE_TO_VENDOR_FAILURE: "MESSAGE_TO_VENDOR_FAILURE",
  POST_SUPPORT_REQUEST: "POST_SUPPORT_REQUEST",
  POST_SUPPORT_SUCCESS: "POST_SUPPORT_SUCCESS",
  POST_SUPPORT_FAILURE: "POST_SUPPORT_FAILURE",

  GET_USER_INFO_REQUEST: "GET_USER_INFO_REQUEST",
  GET_USER_INFO_SUCCESS: "GET_USER_INFO_SUCCESS",
  GET_USER_INFO_FAILURE: "GET_USER_INFO_FAILURE",

  GET_CUSTOMER_ORDERS_REQUEST: "GET_CUSTOMER_ORDERS_REQUEST",
  GET_CUSTOMER_ORDERS_SUCCESS: "GET_CUSTOMER_ORDERS_SUCCESS",
  GET_CUSTOMER_ORDERS_FAILURE: "GET_CUSTOMER_ORDERS_FAILURE",

  GET_MESSAGE_REQUEST_FOR_CUSTOMER_REQUEST:
    "GET_MESSAGE_REQUEST_FOR_CUSTOMER_REQUEST",
  GET_MESSAGE_REQUEST_FOR_CUSTOMER_SUCCESS:
    "GET_MESSAGE_REQUEST_FOR_CUSTOMER_SUCCESS",
  GET_MESSAGE_REQUEST_FOR_CUSTOMER_FAILURE:
    "GET_MESSAGE_REQUEST_FOR_CUSTOMER_FAILURE",

  UPDATE_MESSAGE_REQUEST_FOR_CUSTOMER_REQUEST:
    "UPDATE_MESSAGE_REQUEST_FOR_CUSTOMER_REQUEST",
  UPDATE_MESSAGE_REQUEST_FOR_CUSTOMER_SUCCESS:
    "UPDATE_MESSAGE_REQUEST_FOR_CUSTOMER_SUCCESS",
  UPDATE_MESSAGE_REQUEST_FOR_CUSTOMER_FAILURE:
    "UPDATE_MESSAGE_REQUEST_FOR_CUSTOMER_FAILURE",

  ADD_CUSTOMER_TO_EMAIL_LIST_REQUEST: "ADD_CUSTOMER_TO_EMAIL_LIST_REQUEST",
  ADD_CUSTOMER_TO_EMAIL_LIST_SUCCESS: "ADD_CUSTOMER_TO_EMAIL_LIST_SUCCESS",
  ADD_CUSTOMER_TO_EMAIL_LIST_FAILURE: "ADD_CUSTOMER_TO_EMAIL_LIST_FAILURE",
};
