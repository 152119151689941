import React from "react";
import "./LinkBtn.scss";

import { Link } from "react-router-dom";

const LinkBtn = (props) => {
    return (
        <>
            <div
                className={`linkbtn  ${props.className}`}
                onClick={props.onClick}
            >
                <Link to={props.to}>{props.children}</Link>
            </div>
        </>
    );
};

export default LinkBtn;
