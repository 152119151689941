import axios from "axios";
import { asyncCatch, objToStr, removeEmpty } from "../../utils";
import { occasion2Constants } from "../constants";
import { BACKEND_URL } from "../../constants";

export const getAllTopOccasions2 = asyncCatch(
  async ({ payload: { filters = {} } }) => {
    const { data } = await axios.get(
      `${BACKEND_URL}/v1/api/occasions/top?${objToStr(removeEmpty(filters))}`
    );
    return data;
  },
  occasion2Constants.GET_ALL_TOP_OCCASSIONS_2
);
